import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormGroup, FormLabel, Stack } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useNavigate } from 'app-router';
import { Button, EmailInput, LoadingButton, Text } from 'common/components';
import { getRoutePathname } from 'routes/getRoutePathname';
import useAuthContext from 'providers/AuthProvider';

import { ForgotPasswordSchema, TForgotPasswordForm } from '../authentication.schemas';

const ForgotPasswordEmailForm = () => {
  const [sendEmail, setSendEmail] = React.useState(false);
  const { forgotPassword, forgotPasswordLoading } = useAuthContext();
  const formMethods = useForm<TForgotPasswordForm>({
    resolver: zodResolver(ForgotPasswordSchema),
    defaultValues: {
      email: '',
    },
  });
  const navigate = useNavigate();
  const toLoginScreen = () => {
    navigate(getRoutePathname.Login());
  };
  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<TForgotPasswordForm> = async (values) => {
    const { email } = values;
    forgotPassword(email);
    setSendEmail(true);
  };
  return !sendEmail ? (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <FormGroup>
            <FormLabel
              sx={(theme) => ({
                color: theme.palette.secondary.main,
                paddingRight: '10px',
                marginBottom: 1,
              })}
              htmlFor="email"
            >
              Digite o endereço de e-mail e vamos enviar um link para redefinir a senha.
            </FormLabel>
            <EmailInput />
          </FormGroup>
          <LoadingButton loading={forgotPasswordLoading} fullWidth size="small" variant="contained" type="submit">
            Redefinir Senha
          </LoadingButton>
          <Button disabled={forgotPasswordLoading} onClick={toLoginScreen} fullWidth size="small" variant="outlined">
            Voltar
          </Button>
        </Stack>
      </form>
    </FormProvider>
  ) : (
    <Text>Acesse o link que foi enviado ao seu e-mail para que você possa trocar sua senha.</Text>
  );
};

export default ForgotPasswordEmailForm;
