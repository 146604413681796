const IconPaidChannel = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4228_75303)">
        <path
          d="M10.793 7.625H17.1263L9.20964 19.5V12.375H3.66797L10.793 0.5V7.625ZM9.20964 9.20833V6.21583L6.46414 10.7917H10.793V14.2702L14.1678 9.20833H9.20964Z"
          fill="#2D3748"
        />
      </g>
      <defs>
        <clipPath id="clip0_4228_75303">
          <rect width="19" height="19" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconPaidChannel;
