import React from 'react';
import { useEditor, EditorContent, JSONContent } from '@tiptap/react';
import Image from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import StarterKit from '@tiptap/starter-kit';
import { useFormContext } from 'react-hook-form';

import { Box } from 'common/components';

import './tiptap.css';
import MenuBar from './MenuBar';

type TTiptapForDescription = {
  editable: boolean;
  content?: JSONContent;
};

const TiptapForDescription = ({ editable, content }: TTiptapForDescription) => {
  const [_fileImage, setFileImage] = React.useState<File[] | null>(null);
  const handleAddFile = (files: JSONContent[] | undefined) => {
    let filesArr: File[] = [];
    files?.forEach((it) => {
      const imageSrc = it.attrs?.src ?? '';
      const imageFile = new File([imageSrc], `${imageSrc}.jpeg`, { type: 'image/jpeg' });
      filesArr.push(imageFile);
    });
    setFileImage(filesArr);
  };
  const { setValue } = useFormContext();
  const editor = useEditor({
    editable: editable,
    extensions: [
      StarterKit,
      Placeholder.configure({
        emptyEditorClass: 'is-editor-empty',
        placeholder: 'Escreva seu tópico...',
      }),
      Image.configure({
        HTMLAttributes: {
          class: 'image',
        },
      }),
    ],
    content: content,
    onUpdate: ({ editor }) => {
      const contentJson = editor.getJSON();
      setValue('description', contentJson);
      const files = contentJson.content?.filter((it) => it.type === 'image');
      handleAddFile(files);
    },
    editorProps: {
      attributes: {
        class: 'description',
      },
    },
  });

  return (
    <Box>
      {editable && <MenuBar editor={editor} />}
      <EditorContent editor={editor} />
    </Box>
  );
};

export default TiptapForDescription;
