import { Alert, AlertTitle, Snackbar } from '@mui/material';
import React from 'react';

export type AlertProps = {
  duration: number;
  horizontal: 'center' | 'left' | 'right';
  vertical: 'top' | 'bottom';
  title: string;
  message: string;
  type: 'error' | 'info' | 'success' | 'warning';
  open: boolean;
};
export type toastProps = {
  duration?: number;
  horizontal?: 'center' | 'left' | 'right';
  vertical?: 'top' | 'bottom';
  title: string;
  message?: string;
  type?: 'error' | 'info' | 'success' | 'warning';
  open?: boolean;
};

interface NotificationContextProps {
  toast: (props: toastProps) => void;
}

interface NotificationProviderProps {
  children: React.ReactNode;
}

export const NotificationContext = React.createContext<NotificationContextProps>({} as NotificationContextProps);

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [status, setStatus] = React.useState<AlertProps>({
    title: '',
    duration: 2500,
    message: '',
    type: 'success',
    horizontal: 'center',
    vertical: 'bottom',
    open: false,
  });

  const { message, type, horizontal, vertical, title, open, duration } = status;

  const toast: (props: toastProps) => void = (props) => {
    setStatus({ ...status, open: true, ...props });
  };

  const handleClose = () => {
    setStatus({ ...status, open: false });
  };

  return (
    <NotificationContext.Provider value={{ toast }}>
      <Snackbar anchorOrigin={{ horizontal, vertical }} open={open} autoHideDuration={duration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={type}>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider.');
  }
  return context;
};

export default useNotification;
