import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { QueryClient } from 'react-query';

import { LoaderFunctionArgs, useNavigate, useParams } from 'app-router';
import { Box, LoadingButton, Button, Card } from 'common/components';
import TiptapForDescription from 'modules/communities/common/editor/TiptapForDescription';
import { getRoutePathname } from 'routes/getRoutePathname';

import { NameInputPost } from './components';
import { CreateTopicSchema, TCreateTopicSchema } from './post.schemas';
import { fetchTopicByIdAPI, useGetTopicByIdAPI, useUpdateTopicAPI } from './post.api';
import { POSTS_KEY } from 'common/utils/queryKeys';

export const loaderTopicEdit =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const topicId = params.postId ?? '';
    const query = POSTS_KEY.details(topicId);
    queryClient.prefetchQuery({ queryKey: query, queryFn: () => fetchTopicByIdAPI(topicId) });
    return null;
  };

const normalizeTopic = (formData: TCreateTopicSchema) => {
  const normalizedTopic = { title: formData.name, content: formData.description };
  return normalizedTopic;
};

const TopicEdit = () => {
  const navigate = useNavigate();
  const { id = '', channelId = '', postId = '' } = useParams();
  const { data: topic, isLoading: isLoadingTopic } = useGetTopicByIdAPI(postId);
  const { mutate: updateTopic } = useUpdateTopicAPI(postId);

  const postRoute = getRoutePathname.CommunitiesDetailsPostsDetails(id, channelId, postId);

  const onSubmit = (formData: TCreateTopicSchema) => {
    const updatedTopic = normalizeTopic(formData);
    updateTopic(updatedTopic, {
      onSuccess: () => {
        navigate(postRoute);
      },
    });
  };

  const handleCancel = () => {
    navigate(postRoute);
  };

  const formMethods = useForm<TCreateTopicSchema>({
    resolver: zodResolver(CreateTopicSchema),
    defaultValues: {
      name: topic?.title,
      description: topic?.content,
    },
  });

  const { handleSubmit, reset } = formMethods;

  const handleConfirm = (formData: TCreateTopicSchema) => {
    if (onSubmit instanceof Function) {
      onSubmit({ ...formData });
    }
    navigate(postRoute);
  };

  React.useLayoutEffect(() => {
    reset({ name: topic?.title, description: topic?.content });
  }, [topic]);

  if (isLoadingTopic) {
    return null;
  }
  if (!topic) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%" alignItems="center">
      <Box display="flex" flexDirection="column" gap={2} width="100%" maxWidth="1080px" marginTop={1}>
        <FormProvider {...formMethods}>
          <Box component="form" onSubmit={handleSubmit(handleConfirm)}>
            <Card
              sx={{
                gap: 2,
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
              }}
            >
              <NameInputPost />
              <TiptapForDescription content={topic.content} editable={true} />
            </Card>
            <Box
              sx={() => ({
                display: 'flex',
                gap: '8px',
                padding: '16px',
                justifyContent: 'center',
              })}
            >
              <Button onClick={handleCancel} variant="outlined">
                Cancelar
              </Button>
              <LoadingButton variant="contained" type="submit">
                Salvar
              </LoadingButton>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default TopicEdit;
