import CloseIcon from '@mui/icons-material/Close';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListItemIcon, ListItemText } from '@mui/material';

import { useNavigate, useParams } from 'app-router';
import { MoreIconMenu } from 'common/components';
import { useUpdateMemberByMemberIdAPI } from 'modules/communities/screens/community/communityMembers.api';
import { useDeleteCommunityByIdAPI } from 'modules/communities/screens/community/community.api';
import { getRoutePathname } from 'routes/getRoutePathname';

import { TMember } from '../types/community.types';
import { MemberRoleEnum, MemberStatusEnum } from '../enums/members.enums';

type MemberButtonsCommunityProps = {
  member: TMember | null;
};

const MemberButtonsCommunity = ({ member }: MemberButtonsCommunityProps) => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const userRole = member && !member.is_deleted ? member.role : null;

  const { mutate: updateMember } = useUpdateMemberByMemberIdAPI(id);
  const { mutateAsync: deleteCommunity } = useDeleteCommunityByIdAPI();

  const handleDeleteMember = () => {
    updateMember({ id: member?._id ?? '', update: { status: MemberStatusEnum.INACTIVE } });
  };

  const handleDeleteCommunity = async () => {
    await deleteCommunity({ id });
    navigate(getRoutePathname.Communities());
  };

  const optionsMoreMenu = [
    {
      label: (
        <>
          <ListItemIcon>
            <CloseIcon sx={{ width: '12px', height: '12px' }} />
          </ListItemIcon>
          <ListItemText>Deixar de ser membro</ListItemText>
        </>
      ),
      onClick: handleDeleteMember,
      shouldRender: true,
    },
    {
      label: (
        <>
          <ListItemIcon>
            <ReportGmailerrorredIcon sx={{ width: '12px', height: '12px' }} />
          </ListItemIcon>
          <ListItemText>Denunciar comunidade</ListItemText>
        </>
      ),
      onClick: () => alert('Denunciar comunidade'),
      shouldRender: true,
    },
    {
      label: (
        <>
          <ListItemIcon>
            <DeleteIcon sx={{ width: '12px', height: '12px' }} />
          </ListItemIcon>
          <ListItemText>Deletar comunidade</ListItemText>
        </>
      ),
      onClick: handleDeleteCommunity,
      shouldRender: true,
    },
  ];

  const optionsMoreMenuAdmin = userRole === MemberRoleEnum.ADMIN ? optionsMoreMenu.slice(1) : null;
  const optionsMoreMenuInactive = member?.status === MemberStatusEnum.INACTIVE ? optionsMoreMenu.slice(1, 2) : null;
  const optionsMoreMenuBanned = member?.status === MemberStatusEnum.BANNED ? optionsMoreMenu.slice(1, 2) : null;
  const optionsMoreMenuMember = userRole && userRole !== MemberRoleEnum.ADMIN ? optionsMoreMenu.slice(0, 2) : null;
  const optionsMoreMenuNoMember = optionsMoreMenu.slice(1, 2);

  return (
    <MoreIconMenu
      height="30.75px"
      variant="outlined"
      options={
        optionsMoreMenuAdmin ||
        optionsMoreMenuInactive ||
        optionsMoreMenuBanned ||
        optionsMoreMenuMember ||
        optionsMoreMenuNoMember
      }
    />
  );
};

export default MemberButtonsCommunity;
