import { HelperMessagesEnum } from 'common/utils/validation.schemas';
import { z } from 'zod';
import { JSONContent } from '@tiptap/react';

const name = z
  .string()
  .min(5, { message: 'Precisa conter pelo menos 5 caracteres' })
  .max(30, { message: HelperMessagesEnum.MAX_CHARACTERS });
const description = z
  .string()
  .min(5, { message: 'Precisa conter pelo menos 5 caracteres' })
  .max(90, { message: HelperMessagesEnum.MAX_CHARACTERS });
const category = z.string().min(1);
const tag = z.object({
  community_category: z.string(),
  created_at: z.coerce.date(),
  is_deleted: z.boolean(),
  label: z.string(),
  updated_at: z.coerce.date(),
  used_count: z.coerce.number(),
  __v: z.coerce.number(),
  _id: z.string(),
});
const not_allow_minor_members = z.boolean();
const highlight = z.custom<JSONContent>();

const FileSchema = z
  .custom<string | File | FileList>((val) => {
    if (typeof val === 'string' || val instanceof File || val instanceof FileList) {
      return true;
    } else return false;
  }, 'Arquivo inválido')
  .optional();

//CREATE COMMUNITY SETTINGS SCHEMA
export const CreateCommunitySchema = z.object({
  name: name,
  description: description,
  category: category,
  tags: z.array(tag).optional(),
  not_allow_minor_members: not_allow_minor_members,
});

export type TCreateCommunityForm = z.infer<typeof CreateCommunitySchema>;

//EDIT COMMUNITY SETTINGS SCHEMA
export const EditCommunitySchema = z.object({
  name: name,
  description: description,
  category: category,
  tags: z.array(z.string()).optional(),
  not_allow_minor_members: not_allow_minor_members,
});

export type TEditCommunityForm = z.infer<typeof EditCommunitySchema>;

//EDIT COMMUNITY SCHEMA
export const UpdateCommunityImgsSchema = z.object({
  avatarUrl: FileSchema.optional(),
  bannerUrl: FileSchema.optional(),
});

export type TUpdateCommunityImgsForm = z.infer<typeof UpdateCommunityImgsSchema>;

//CREATE HIGHLIGHT COMMUNITY SCHEMA
export const CreateCommunityHighlightSchema = z.object({
  description: highlight.optional(),
});

export type TCreateCommunityHighlightSchema = z.infer<typeof CreateCommunityHighlightSchema>;
