const ModeratorIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 7.99984H12.6667C12.3133 10.7398 10.48 13.1865 8 13.9465V7.99984H3.33333V4.19984L8 2.1265M8 0.666504L2 3.33317V7.33317C2 11.0332 4.56 14.4865 8 15.3332C11.44 14.4865 14 11.0332 14 7.33317V3.33317L8 0.666504Z"
        fill="#2D3748"
      />
    </svg>
  );
};

export default ModeratorIcon;
