export const categoriesArr = [
  { label: 'Todos', value: '' },
  { label: 'Gastronomia', value: 'gastronomy' },
  { label: 'Política', value: 'politics' },
  { label: 'Religião/Filosofia', value: 'religion-philosophy' },
  { label: 'Bem Estar', value: 'well-being' },
  { label: 'Investimentos', value: 'investments' },
  { label: 'Entretenimento', value: 'entertainment' },
  { label: 'Turismo', value: 'tourism' },
  { label: 'Tecnologia', value: 'technology' },
  { label: 'Empreendedorismo', value: 'entrepreneurship' },
  { label: 'Marketing/Publicidade', value: 'marketing-advertising' },
  { label: 'Imigração/Exterior', value: 'immigration-abroad' },
  { label: 'Artes', value: 'arts' },
  { label: 'Esportes', value: 'sports' },
  { label: 'Local', value: 'local' },
  { label: 'Hobbies', value: 'hobbies' },
  { label: 'Família', value: 'family' },
  { label: 'Trabalho', value: 'work' },
  { label: 'Eventos', value: 'events' },
  { label: 'Outros', value: 'other' },
];

export const categoriesObj = categoriesArr.reduce((acc: any, cur) => {
  const value = cur.value.replace('-', '_');
  acc[value] = cur;
  return acc;
}, {});

export const typesArr = [
  { label: 'Pública', value: 'public' },
  { label: 'Privada', value: 'private' },
  { label: 'Paga', value: 'paid' },
];

type typesObjProps = Record<string, { label: string; value: string }>;

export const typesObj: typesObjProps = typesArr.reduce((acc: any, cur) => {
  acc[cur.value] = cur;
  return acc;
}, {});
