export enum UserStatusEnum {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  DELETED = 'deleted',
  INACTIVATED = 'inactivated',
  SUSPENDED = 'suspended',
  WAITING_ACTIVATION = 'waiting-activation',
}

export enum GenderEnum {
  FEMALE = 'female',
  MALE = 'male',
}
