import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { Outlet, useNavigate, useParams } from 'app-router';
import SkeletonChannelShell from 'assets/skeletons/SkeletonChannelShell';
import { Box, Button, Image, Text } from 'common/components';
import { getRoutePathname } from 'routes/getRoutePathname';
import { useGetCommunityByIdAPI } from 'modules/communities/screens/community/community.api';

const ChannelShell = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const { data: community, isLoading: isLoadingCommunity } = useGetCommunityByIdAPI(id);

  const handleBackToCommunity = () => {
    navigate(getRoutePathname.CommunitiesDetails(id));
  };

  if (isLoadingCommunity) {
    return <SkeletonChannelShell />;
  }
  if (!community) {
    return <div>No community.</div>;
  }

  return (
    <Box
      sx={(theme) => ({
        bgcolor: theme.palette.info.light,
      })}
      display="flex"
      flexDirection="column"
      paddingBottom={2}
      alignItems="center"
      gap={1}
      marginTop={{ xs: 1, sm: 0 }}
      width="100%"
    >
      <Box
        position="sticky"
        zIndex={900}
        paddingBottom={1}
        top={{ xs: '0', sm: '56px' }}
        sx={(theme) => ({ bgcolor: theme.palette.info.light })}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        gap={1}
      >
        <Button
          onClick={handleBackToCommunity}
          disableTouchRipple
          sx={{
            height: '40px',
            minWidth: '40px',
            gap: 1,
            justifyContent: 'flex-start',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          <KeyboardArrowLeftIcon />
          <Box display={{ xs: 'none', sm: 'block' }} height="32px" width="32px" overflow="hidden">
            <Image src={community.avatar_url} />
          </Box>
          <Text variant="h4" sx={{ fontSize: { xs: '.875rem', sm: '1rem' } }}>
            {community.name}
          </Text>
        </Button>
      </Box>
      <Box display="flex" alignItems="center" width="100%" padding={0.5} justifyContent="center">
        <Outlet />
      </Box>
    </Box>
  );
};

export default ChannelShell;
