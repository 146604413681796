import { zodResolver } from '@hookform/resolvers/zod';
import { get } from 'lodash';
import { Divider, Stack, TextField } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useNavigate, useSearchParams } from 'app-router';
import { Button, LoadingButton, Text } from 'common/components';
import useAuthContext from 'providers/AuthProvider';
import { getRoutePathname } from 'routes/getRoutePathname';

import { ActivateSchema, TActivateForm } from './authentication.schemas';

const loginRoute = getRoutePathname.Login();

const Activate = () => {
  const { activateUser, resendActivationCode, resendActivationCodeLoading, activateUserIsLoading } = useAuthContext();
  const isLoading = activateUserIsLoading || resendActivationCodeLoading;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TActivateForm>({
    resolver: zodResolver(ActivateSchema),
  });

  const onSubmit: SubmitHandler<TActivateForm> = (values) => {
    activateUser({ userId: searchParams.get('user'), activationCode: values.code.toString() });
  };

  const handlerResendActivateCode = () => {
    resendActivationCode(searchParams.get('user') ?? '');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Text variant="h1" fontSize={{ xs: '1.25rem', sm: '2.25rem' }}>
          Ative sua conta Yethos
        </Text>
        <Divider />
        <TextField
          error={!!`${get(errors, 'code.message', '')}`}
          size="small"
          placeholder="Codigo"
          helperText={`${get(errors, 'code.message', '')}`}
          {...register('code')}
        />

        <Button onClick={handlerResendActivateCode} disabled={isLoading} size="small" fullWidth variant="text">
          Reenviar código
        </Button>
        <LoadingButton loading={isLoading} size="small" variant="contained" type="submit">
          Ativar Conta
        </LoadingButton>
        <Button onClick={() => navigate(loginRoute)} disabled={isLoading} fullWidth size="small" variant="outlined">
          Já tem conta? Login
        </Button>
      </Stack>
    </form>
  );
};

export default Activate;
