import { styled } from '@mui/material/styles';

import { Link as LinkRouter } from 'app-router';

const Link = styled(LinkRouter)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.info.dark,
}));

export default Link;
