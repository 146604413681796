import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { FormGroup, MenuItem, TextField, TextFieldProps } from '@mui/material';

const GenderSelect = (props: TextFieldProps) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <FormGroup>
      <TextField
        error={!!`${get(errors, 'gender.message', '')}`}
        helperText={`${get(errors, 'gender.message', '')}`}
        sx={{ marginTop: 1 }}
        id="gender"
        label="Sexo"
        size="small"
        select
        {...register('gender')}
        {...props}
      >
        <MenuItem value="female">Feminino</MenuItem>
        <MenuItem value="male">Masculino</MenuItem>
      </TextField>
    </FormGroup>
  );
};

export default GenderSelect;
