const EditIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6091 3.16177L8.81098 1.35713C8.57629 1.1359 8.26877 1.00896 7.9469 1.00046C7.62504 0.991956 7.31128 1.10249 7.06533 1.31103L1.15902 7.23867C0.946891 7.45336 0.814811 7.73475 0.784949 8.03561L0.502758 10.7821C0.493918 10.8786 0.50639 10.9758 0.539286 11.0669C0.572182 11.1579 0.624692 11.2406 0.693073 11.309C0.754394 11.37 0.827117 11.4183 0.907074 11.4511C0.987031 11.4839 1.07265 11.5005 1.15902 11.5H1.21808L3.95467 11.2497C4.25445 11.2197 4.53483 11.0872 4.74874 10.8743L10.6551 4.94665C10.8843 4.7036 11.0082 4.37925 10.9996 4.04468C10.991 3.71012 10.8506 3.39263 10.6091 3.16177V3.16177ZM3.83655 9.93246L1.86777 10.1169L2.04496 8.14099L5.75282 4.46585L7.52471 6.24415L3.83655 9.93246ZM8.37785 5.36159L6.61908 3.59647L7.89878 2.27921L9.69036 4.07726L8.37785 5.36159Z"
        fill="#2D3748"
      />
    </svg>
  );
};

export default EditIcon;
