import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';

import { useNavigate, useParams } from 'app-router';
import { Box, LoadingButton, Button, Card } from 'common/components';
import TiptapForDescription from 'modules/communities/common/editor/TiptapForDescription';
import { emptyContent } from 'modules/communities/common/editor/initialContent';
import { getRoutePathname } from 'routes/getRoutePathname';

import { NameInputPost } from './components';
import { CreateTopicSchema, TCreateTopicSchema } from './post.schemas';
import { useCreateTopicAPI } from './post.api';
import { TFormDataTopic } from './topic.types';
import useAuthContext from 'providers/AuthProvider';

type TNormalizeTopicData = {
  formData: TCreateTopicSchema;
  channelId: string;
  communityId: string;
  profileId: string;
};

const normalizeTopicData = ({ formData, channelId, communityId, profileId }: TNormalizeTopicData) => {
  const normalizedData: TFormDataTopic = {
    channel: channelId,
    community: communityId,
    created_by: profileId,
    title: formData.name,
    is_visible: true,
    content: formData.description,
    member: '64a85da4170961d267a7cdcc',
  };
  return normalizedData;
};

const CreateTopic = () => {
  const { user } = useAuthContext();
  const { mutate: createTopic, isLoading: isCreatingTopic } = useCreateTopicAPI();
  const navigate = useNavigate();
  const { id = '', channelId = '' } = useParams();
  const channelRoute = getRoutePathname.CommunitiesDetailsChannel(id ?? '', channelId ?? '');

  const onSubmit = (formData: TCreateTopicSchema) => {
    const dataTopic = normalizeTopicData({
      formData,
      channelId,
      communityId: id,
      profileId: user?.main_profile._id ?? '',
    });
    createTopic(dataTopic, {
      onSuccess: (res) => {
        navigate(getRoutePathname.CommunitiesDetailsPostsDetails(id, channelId, res._id));
      },
    });
  };

  const handleCancel = () => {
    navigate(channelRoute);
  };

  const formMethods = useForm<TCreateTopicSchema>({
    resolver: zodResolver(CreateTopicSchema),
    defaultValues: {
      name: '',
      description: emptyContent,
    },
  });

  const { handleSubmit } = formMethods;

  const handleConfirm = (formData: TCreateTopicSchema) => {
    if (onSubmit instanceof Function) {
      onSubmit({ ...formData });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%" alignItems="center">
      <Box display="flex" flexDirection="column" gap={2} width="100%" maxWidth="1080px" marginTop={1}>
        <FormProvider {...formMethods}>
          <Box component="form" onSubmit={handleSubmit(handleConfirm)}>
            <Card
              sx={{
                gap: 2,
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
              }}
            >
              <NameInputPost />
              <TiptapForDescription content={emptyContent} editable={true} />
            </Card>
            <Box
              sx={() => ({
                display: 'flex',
                gap: '8px',
                padding: '16px',
                justifyContent: 'center',
              })}
            >
              <Button disabled={isCreatingTopic} onClick={handleCancel} variant="outlined">
                Cancelar
              </Button>
              <LoadingButton loading={isCreatingTopic} variant="contained" type="submit">
                Criar
              </LoadingButton>
            </Box>
          </Box>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default CreateTopic;
