import { zodResolver } from '@hookform/resolvers/zod';
import { FormGroup, FormLabel, Stack } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { useSearchParams } from 'app-router';
import { Box, LoadingButton } from 'common/components';
import useAuthContext from 'providers/AuthProvider';

import { PasswordInput } from '../../../common/components';
import { NewPasswordSchema, TNewPasswordForm } from '../authentication.schemas';

const NewPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const { resetPassword, resetPasswordLoading } = useAuthContext();
  const formMethods = useForm<TNewPasswordForm>({
    resolver: zodResolver(NewPasswordSchema),
  });
  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<TNewPasswordForm> = (values) => {
    const { password } = values;
    resetPassword({ password, token: searchParams.get('token') ?? '' });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <FormGroup>
            <FormLabel
              sx={(theme) => ({
                color: theme.palette.secondary.main,
                paddingRight: '10px',
                marginBottom: 2,
              })}
              htmlFor="password"
            >
              Escolha sua nova senha.
            </FormLabel>
            <Box display="flex" flexDirection="column" gap={2}>
              <PasswordInput />
              <PasswordInput
                label="Confirmar senha"
                placeholder="Repita a nova senha"
                registerName="confirm_password"
              />
            </Box>
          </FormGroup>

          <LoadingButton loading={resetPasswordLoading} fullWidth size="small" variant="contained" type="submit">
            Continue
          </LoadingButton>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default NewPasswordForm;
