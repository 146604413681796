import { get } from 'lodash';
import { FormGroup, FormLabel, TextField } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Text } from 'common/components';
import { HelperMessagesEnum, InputErrorMessageEnum, InputValueEnum } from 'common/utils/validation.schemas';

const NameInputChannel = () => {
  const maxCharacteres = 30;
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const namValue = useWatch({ control, name: InputValueEnum.NAME });
  const nameLength = namValue?.length;
  const limitCharacteres = nameLength > 1 && nameLength === maxCharacteres;
  const limitCharacteresMessage = limitCharacteres ? HelperMessagesEnum.MAX_CHARACTERS : '';
  return (
    <FormGroup>
      <Box display="flex" gap={1}>
        <FormLabel
          required
          sx={(theme) => ({
            color: theme.palette.secondary.main,
            fontWeight: 500,
            '&.Mui-required': { color: 'error.main' },
            display: 'flex',
          })}
          htmlFor={InputValueEnum.NAME}
        >
          <Text sx={{ fontWeight: 500 }}>Nome</Text>
        </FormLabel>
        <Text variant="caption">
          {nameLength ?? 0}/{maxCharacteres}
        </Text>
      </Box>
      <TextField
        error={!!`${get(errors, InputErrorMessageEnum.NAME, '')}`}
        helperText={`${get(errors, InputErrorMessageEnum.NAME, '')}` || limitCharacteresMessage}
        sx={{ marginTop: 1 }}
        inputProps={{ maxLength: maxCharacteres, style: { backgroundColor: 'white' } }}
        size="small"
        {...register(InputValueEnum.NAME)}
      />
    </FormGroup>
  );
};

export default NameInputChannel;
