import { styled } from '@mui/material/styles';

import Box from './Box';

const Card = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.light}`,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  boxShadow: theme.yethosCustoms.shadows.card,
}));

export default Card;
