import { QueryClient } from 'react-query';

import { RouteObject } from 'app-router';
import { ERoutesAuthentication } from 'routes/ERoutesAuthentication';

import { Activate, ForgotPassword, Login, SignUp } from './index';
import { loaderMe } from './Login.screen';
import { BoxShellAuth } from './components';

const authtenticationRoutes: (queryClient: QueryClient) => RouteObject[] = (queryClient) => [
  {
    path: ERoutesAuthentication.Auth,
    loader: loaderMe(queryClient),
    element: <BoxShellAuth />,
    children: [
      { path: ERoutesAuthentication.Auth_Activate, element: <Activate /> },
      { path: ERoutesAuthentication.Auth_ForgotPassword, element: <ForgotPassword /> },
      { path: ERoutesAuthentication.Signup, element: <SignUp /> },
      { path: ERoutesAuthentication.Login, element: <Login /> },
    ],
  },
];

export default authtenticationRoutes;
