import React from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';

import { Navigate, Outlet, ScrollRestoration } from 'app-router';
import { UserStatusEnum } from 'modules/accounts/common/enums';
import useAuthContext from 'providers/AuthProvider';
import { getRoutePathname } from 'routes/getRoutePathname';

import Navbar from './navbar/Navbar';
import FallbackErrorBoundary from './FallbackErrorBoundary';
import AccountMenuMobile from 'modules/accounts/screens/profile/components/AccountMenuMobile';
import { Avatar, Text } from 'common/components';
import LogoMobile from 'assets/logo/LogoMobile';
import AutoCompleteHeader from './header/components/AutoCompleteHeader';
import useGetDefaultsNavigate from 'common/utils/useGetDefaultsNavigate';

const Header = React.lazy(() => import('./header/Header'));
const FooterMobile = React.lazy(() => import('./header/components/FooterMobile'));

const AppShell = () => {
  const { user } = useAuthContext();
  const { handleClickHome } = useGetDefaultsNavigate();
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (user && user.status !== UserStatusEnum.ACTIVE) {
    const activateRoute = getRoutePathname.AuthActivate(user._id);
    return <Navigate to={activateRoute} />;
  }

  return (
    <Box
      sx={() => ({
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
        gridTemplateRows: { xs: '1fr auto', sm: 'auto 1fr' },
      })}
      minHeight={'100vh'}
      width="100%"
    >
      <ScrollRestoration />

      <Box
        sx={(theme) => ({
          position: 'sticky',
          [theme.breakpoints.up('sm')]: {
            top: 0,
            gridArea: '1/1/2/3',
          },
          [theme.breakpoints.down('sm')]: {
            bottom: 0,
            gridArea: '2/1/3/3',
          },
          backgroundColor: theme.palette.info.light,
          zIndex: 1000,
        })}
      >
        {matchMobile ? <FooterMobile /> : <Header />}
      </Box>
      <Box
        gridArea={'2/1/3/2'}
        display={{ xs: 'none', sm: 'flex' }}
        sx={() => ({
          padding: `0px 16px`,
          position: 'sticky',
          top: '56px',
          zIndex: 900,
          alignSelf: 'start',
          //100vh - HeightHeader
          height: 'calc(100vh - 73px)',
          maxHeight: 'calc(100vh - 73px)',
          overflow: 'auto',
          minWidth: '114px',
        })}
      >
        <Navbar />
      </Box>
      <Box
        sx={{ gridArea: { xs: '1/1/2/3', sm: '2/2/3/3' } }}
        padding={{ xs: '0px 16px 0px 16px', sm: '0px 16px 0px 0px' }}
      >
        <ErrorBoundary FallbackComponent={FallbackErrorBoundary}>
          {matchMobile && (
            <Box marginTop={1} display="flex" alignItems="center" justifyContent="space-between">
              <Box onClick={handleClickHome}>
                <LogoMobile />
              </Box>
              <AutoCompleteHeader />
              <Box display="flex">
                <AccountMenuMobile
                  size={32}
                  name={<Text>{user?.main_profile.profile_name}</Text>}
                  avatar={
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      profileName={user?.first_name ?? ''}
                      src={user?.main_profile?.avatar_url ?? ''}
                    />
                  }
                />
              </Box>
            </Box>
          )}
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

export default AppShell;
