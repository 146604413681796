import { Avatar, Box } from 'common/components';

const SkeletonChannelShell = () => {
  return (
    <Box width="100%" display="flex" flexDirection="column" gap={2} alignItems="center" maxWidth="1080px">
      <Box
        marginTop={{ xs: 2, sm: 1 }}
        minWidth="280px"
        maxWidth="280px"
        width="280px"
        height="157.5px"
        minHeight="157.5px"
        maxHeight="157.5px"
        position="relative"
        sx={(theme) => ({
          overflow: 'hidden',
          border: `1px solid ${theme.palette.primary.light}`,
          bgcolor: theme.palette.primary.light,
        })}
      />

      <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
        <Box width="160px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />
        <Box width="90px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          padding: '10px 0px',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Avatar sx={{ width: '36.5px', height: '36.5px', bgcolor: 'primary.light' }} src={''} profileName={''} />
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-start',
            color: 'rgba(0, 0, 0, 0.36)',
            bgcolor: 'primary.light',
            width: '100%',
            height: '36.5px',
          }}
        />
      </Box>
    </Box>
  );
};

export default SkeletonChannelShell;
