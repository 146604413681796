import { useNavigate } from 'app-router';
import { getRoutePathname } from 'routes/getRoutePathname';
import ModeratorIcon from 'assets/icons/ModeratorIcon';
import AdminIcon from 'assets/icons/AdminIcon';
import { TMember } from 'modules/communities/common/types/community.types';
import { MemberRoleEnum, MemberStatusEnum } from 'modules/communities/common/enums/members.enums';
import useAuthContext from 'providers/AuthProvider';

import Box from './Box';
import Avatar from './Avatar';
import Text from './Text';

type TCompleteAvatar = {
  profileId: string;
  fullName: string;
  src: string;
  role?: TMember['role'];
  status?: TMember['status'];
  onlyName?: boolean;
};

const CompleteAvatar = ({
  profileId,
  fullName,
  src,
  onlyName,
  role = MemberRoleEnum.MEMBER,
  status = MemberStatusEnum.ACTIVE,
}: TCompleteAvatar) => {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const handleNavigatePerfil = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (user?.main_profile?._id === profileId) {
      navigate(getRoutePathname.Profile());
      return;
    } else {
      navigate(getRoutePathname.ProfileDetails(profileId));
      return;
    }
  };
  const isBanned = status === MemberStatusEnum.BANNED;

  return (
    <Box display="flex" gap={1} alignItems="center" onClick={handleNavigatePerfil} sx={{ cursor: 'pointer' }}>
      {!onlyName && (
        <Avatar profileName={fullName} src={src} sx={{ width: '24px', height: '24px', fontSize: '.75rem' }} />
      )}
      <Text variant="h5" sx={{ color: isBanned ? 'error.main' : undefined, lineHeight: 1 }}>
        {fullName}
      </Text>
      {role === MemberRoleEnum.ADMIN && <AdminIcon />}
      {role === MemberRoleEnum.MODERATOR && <ModeratorIcon />}
    </Box>
  );
};

export default CompleteAvatar;
