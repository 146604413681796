export const getImageUrl = (file: File | null) => {
  if (!file) return '';

  const imageUrl = URL.createObjectURL(file);
  return imageUrl;
};

export const getImgSrc = (img: FileList | string | File | undefined) => {
  if (typeof img === 'string') return img;
  if (!!img) {
    if (img instanceof FileList) {
      return getImageUrl(img.item(0));
    } else return getImageUrl(img);
  } else return '';
};

export const getImgFile = (img: FileList | string | File | undefined) => {
  if (typeof img === 'string') return null;
  if (!!img) {
    if (img instanceof FileList) {
      return img.item(0);
    } else return img;
  } else return null;
};
