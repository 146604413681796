import React from 'react';
import { Tabs, Tab } from '@mui/material';

import { Box, TabPanel } from 'common/components';
import { TCommunity, TMember } from 'modules/communities/common/types/community.types';
import { MemberRoleEnum } from 'modules/communities/common/enums/members.enums';

import PanelGeralCommunity from './PanelGeralCommunity';
import PanelMembersCommunity from './PanelMembersCommunity';
import PanelSettingsCommunity from './PanelSettingsCommunity';

type PanelCommunityProps = {
  community: TCommunity;
  meMember: TMember | null;
  isFounder: boolean;
};

const styleTab = (theme: any) => ({
  textTransform: 'none',
  borderRadius: 999,
  ...theme.typography.h5,
  padding: '8px 16px',
  minHeight: '40px',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    background: '#FEEBCB',
  },
});
const styleTabContainer = () => ({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  minHeight: '0px',
});

const PanelCommunity = ({ community, meMember }: PanelCommunityProps) => {
  const [value, setValue] = React.useState(0);
  const handleChangeTab = (_e: React.SyntheticEvent<Element, Event>, tabValue: number) => {
    setValue(tabValue);
  };

  const hasPermission = ({ user, permissions }: { user: TMember | null; permissions?: string[] }) => {
    const allowKeys = [user?.role ?? 'not', user?.status ?? 'not', user?.profile._id ?? 'not', 'render'];
    return permissions ? allowKeys.some((it) => permissions.includes(it)) : true;
  };

  const tabs = [
    {
      id: 'geral',
      title: 'Visão Geral',
      component: <PanelGeralCommunity meMember={meMember} founderId={community.founder._id} />,
      permissions: [MemberRoleEnum.ADMIN],
    },
    {
      id: 'members',
      title: 'Membros',
      component: <PanelMembersCommunity meMember={meMember} founderId={community.founder._id} />,
      permissions: ['render'],
    },
    {
      id: 'settings',
      title: 'Configurações',
      component: <PanelSettingsCommunity community={community} />,
      permissions: [community.founder._id],
    },
  ];

  const allowedTabs = tabs.filter((it) => {
    const permissions = it.permissions ? it.permissions : [];
    return hasPermission({ user: meMember, permissions: permissions });
  });

  return (
    <Box display="flex" flexDirection="column" marginTop={1} width="100%" gap={2} alignItems="center">
      <Tabs sx={{ ...styleTabContainer() }} value={value} onChange={handleChangeTab}>
        {allowedTabs.map((tab, idx) => (
          <Tab
            disableRipple
            disableFocusRipple
            sx={(theme) => ({ ...styleTab(theme) })}
            key={`tab_${idx}_${tab.title}`}
            label={tab.title}
          />
        ))}
      </Tabs>
      <Box display="flex" width="100%">
        {allowedTabs.map((tab, idx) => (
          <TabPanel key={`_idPanel_${tab.id}`} index={idx} value={value}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default PanelCommunity;
