type TParams = Record<string, unknown>;

export const createResourceKey = (entity: string) => ({
  _base: entity,
  list: (params: TParams = {}) => [entity, params],
  details: (id: string, params?: TParams) => [entity, { id, ...params }],
});

export const USERS_KEY = createResourceKey('users');
export const COMMUNITIES_KEY = createResourceKey('community');
export const TAGS_KEY = createResourceKey('tags');
export const CHANNELS_KEY = createResourceKey('channel');
export const POSTS_KEY = createResourceKey('posts');
export const MEMBERS_KEY = createResourceKey('members');
export const PROFILES_KEY = createResourceKey('profiles');

export default createResourceKey;
