import { Box } from '@mui/material';

import { useNavigate } from 'app-router';
import { Text } from 'common/components';
import { getRoutePathname } from 'routes/getRoutePathname';
import CommunityCard from 'common/components/CommunityCard';
import { TCommunity, TMember } from 'modules/communities/common/types/community.types';
import { MemberRoleEnum, MemberStatusEnum } from 'modules/communities/common/enums/members.enums';

type TabCommunitiesProps = {
  communities: {
    community: TCommunity;
    member: TMember;
    _id: string;
  }[];
};

const TabCommunities = ({ communities }: TabCommunitiesProps) => {
  const navigate = useNavigate();
  const onClickCommunity = (communityId: string) => {
    navigate(getRoutePathname.CommunitiesDetails(communityId));
  };

  const communitiesADMIN = communities.filter(({ member }) => member.role === MemberRoleEnum.ADMIN) ?? [];
  const communitiesMODERATOR = communities.filter(({ member }) => member.role === MemberRoleEnum.MODERATOR) ?? [];
  const filteredCommunities =
    communities.filter(
      ({ member }) =>
        member.role !== MemberRoleEnum.ADMIN &&
        member.role !== MemberRoleEnum.MODERATOR &&
        member.status === MemberStatusEnum.ACTIVE,
    ) ?? [];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '16px',
        width: '100%',
        gap: '24px',
      }}
    >
      {communitiesADMIN.length > 0 && (
        <Box>
          <Text variant="h4" sx={{ marginBottom: '10px', color: 'info.dark' }}>
            Admin
          </Text>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '24px',
              justifyContent: { xs: 'center', sm: 'flex-start' },
            }}
          >
            {communitiesADMIN.map((item) => (
              <CommunityCard
                key={`${item._id}_tab_communities_admin`}
                onClickCommunity={() => onClickCommunity(item._id)}
                community={item.community}
              />
            ))}
          </Box>
        </Box>
      )}
      {communitiesMODERATOR.length > 0 && (
        <Box>
          <Text variant="h4" sx={{ marginBottom: '10px', color: 'info.dark' }}>
            Moderador
          </Text>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '24px',
              justifyContent: { xs: 'center', sm: 'flex-start' },
            }}
          >
            {communitiesMODERATOR.map((item) => (
              <CommunityCard
                key={`${item._id}_tab_communities_admin`}
                onClickCommunity={() => onClickCommunity(item._id)}
                community={item.community}
              />
            ))}
          </Box>
        </Box>
      )}
      {filteredCommunities.length > 0 && (
        <Box>
          <Text variant="h4" sx={{ marginBottom: '10px', color: 'info.dark' }}>
            Membro
          </Text>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '24px',
              justifyContent: { xs: 'center', sm: 'flex-start' },
            }}
          >
            {filteredCommunities?.map((item) => (
              <CommunityCard
                key={`communities_${item._id}_key_membro`}
                onClickCommunity={() => onClickCommunity(item._id)}
                community={item.community}
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default TabCommunities;
