import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Stack, TextField, TextFieldProps } from '@mui/material';
import { InputErrorMessageEnum, InputValueEnum } from 'common/utils/validation.schemas';

interface NameInputProps {
  firstNameProps?: TextFieldProps;
  lastNameProps?: TextFieldProps;
}

const NameInput = ({ firstNameProps = {}, lastNameProps = {} }: NameInputProps) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <Stack direction="row" spacing={2}>
      <TextField
        error={!!`${get(errors, InputErrorMessageEnum.FIRST_NAME, '')}`}
        helperText={`${get(errors, InputErrorMessageEnum.FIRST_NAME, '')}`}
        size="small"
        label="Nome"
        placeholder="Nome"
        {...register(InputValueEnum.FIRST_NAME)}
        {...firstNameProps}
      />
      <TextField
        label="Sobrenome"
        placeholder="Sobrenome"
        error={!!`${get(errors, InputErrorMessageEnum.LAST_NAME, '')}`}
        helperText={`${get(errors, InputErrorMessageEnum.LAST_NAME, '')}`}
        size="small"
        {...register(InputValueEnum.LAST_NAME)}
        {...lastNameProps}
      />
    </Stack>
  );
};

export default NameInput;
