import { Checkbox, FormControlLabel } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useNavigate } from 'app-router';
import { CheckBoxOutlineIcon } from 'assets/icons/CheckBoxOutlineIcon';
import { Box, Button, LoadingButton, Text } from 'common/components';
import { TCreateCommunityRequest } from 'modules/communities/common/types/community.types';
import { TUser } from 'modules/accounts/common/types';
import { getRoutePathname } from 'routes/getRoutePathname';
import useAuthContext from 'providers/AuthProvider';
import useNotification from 'providers/NotificationProvider';

import { DescriptionInputCommunity, NameInputCommunity } from '../../common/components';
import { CategoryInput, CommunityCardForm, TagGroupForm } from './components';
import { useCreateCommunity } from './community.api';
import { CreateCommunitySchema, TCreateCommunityForm } from './community.schemas';

export const MAX_CHARACTERS = 90;

const normalizeCommunityData = (values: TCreateCommunityForm, user: TUser | null) => {
  const community: TCreateCommunityRequest = {
    name: values.name,
    description: values.description,
    category: values.category,
    tags: !!values.tags && values.tags?.length > 0 ? values.tags.map((tag) => tag.label) : undefined,
    settings: {
      allow_minor_members: !values.not_allow_minor_members,
    },
    founder: user?.main_profile._id ?? '',
  };
  return community;
};

const CreateCommunity = () => {
  const { toast } = useNotification();
  const { user } = useAuthContext();
  const { mutate: createCommunity, isLoading: createCommunityLoading } = useCreateCommunity();
  const isLoading = createCommunityLoading;
  const navigate = useNavigate();
  const formMethods = useForm<TCreateCommunityForm>({
    resolver: zodResolver(CreateCommunitySchema),
    defaultValues: {
      name: '',
      description: '',
      category: '',
      not_allow_minor_members: true,
      tags: [],
    },
  });

  const checkKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      return e.preventDefault();
    }
  };

  const onSubmit: SubmitHandler<TCreateCommunityForm> = (formData: TCreateCommunityForm) => {
    const community = normalizeCommunityData(formData, user);
    createCommunity(community, {
      onSuccess: (res) => {
        const communityDetails = getRoutePathname.CommunitiesDetails(res._id);
        navigate(communityDetails);
      },
      onError: () => {
        toast({ title: 'Falha ao criar comunidade', message: 'Tente novamente', type: 'error' });
      },
    });
  };
  const { handleSubmit, register } = formMethods;
  return (
    <Box
      sx={{
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
      marginBottom={2}
      marginTop={{ xs: 2, sm: 1 }}
    >
      <Text variant="h1">Criar Comunidade</Text>
      <FormProvider {...formMethods}>
        <Box
          display="flex"
          flexDirection="column"
          component="form"
          onKeyDown={(e) => checkKeyDown(e)}
          gap={4}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            width="100%"
            display="flex"
            sx={(theme) => ({ [theme.breakpoints.down('md')]: { flexDirection: 'column' } })}
            gap={4}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
              }}
            >
              <NameInputCommunity />
              <DescriptionInputCommunity maxCharacteres={MAX_CHARACTERS} />
              <CategoryInput />
              <TagGroupForm />
              <Box>
                <Text fontWeight={500}>Conteudo adulto</Text>
                <FormControlLabel
                  control={
                    <Checkbox
                      {...register('not_allow_minor_members')}
                      sx={{
                        color: 'primary.light',
                        '&:hover': { backgroundColor: 'transparent' },
                        width: '38px',
                        height: '38px',
                      }}
                      icon={<CheckBoxOutlineIcon />}
                    />
                  }
                  label="Comunidade para maiores de 18 anos"
                />
              </Box>
            </Box>
            <Box
              display="flex"
              sx={(theme) => ({ [theme.breakpoints.down('md')]: { alignItems: 'center' } })}
              flexDirection="column"
              gap={1}
            >
              <Text fontWeight={500}>Preview do cartão da comunidade</Text>
              <CommunityCardForm />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              alignSelf: 'center',
              alignItems: 'center',
            }}
          >
            <Button onClick={() => navigate(getRoutePathname.Communities())} variant="outlined">
              Voltar
            </Button>
            <LoadingButton loading={isLoading} disabled={isLoading} variant="contained" type="submit">
              Criar
            </LoadingButton>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default CreateCommunity;
