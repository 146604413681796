import { styled } from '@mui/material';

export const CheckBoxOutlineIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 20,
  height: 20,
  border: `2px solid ${theme.palette.primary.light}`,
  backgroundColor: 'white',

  'input:hover ~ &': {
    backgroundColor: 'white',
  },
  'input:disabled ~ &': {
    background: `${theme.palette.info.dark}`,
  },
}));
