import React from 'react';
import AddIcon from '@mui/icons-material/Add';

import { useNavigate, useParams } from 'app-router';
import { Box, Button } from 'common/components';
import { getRoutePathname } from 'routes/getRoutePathname';

interface ChannelListProps {
  channels: React.ReactNode;
  isAdmin: boolean;
}

const ChannelList = ({ channels, isAdmin }: ChannelListProps) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const handleCreateChannel = () => {
    const createChannelRoute = getRoutePathname.CommunitiesDetailsChannelAdd(id ?? '');
    navigate(createChannelRoute);
  };
  return (
    <Box display="flex" width="100%" gap={1} flexDirection="column" marginTop={1}>
      {isAdmin && (
        <Box>
          <Button
            onClick={handleCreateChannel}
            sx={{ width: 'fit-content' }}
            startIcon={<AddIcon />}
            variant="outlined"
          >
            Criar Canal
          </Button>
        </Box>
      )}
      <Box
        gap={1}
        display="flex"
        width="100%"
        flexWrap="wrap"
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
        padding={`0`}
      >
        {channels}
      </Box>
    </Box>
  );
};

export default ChannelList;
