import React from 'react';
import { Tooltip } from '@mui/material';

import { useNavigate } from 'app-router';
import LogoCircle from 'assets/logo/LogoCircle';
import { Box, Image, Text } from 'common/components';
import { getRoutePathname } from 'routes/getRoutePathname';

import { useGetCommunitiesByManyAPI } from '../community.api';

//TODO MAKE THIS A INFINITE QUERY
const RecommendedCommunity = () => {
  const {
    data: returnCommunities,
    isLoading,
    hasNextPage: hasNextPageCommunities,
    fetchNextPage: fetchNextPageCommunities,
  } = useGetCommunitiesByManyAPI({ page: 0, size: 15 });

  const navigate = useNavigate();
  const communities = returnCommunities?.pages ?? [];

  React.useEffect(() => {
    let loading = false;
    const onScroll = async (event: any) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement;
      if (!loading && scrollHeight - scrollTop <= clientHeight * 1.4) {
        loading = true;
        if (hasNextPageCommunities) {
          await fetchNextPageCommunities();
          loading = false;
        }
      }
    };
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isLoading]);

  return communities.length > 0 ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '240px',
        width: '100%',
        gap: 3,
      }}
    >
      <Text fontWeight={700}>Comunidades Recomendadas</Text>
      {communities.map((page, idx) => {
        return (
          <React.Fragment key={`key_${idx}_recommended`}>
            {page.items.map((community, idx) => {
              const onClickCommunity = () => {
                navigate(getRoutePathname.CommunitiesDetails(community._id));
              };
              const communityTotalMembers =
                community.members_count > 1000
                  ? (community.members_count / 1000).toFixed(1) + `K Membros`
                  : community?.members_count + ` Membros`;
              return (
                <Box
                  key={`_recommended_${community.name}_id_${idx}`}
                  onClick={onClickCommunity}
                  display="grid"
                  sx={(theme) => ({
                    gridTemplateColumns: '80px auto',
                    gridTemplateRows: 'auto',
                    alignItems: 'flex-start',
                    [theme.breakpoints.down(1000)]: {
                      display: 'flex',
                      flexDirection: 'column',
                    },

                    gap: 1,
                    cursor: 'pointer',
                    '&:active': {
                      cursor: 'default',
                    },
                  })}
                >
                  <Box
                    className="communityImg"
                    height="45px"
                    width="80px"
                    sx={(theme) => ({
                      borderRadius: theme.spacing(1),
                      boxShadow: theme.yethosCustoms.shadows.card,
                      overflow: 'hidden',
                    })}
                  >
                    {community.avatar_url ? (
                      <Image src={community.avatar_url ?? ''} />
                    ) : (
                      <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                        <LogoCircle />
                      </Box>
                    )}
                  </Box>
                  <Tooltip arrow title={community.description}>
                    <Box>
                      <Text fontWeight={700} variant="subtitle2">
                        {community.name}
                      </Text>
                      <Text fontSize={'12px'}>{communityTotalMembers}</Text>
                    </Box>
                  </Tooltip>
                </Box>
              );
            })}
          </React.Fragment>
        );
      })}
    </Box>
  ) : null;
};

export default RecommendedCommunity;
