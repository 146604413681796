import { createTheme } from '@mui/material/styles';

const typographyStyles = {
  h1: {
    fontWeight: 700,
    fontSize: '1.25rem',
    lineHeight: 1.5,
  },
  h2: {
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: 1.5,
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: 1.5,
  },
  h4: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  h5: {
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: 1.5,
  },
  body1: {
    fontSize: '.875rem',
  },
  body2: {
    fontSize: '.75rem',
  },
  caption: {
    fontSize: '.875rem',
    lineHeight: 1.5,
  },
  caption2: {
    fontSize: '13px',
    lineHeight: 1.5,
  },
};

export const YETHOS_THEME_MUI = createTheme({
  palette: {
    primary: {
      light: '#E2E8F0',
      main: '#DD6B20',
      dark: '#C05621',
      contrastText: '#F7FAFC',
    },
    secondary: {
      light: '#EDF2F7',
      main: '#2D3748',
      dark: '#E2E8F0',
      contrastText: '#F7FAFC',
    },
    info: {
      main: '#EDF2F7',
      dark: '#718096',
      contrastText: '#CBD5E0',
      light: '#F7FAFC',
    },
    error: {
      main: '#E53E3E',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1300,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: 'Inter',
    ...typographyStyles,
  },
  yethosCustoms: {
    shadows: {
      // TODO: name these shadows better by depth instead (Felipe)
      card: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
      cardHover: '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.06)',
    },
  },
});

YETHOS_THEME_MUI.components = {
  MuiButton: {
    styleOverrides: {
      outlined: ({ theme }) => ({
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.dark,
        '&:hover': {
          borderColor: theme.palette.secondary.dark,
          backgroundColor: theme.palette.secondary.light,
        },
      }),
      outlinedSecondary: ({ theme }) => ({
        color: theme.palette.secondary.light,
        '&:hover': {
          color: theme.palette.secondary.main,
        },
      }),
      text: ({ theme }) => ({
        color: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: theme.palette.secondary.light,
        },
      }),
      textSecondary: ({ theme }) => ({
        color: theme.palette.info.main,
        '&:hover': {
          backgroundColor: '#4A5568',
        },
      }),
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltipArrow: () => ({
        backgroundColor: 'white',
        boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)',
      }),
      tooltip: ({ theme }) => ({
        backgroundColor: 'white',
        color: theme.palette.secondary.main,
        boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)',
        '> span': {
          color: 'white',
        },
      }),
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary.main,
      }),
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary.main,
      }),
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        caption2: 'p',
      },
    },
  },
};
