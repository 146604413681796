import { useMutation, useQuery, useQueryClient } from 'react-query';

import { axiosService } from 'common/services/axiosService';
import { PROFILES_KEY, USERS_KEY } from 'common/utils/queryKeys';
import { HEADER_CONFIG_UPLOAD_FILE } from 'common/utils/api.constants';
import useNotification from 'providers/NotificationProvider';

import { ACCOUNTS_API_PATH } from '../constants';
import { TPostUploadProfile, TProfile } from '../types/profiles.types';
import { TUser } from '../types';

export function useGetProfileByUserIdAPI(userId: string | undefined) {
  const queryClient = useQueryClient();
  async function fetchUser() {
    const response = await axiosService.get<TProfile>(`${ACCOUNTS_API_PATH}/profiles/users/${userId}`);
    return response.data;
  }
  return useQuery(PROFILES_KEY.details(userId ?? ''), fetchUser, {
    enabled: !!userId,
    onSuccess: (response) => {
      queryClient.setQueryData(PROFILES_KEY.details(response._id), response);
    },
  });
}
export async function fetchProfileById(profileId: string) {
  const { data } = await axiosService.get<TProfile>(`${ACCOUNTS_API_PATH}/profiles/${profileId}`);
  return data;
}
export function useGetProfileByIdAPI(profileId: string) {
  return useQuery(PROFILES_KEY.details(profileId), () => fetchProfileById(profileId));
}

export function useUpdateProfileByIdAPI() {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  async function fetchUpdateProfile({
    profileId,
    profile_name,
    short_bio,
  }: Pick<TPostUploadProfile, 'profileId' | 'profile_name' | 'short_bio'>) {
    const body = { profile_name, short_bio };
    const { data } = await axiosService.patch<TProfile>(`${ACCOUNTS_API_PATH}/profiles/${profileId}`, body);
    return data;
  }
  return useMutation({
    mutationFn: fetchUpdateProfile,
    onSuccess: () => {
      toast({ title: 'Perfil atualizado.' });
    },
    onError: () => {
      toast({ title: 'Oops, não conseguimos salvar', type: 'error' });
    },
    onSettled: (profile) => {
      queryClient.invalidateQueries(PROFILES_KEY.details(profile?._id ?? ''));
    },
  });
}

async function fetchUploadImgById({
  id,
  file,
  associationKind,
}: {
  id: string;
  file: File | null;
  associationKind: 'avatar' | 'banner';
}) {
  const body = {
    file: file,
    ref_id: id,
    association: 'profiles',
    association_kind: associationKind,
  };
  const { data } = await axiosService.post('upload/api/v1/media/images', body, HEADER_CONFIG_UPLOAD_FILE);
  return data;
}

export function useUpdateProfileAvatarByIdAPI(id: string) {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  return useMutation({
    mutationFn: (file: File | null) => fetchUploadImgById({ id, file, associationKind: 'avatar' }),
    onSuccess: (res) => {
      toast({ title: 'Avatar atualizado.' });
      const profile: TUser['main_profile'] | undefined = queryClient.getQueryData(PROFILES_KEY.details(id));
      const user: TUser | undefined = queryClient.getQueryData([USERS_KEY._base, 'me']);
      const avatar_url = res.image.url;
      const newProfile = { ...profile, avatar_url };
      const newUser = { ...user, main_profile: { ...user?.main_profile, ...newProfile } };
      queryClient.setQueryData(PROFILES_KEY.details(id), newProfile);
      queryClient.setQueryData([USERS_KEY._base, 'me'], newUser);
    },
    onError: () => {
      toast({ title: 'Erro ao atualizar avatar', type: 'error' });
    },
  });
}

export function useUpdateProfileBannerByIdAPI(id: string) {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  return useMutation({
    mutationFn: (file: File | null) => fetchUploadImgById({ id, file, associationKind: 'banner' }),
    onSuccess: (res) => {
      toast({ title: 'Capa atualizado.' });
      const profile: TUser['main_profile'] | undefined = queryClient.getQueryData(PROFILES_KEY.details(id));
      const user: TUser | undefined = queryClient.getQueryData([USERS_KEY._base, 'me']);
      const banner_url = res.image.url;
      const newProfile = { ...profile, banner_url };
      const newUser = { ...user, main_profile: { ...user?.main_profile, ...newProfile } };
      queryClient.setQueryData(PROFILES_KEY.details(id), newProfile);
      queryClient.setQueryData([USERS_KEY._base, 'me'], newUser);
    },
    onError: () => {
      toast({ title: 'Erro ao atualizar a capa', type: 'error' });
    },
  });
}
