import { HelperMessagesEnum, validationZod } from 'common/utils/validation.schemas';
import { z } from 'zod';

//UPDATE PROFILE SCHEMA
export const UpdateProfileSchema = z.object({
  profile_name: z
    .string()
    .min(1, { message: 'Nome é obrigatório.' })
    .max(30, { message: HelperMessagesEnum.MAX_CHARACTERS }),
  short_bio: z.string().max(90, { message: HelperMessagesEnum.MAX_CHARACTERS }).optional(),
});

export type TUpdateProfileForm = z.infer<typeof UpdateProfileSchema>;

//UPDATE PROFILE IMGS SCHEMA
const FileSchema = z
  .custom<string | File | FileList>((val) => {
    if (typeof val === 'string' || val instanceof File || val instanceof FileList) {
      return true;
    } else return false;
  }, 'Arquivo inválido')
  .optional();

export const UpdateProfileImgsSchema = z.object({
  avatarProfile: FileSchema.optional(),
  bannerUrl: FileSchema.optional(),
});

export type TUpdateProfileImgsForm = z.infer<typeof UpdateProfileImgsSchema>;

//CHANGE PASSWORD SCHEMA
export const ChangePasswordSchema = z
  .object({
    current_password: z.string().min(1, { message: 'Senha atual é obrigatório' }),
    password: validationZod.password,
    confirm_password: validationZod.password,
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Senhas precisam ser iguais.',
    path: ['confirm_password'],
  });

export type TChangePasswordForm = z.infer<typeof ChangePasswordSchema>;
