import { Box, Button, Avatar } from 'common/components/index';
import { PostProps } from 'common/services/mocks/mockData';
import useAuthContext from 'providers/AuthProvider';

type PostCardProps = {
  post?: PostProps;
  channelId?: string;
  id?: string;
  handleCreateTopic?: () => void;
};

const CreateTopicButton = ({ handleCreateTopic }: PostCardProps) => {
  const { user } = useAuthContext();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        padding: '10px 0px',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Avatar
        sx={{ width: '36.5px', height: '36.5px' }}
        src={user?.main_profile?.avatar_url ?? ''}
        profileName={user?.first_name ?? ''}
      />
      <Button
        onClick={handleCreateTopic}
        variant="outlined"
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-start',
          color: 'rgba(0, 0, 0, 0.36)',
          bgcolor: 'white',
          '&:hover': { backgroundColor: 'white' },
        }}
      >
        Criar tópico
      </Button>
    </Box>
  );
};

export default CreateTopicButton;
