import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { axiosService } from 'common/services/axiosService';
import { getRoutePathname } from 'routes/getRoutePathname';
import { useMeAPI } from 'modules/accounts/common/apis/users.api';
import {
  useActivateUserAPI,
  useLoginAPI,
  useLogoutAPI,
  useResendActivateUserAPI,
  useForgotPasswordAPI,
  useResetPasswordAPI,
} from 'modules/accounts/common/apis/authentication.api';
import { TActivateUser, TCredentials, TResetPassword, TUser } from 'modules/accounts/common/types';
import { UserStatusEnum } from 'modules/accounts/common/enums';

import useNotification from './NotificationProvider';

interface AuthContextProps {
  activateUser: (activateUser: TActivateUser) => void;
  login: (credentials: TCredentials) => void;
  logout: (func?: () => void) => void;
  resendActivationCode: (userId: string) => void;
  forgotPassword: (email: string) => void;
  resetPassword: (body: TResetPassword) => void;
  authLoading: boolean;
  forgotPasswordLoading: boolean;
  activateUserIsLoading: boolean;
  loginLoading: boolean;
  logoutLoading: boolean;
  resetPasswordLoading: boolean;
  resendActivationCodeLoading: boolean;
  user: TUser | null;
  updateUser: (newUser: any) => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const profileRoute = getRoutePathname.Profile();
  const loginRoute = getRoutePathname.Login();
  const { pathname, search } = location;
  const [searchParams] = useSearchParams();
  const searchParamsValue = searchParams.get('redirectTo') ?? profileRoute;
  const { toast } = useNotification();

  const useLoginMutation = useLoginAPI();
  const useLogoutMutation = useLogoutAPI();
  const userActivateUserMutation = useActivateUserAPI();
  const useResendActivateUserMutation = useResendActivateUserAPI();
  const useForgotPasswordMutation = useForgotPasswordAPI();
  const useResetPasswordMutation = useResetPasswordAPI();

  const [user, setUser] = useState<AuthContextProps['user']>(null);

  React.useLayoutEffect(() => {
    axiosService.interceptors.response.use(
      function (config) {
        return config;
      },
      function (error) {
        if (error.response?.status === 401) {
          setUser(null);
          navigate({
            pathname: loginRoute,
            search: pathname.includes('auth') ? '' : `?${createSearchParams({ redirectTo: pathname + search })}`,
          });
        }
        return Promise.reject(error);
      },
    );
  }, []);

  const shouldFetchMe = React.useRef(true);

  const { isLoading } = useMeAPI({
    enabled: !!shouldFetchMe,
    onSettled: () => {
      shouldFetchMe.current = false;
    },

    onSuccess: (res: TUser) => {
      setUser(res);
    },
  });

  const updateUser = (newData: any) => {
    setUser((prevUser) => {
      const newUser = prevUser ? { ...prevUser, ...newData } : prevUser;
      return newUser;
    });
  };

  const login = async (credentials: TCredentials) => {
    try {
      const { data } = await useLoginMutation.mutateAsync(credentials);
      setUser(data);
      if (data.status === UserStatusEnum.WAITING_ACTIVATION) {
        navigate(getRoutePathname.AuthActivate(data._id));
      }
      if (data.status === UserStatusEnum.ACTIVE) {
        const validateParams = searchParamsValue.toLowerCase().includes('auth') ? profileRoute : searchParamsValue;
        navigate(validateParams);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        const { status } = error?.response?.data;
        let title;
        switch (status) {
          case 404:
            title = 'Usuário não existe.';
            break;
          case 401:
            title = 'Usuário ou senha incorretos.';
            break;
          default:
            title = 'Falha ao conectar com o servidor.';
            break;
        }
        return toast({ title, type: 'error' });
      }
    }
  };

  const logout = async (func?: () => void) => {
    try {
      await useLogoutMutation.mutateAsync();
      func && func();
      setUser(null);
      navigate(getRoutePathname.Login());
    } catch (error) {
      toast({ title: 'Algo deu errado. Tente novamente.', type: 'error' });
    }
  };

  const activateUser = async (activateUser: TActivateUser) => {
    try {
      const response = await userActivateUserMutation.mutateAsync(activateUser);
      setUser(response.data);
      navigate(searchParamsValue);
    } catch (error) {
      toast({ title: 'Algo deu errado. Tente novamente.', type: 'error' });
    }
  };

  const resendActivationCode = async (userId: string) => {
    try {
      await useResendActivateUserMutation.mutateAsync(userId);
      toast({ title: 'O código foi enviado para o seu e-mail.', type: 'success' });
    } catch (error) {
      toast({ title: 'Algo deu errado.', type: 'error' });
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await useForgotPasswordMutation.mutateAsync(email);
      toast({ title: 'Verifique seu e-mail para continuar.', type: 'info' });
    } catch (error) {
      toast({ title: 'Algo deu errado.', type: 'error' });
    }
  };

  const resetPassword = async (body: TResetPassword) => {
    try {
      await useResetPasswordMutation.mutateAsync(body);
      toast({ title: 'Senha alterada com sucesso.', type: 'success' });
      navigate(getRoutePathname.Login());
    } catch (error) {
      toast({ title: 'Algo deu errado.', type: 'error' });
    }
  };

  const value = {
    user,
    updateUser,
    activateUser,
    forgotPassword,
    login,
    logout,
    resendActivationCode,
    resetPassword,
    resetPasswordLoading: useResetPasswordMutation.isLoading,
    resendActivationCodeLoading: useResendActivateUserMutation.isLoading,
    authLoading: isLoading,
    activateUserIsLoading: userActivateUserMutation.isLoading,
    forgotPasswordLoading: useForgotPasswordMutation.isLoading,
    logoutLoading: useLogoutMutation.isLoading,
    loginLoading: useLoginMutation.isLoading,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider.');
  }
  return context;
};

export default useAuthContext;
