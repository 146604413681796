import { validationZod } from 'common/utils/validation.schemas';
import { GenderEnum } from 'modules/accounts/common/enums';
import { z } from 'zod';

//LOGIN SCHEMA
export const LoginSchema = z.object({
  email: validationZod.email,
  password: validationZod.required,
});

export type TLoginForm = z.infer<typeof LoginSchema>;

//SIGNUP SCHEMA
const genderValues = Object.values(GenderEnum);
const Gender = [genderValues[0], ...genderValues.slice(1)] as const;

export const SignUpSchema = z.object({
  first_name: z.string().min(1, { message: 'Primeiro nome é obrigatório.' }),
  last_name: z.string().min(1, { message: 'Sobrenome é obrigatório.' }),
  email: validationZod.email,
  password: validationZod.password,
  gender: z.enum(Gender),
  year_of_birth: z.string().min(1),
  month_of_birth: z.string().min(1),
  day_of_birth: z.string().min(1),
});

export type TSignUpForm = z.infer<typeof SignUpSchema>;

//FORGOT PASSWORD SCHEMA
export const ForgotPasswordSchema = z.object({
  email: validationZod.email,
});

export type TForgotPasswordForm = z.infer<typeof ForgotPasswordSchema>;

//ACTIVATE SCHEMA
export const ActivateSchema = z.object({
  code: z.coerce
    .number({ invalid_type_error: 'Código é obrigatório e precisa ser número' })
    .gte(1, { message: 'Código é obrigatório' }),
});

export type TActivateForm = z.infer<typeof ActivateSchema>;

//NEW PASSWORD SCHEMA
export const NewPasswordSchema = z
  .object({
    password: validationZod.password,
    confirm_password: validationZod.password,
  })
  .refine((data) => data.password === data.confirm_password, {
    message: 'Senhas precisam ser iguais.',
    path: ['confirm_password'],
  });

export type TNewPasswordForm = z.infer<typeof NewPasswordSchema>;
