import { LinearProgress, Stack } from '@mui/material';
import { Box } from 'common/components';

const LogoLoading = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh" width="100%">
      <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M100 50C100 77.6143 77.6143 100 50 100C22.3857 100 0 77.6143 0 50C0 22.3857 22.3857 0 50 0C77.6143 0 100 22.3857 100 50Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100 50C100 77.6143 77.6143 100 50 100C22.3857 100 0 77.6143 0 50C0 22.3857 22.3857 0 50 0C77.6143 0 100 22.3857 100 50ZM59.8128 54.9498L59.5475 55.2198V55.6015V72.4415C59.5475 78.0408 55.0853 82.58 49.5808 82.58C44.0763 82.58 39.614 78.0408 39.614 72.4415V54.7757V54.3937L39.3488 54.1237L24.0598 38.5715C29.909 42.9075 39.498 46.8065 49.9328 36.2142L49.9867 36.2692L50.6273 35.6175L65.597 20.3895C69.4893 16.4301 75.8 16.4301 79.692 20.3895C83.5845 24.3489 83.5845 30.7682 79.692 34.7277L59.8128 54.9498ZM35.6842 19.1131L49.9867 33.6623L64.3157 19.086C68.9157 14.4067 76.3735 14.4067 80.9735 19.086C85.5733 23.7652 85.5733 31.3518 80.9735 36.031L61.3595 55.9835V72.4415C61.3595 79.0588 56.086 84.4235 49.5808 84.4235C43.0755 84.4235 37.8017 79.0588 37.8017 72.4415V55.1573L19.0265 36.0582C14.4266 31.379 14.4266 23.7924 19.0265 19.1131C23.6265 14.4339 31.0845 14.4339 35.6842 19.1131Z"
            fill="url(#paint0_linear_3436_59956)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3436_59956"
              x1="50"
              y1="0"
              x2="50"
              y2="100"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#DD6B20" />
              <stop offset="0.588542" stopColor="#DD6B20" />
              <stop offset="1" stopColor="#F6AD55" />
            </linearGradient>
          </defs>
        </svg>

        <Stack sx={{ width: '100%', color: 'grey.500' }}>
          <LinearProgress color="primary" />
        </Stack>
      </Box>
    </Box>
  );
};

export default LogoLoading;
