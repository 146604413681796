import { axiosService } from 'common/services/axiosService';
import { useQuery } from 'react-query';
import createResourceKey from './queryKeys';

//TODO: MOVE TO GITIGNORE
const ACCESS_KEY = 'y6DmsWQu4aki31tIOG9beXe95xhYBANpcHQS_3AlGXY';
// const SECRET_KEY = 'DpMhSEHwtW7IFIwkxKoDAqOs6uHIH8auKBxnosRiJSA';
const URL_API = 'https://api.unsplash.com/search/photos';
const IMAGES_KEY = createResourceKey('images');

export const useGetImagesByManyAPI = (query: string) => {
  const fetchImages = async () => {
    const { data } = await axiosService.get(URL_API, {
      params: { query, client_id: ACCESS_KEY, per_page: 30 },
      withCredentials: false,
    });
    return data;
  };
  const response = useQuery(IMAGES_KEY.list({ query }), fetchImages);

  return response;
};
