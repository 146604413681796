import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { useParams } from 'app-router';
import SkeletonCommunityAppShell from 'assets/skeletons/SkeletonCommunityAppShell';
import { Image, Text, Box } from 'common/components';
import { categoriesArr } from 'common/utils/filters.community.constants';
import CompleteAvatar from 'common/components/CompleteAvatar';
import useAuthContext from 'providers/AuthProvider';

import EditCommunityDialog from '../../screens/community/components/EditCommunityDialog';
import { useGetMembersByCommunityIdAPI } from '../../screens/community/communityMembers.api';
import { useGetCommunityByIdAPI } from '../../screens/community/community.api';
import MemberButtonsCommunity from './MemberButtonsCommunity';
import { MemberRoleEnum } from '../enums/members.enums';
import BeMemberButton from './BeMemberButton';
import SearchTopics from './SearchTopics';

type TCommunityAppShell = {
  children: React.ReactNode;
};

const minmaxWidth = (number: number) => {
  const value = { width: `${number}px`, maxWidth: `${number}px`, minWidth: `${number}px` };
  return value;
};
const minmaxHeight = (number: number) => {
  const value = { height: `${number}px`, maxHeight: `${number}px`, minHeight: `${number}px` };
  return value;
};

const CommunityAppShell = ({ children }: TCommunityAppShell) => {
  const { user } = useAuthContext();
  const [openEdit, setOpenEdit] = React.useState(false);
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const { id } = useParams();
  const { data: community, isLoading: isLoadingCommunity } = useGetCommunityByIdAPI(id ?? '');
  const { data: returnMembers, isLoading: isLoadingMembers } = useGetMembersByCommunityIdAPI(id ?? '', {
    page: 0,
    size: 10,
  });

  const isLoading = isLoadingMembers || isLoadingCommunity;
  const members = returnMembers?.items ?? [];

  const communityMembersCount = community?.members_count ?? 1;
  const memberMe = members.find((member) => member.profile._id === user?.main_profile._id) ?? null;
  const isAdmin = memberMe?.role === MemberRoleEnum.ADMIN;

  const communityTotalMembers =
    communityMembersCount <= 1
      ? 1 + ' Membro'
      : communityMembersCount > 1000
      ? (communityMembersCount / 1000).toFixed(1) + `K Membros`
      : communityMembersCount + ` Membros`;
  const categoryLabel = community ? categoriesArr.find((item) => item.value === community.category)?.label : null;

  if (isLoading) {
    return <SkeletonCommunityAppShell />;
  }
  if (!community) {
    return <div>No community.</div>;
  }

  return (
    <Box
      sx={(theme) => ({
        bgcolor: theme.palette.info.light,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `"header"
                           "content"`,
      })}
      width="100%"
      marginTop={{ xs: 2, sm: 0 }}
    >
      {isAdmin && (
        <EditCommunityDialog
          communityId={community._id}
          avatarUrl={community.avatar_url}
          bannerUrl={community.banner_url}
          open={openEdit}
          handleClose={handleCloseEdit}
        />
      )}
      <Box gridArea="header" sx={(theme) => ({ [theme.breakpoints.down('sm')]: { marginTop: '15.5px' } })}>
        <Box
          sx={() => ({
            borderRadius: '0px 0px 6px 6px',
            overflow: 'hidden',
          })}
          height={{ xs: '48px', sm: '100px' }}
        >
          <Image src={community.banner_url} />
        </Box>
        <Box
          display="flex"
          sx={(theme) => ({ [theme.breakpoints.down('sm')]: { flexDirection: 'column' } })}
          width="100%"
          alignItems="flex-start"
        >
          <Box
            marginTop={1}
            position="relative"
            sx={(theme) => ({
              overflow: 'hidden',
              border: `1px solid ${theme.palette.primary.light}`,
              ...minmaxHeight(90),
              ...minmaxWidth(160),
              [theme.breakpoints.down('sm')]: {
                ...minmaxHeight(79),
                maxWidth: '85px',
                minWidth: '0px',
                width: 'fit-content',
                border: 'none',
                //(avatar height - banner height)/2 + banner height
              },
            })}
          >
            <Image src={community.avatar_url} />

            {isAdmin && (
              <Box
                position="absolute"
                sx={{
                  right: '8px',
                  bottom: '8px',
                  zIndex: 999,
                }}
              >
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={handleOpenEdit}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.info.main,
                    '&:hover': { backgroundColor: theme.palette.primary.light },
                    borderRadius: '6px',
                  })}
                >
                  <EditOutlinedIcon sx={{ width: '16px', height: '16px' }} />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="column" width="100%" padding="8px 0px 0px 8px" gap={1}>
            <Box display="flex" alignItems="center" gap={1}>
              {/* <Box display="flex" width="100%"> */}
              <Text variant="h3">{community.name}</Text>
              {/* </Box> */}
              <Box
                display="flex"
                sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}
                gap={1}
                alignItems="center"
              >
                <Text variant="caption">por</Text>
                <CompleteAvatar
                  fullName={community.founder.profile_name}
                  profileId={community.founder._id}
                  src={community.founder.avatar_url}
                />
              </Box>
              <Box display="flex" minWidth="fit-content" flex={1} justifyContent="flex-end" gap={1}>
                {!matchMobile && <SearchTopics />}
                <MemberButtonsCommunity member={memberMe} />
                {!matchMobile && <BeMemberButton member={memberMe} />}
              </Box>
            </Box>
            <Box display="flex" gap={1} justifyContent="space-between" alignItems="center">
              <Box display="flex" gap={1} alignItems="center">
                <Text variant="body1">{categoryLabel}</Text>
                <Text variant="h4">·</Text>

                <Text variant="body1">{communityTotalMembers}</Text>
              </Box>
              {matchMobile && <BeMemberButton member={memberMe} />}
            </Box>
            <Text variant="body1">{community.description}</Text>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        sx={{ gridArea: { xs: '2/1/3/3', md: '2/1/3/2' } }}
        gap={2}
        padding={{ xs: '0px 0px 16px 0px', md: '0px 0px 16px 0px' }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default CommunityAppShell;
