import { useParams } from 'app-router';

import { Box, Text } from 'common/components';
import { MemberRoleEnum, MemberStatusEnum } from 'modules/communities/common/enums/members.enums';
import { TMember } from 'modules/communities/common/types/community.types';
import useAuthContext from 'providers/AuthProvider';

import { useGetMembersByCommunityIdAPI } from '../communityMembers.api';
import CardMemberForPanel from './CardMemberForPanel';

type TPanelGeralCommunity = {
  meMember: TMember | null;
  founderId: string;
};

const PanelGeralCommunity = ({ meMember, founderId }: TPanelGeralCommunity) => {
  const { user } = useAuthContext();
  const { id } = useParams();
  const { data: returnMembers } = useGetMembersByCommunityIdAPI(id ?? '', { page: 0, size: 15 });

  const members = returnMembers?.items ?? [];

  const visibleMembers = members.filter(
    (member) =>
      member.status === MemberStatusEnum.ACTIVE ||
      member.status === MemberStatusEnum.BANNED ||
      member.status === MemberStatusEnum.SUSPENDED,
  );

  const activeMembers = visibleMembers.filter((member) => member.status === MemberStatusEnum.ACTIVE)?.slice(0, 4);

  return (
    <Box display="flex" width="100%" justifyContent="center">
      <Box display="flex" width="100%" maxWidth="1080px" justifyContent="center" flexWrap="wrap" gap={2}>
        <Box display="flex" flexDirection="column" flex={1} gap={1}>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Text variant="h4">Membros mais ativos</Text>
            <Text variant="body2">Últimos 7 dias</Text>
          </Box>
          <Box display="flex" flexDirection="column" gap={1} flexWrap="wrap">
            {activeMembers.map((member, idx) => {
              const notShowOptions = member.role === MemberRoleEnum.ADMIN && founderId !== user?.main_profile._id;
              const shouldRenderOptions =
                founderId !== member.profile._id && user?.main_profile._id !== member.profile._id && !notShowOptions;
              return (
                <CardMemberForPanel
                  key={`member_panel_${member._id}`}
                  founderId={founderId}
                  meMember={meMember}
                  member={member}
                  shouldRenderOptions={shouldRenderOptions}
                  idx={idx}
                />
              );
            })}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flex={1} gap={1}>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Text variant="h4">Estatísticas</Text>
            <Text variant="body2">Últimos 7 dias</Text>
          </Box>
          <Box display="flex" padding="24px 40px 24px 16px" justifyContent="space-between" alignItems="center">
            <Text variant="h4">Tópico</Text>
            <Box display="flex" gap={1} alignItems="center">
              <Text variant="h4">#</Text>
              <Text variant="h4">%</Text>
              <Text variant="h4">#</Text>
              <Text variant="h4">%</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PanelGeralCommunity;
