import { styled } from '@mui/material/styles';

const WrapperSearchInput = styled('div')(
  () => `
   border-radius: 8px;
        background-color: #FFF;
        position: relative;
        width: 100%;
        border: 1px solid #E2E8F0;
`,
);

export default WrapperSearchInput;
