import { TypeChannelEnum } from 'modules/communities/common/channels.enum';

export type TChannelType = {
  title: string;
  description: string;
  benefit1: {
    title: string;
    description: string;
  };
  benefit2: {
    title: string;
    description: string;
  };
  exemple1: string;
  exemple2: string;
  exemple3: string;
  disabled: boolean;
  type: TypeChannelEnum;
};

export const TYPES_CHANNEL: TChannelType[] = [
  {
    type: TypeChannelEnum.PUBLIC,
    title: 'Público',
    description: 'Todos os membros podem vizualizar e acessar o canal sem custo.',
    benefit1: {
      title: 'Visibilidade Máxima',
      description: 'Qualquer usuário da Yethos pode acessar o canal.',
    },
    benefit2: {
      title: 'Engajamento',
      description:
        'Permitir que todos os usuários visualizem e acessem o conteúdo pode encorajar mais discussões e interações no canal.',
    },
    exemple1: 'Fãs de uma banda',
    exemple2: 'Praticantes de um esporte',
    exemple3: 'Discussão sobre livros',
    disabled: false,
  },
  {
    type: TypeChannelEnum.PAID,
    title: 'Pago',
    description: 'Membros que pagarem uma taxa terão acesso a esse canal.',
    benefit1: {
      title: 'Monetização',
      description: 'Você pode gerar receita a partir do canal.',
    },
    benefit2: {
      title: 'Comprometimento dos membros',
      description:
        'Se os membros pagam para acessar o canal, eles podem estar mais propensos a se engajar com o seu conteúdo.',
    },
    exemple1: 'Curso online',
    exemple2: 'Conteúdo premium',
    exemple3: 'Venda de produto digital',
    disabled: true,
  },
  {
    type: TypeChannelEnum.PRIVATE,
    title: 'Privado',
    description: 'Apenas membros com permissão do administrador poderão acessar esse canal.',
    benefit1: {
      title: 'Controle',
      description: 'Você tem o controle sobre quem pode acessar o canal.',
    },
    benefit2: {
      title: 'Exclusividade',
      description:
        'A visibilidade limitada pode dar aos membros um senso de pertencimento e exclusividade, o que pode promover um maior engajamento.',
    },
    exemple1: 'Canal de uma empresa',
    exemple2: 'Canal de suporte para um produto',
    exemple3: 'Grupos de estudo de uma universidade',
    disabled: true,
  },
];
