import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import React, { useCallback, useMemo } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Button } from 'common/components';

import { getImgSrc } from 'common/utils/imageResource';

type TCommentButtons = {
  handleClose: () => void;
  type: 'reply' | 'comment';
};

const CommentButtons = ({ handleClose, type }: TCommentButtons) => {
  const { register, reset, setValue, control } = useFormContext();
  const [load, setLoad] = React.useState(false);

  const replyImg = useWatch({ control, name: 'file' });
  const content = useWatch({ control, name: type });
  const img = useMemo(() => getImgSrc(replyImg), [replyImg]);

  const onClose = useCallback(() => {
    handleClose();
    reset();
  }, []);

  const enable = !!replyImg || !!content;

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const img = event.target.files?.[0] ?? null;
    if (img) {
      return setValue('file', img);
    } else return null;
  }, []);

  const handleDelete = useCallback(() => {
    setValue('file', '');
    setLoad(false);
  }, []);

  return (
    <>
      {img && (
        <Box display="flex" position="relative" width="fit-content">
          <Box
            position="absolute"
            display={load ? undefined : 'none'}
            sx={{
              right: '8px',
              bottom: '8px',
              zIndex: 999,
            }}
          >
            <IconButton
              size="small"
              color="secondary"
              onClick={handleDelete}
              sx={(theme) => ({
                backgroundColor: theme.palette.info.main,
                '&:hover': { backgroundColor: theme.palette.primary.light },
                borderRadius: '6px',
              })}
            >
              <DeleteForeverIcon sx={{ width: '16px', height: '16px', color: 'error.main' }} />
            </IconButton>
          </Box>
          <img
            onLoad={() => setLoad(true)}
            src={img}
            alt="reply-img"
            style={{ objectFit: 'cover', maxWidth: '300px', maxHeight: '400px' }}
          />
        </Box>
      )}
      <Box display="flex" width="100%" justifyContent="flex-end" gap={1}>
        <Button component="label" size="small" sx={{ minWidth: '32px' }}>
          <AddPhotoAlternateIcon />
          <input
            type="file"
            hidden
            accept="image/png, image/jpg, image/jpeg, image/gif"
            {...register('file')}
            onChange={(event) => handleChange(event)}
            multiple
          />
        </Button>
        <Button size="small" variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button size="small" disabled={enable ? false : true} type="submit" variant="contained">
          Responder
        </Button>
      </Box>
    </>
  );
};

export default CommentButtons;
