import { createSearchParams, useNavigate } from 'react-router-dom';
import { getRoutePathname } from 'routes/getRoutePathname';

const useGetDefaultsNavigate = () => {
  const navigate = useNavigate();

  const handleClickBookmarks = () => {
    navigate({
      pathname: getRoutePathname.Profile(),
      search: `?${createSearchParams({ bookmarks: 'true' })}`,
    });
  };

  const handleClickHome = () => {
    navigate(getRoutePathname.Communities());
  };

  const handleClickMyCommunities = () => {
    navigate(getRoutePathname.Profile());
  };

  const handleClickCreateCommunity = () => {
    navigate(getRoutePathname.CommunitiesAdd());
  };

  return { handleClickHome, handleClickBookmarks, handleClickCreateCommunity, handleClickMyCommunities };
};

export default useGetDefaultsNavigate;
