import { useFormContext, useWatch } from 'react-hook-form';
import { DescriptionInputCommunity, NameInputChannel } from 'modules/communities/common';
import { Box, Button } from 'common/components';

type TStepOneCreateChannel = {
  nextPage: () => void;
  previousPage: () => void;
};

const StepOneCreateChannel = ({ nextPage, previousPage }: TStepOneCreateChannel) => {
  const { control } = useFormContext();
  const name = useWatch({ control, name: 'name' });
  const description = useWatch({ control, name: 'description' });

  const able = !!name && !!description;

  const handleNextStep = () => {
    nextPage();
  };
  const handleBackStep = () => {
    previousPage();
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} alignItems="center">
      <Box display="flex" flexDirection="column" gap={2} width="100%" maxWidth="1080px">
        <NameInputChannel />
        <DescriptionInputCommunity maxCharacteres={90} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          justifyContent: 'center',
        }}
      >
        <Button variant="outlined" onClick={handleBackStep}>
          Voltar
        </Button>
        <Button disabled={!able} variant="contained" onClick={handleNextStep}>
          Próximo
        </Button>
      </Box>
    </Box>
  );
};

export default StepOneCreateChannel;
