import { Box } from '@mui/material';

import LogoCircleAuthentication from 'assets/logo/LogoCircleAuthentication';

const Header = () => {
  return (
    <Box margin={'8px'} height={75} alignItems="center" justifyContent="center" display="flex">
      <LogoCircleAuthentication />
    </Box>
  );
};

export default Header;
