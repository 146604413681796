import InfoIcon from '@mui/icons-material/Info';
import { FormGroup, FormLabel, Tooltip, FormHelperText } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Text } from 'common/components';
import { HelperMessagesEnum } from 'common/utils/validation.schemas';
import { TTag } from 'modules/communities/common/types/community.types';

import TagInputAutocomplete from './TagInputAutocomplete';
import { useCreateTagByCategoryAPI, useGetTagsByCategoryAPI } from '../community.api';

const MAX_TAGS = 5;

const TagGroupForm = () => {
  const { control } = useFormContext();
  const tagDefault: TTag[] = useWatch({ control, name: 'tags' });
  const categoryDefault: string = useWatch({ control, name: 'category' });

  const tagLength = tagDefault?.length;
  const hasTag = !!tagLength;
  const limitTags = hasTag && tagLength > 1 && tagLength >= MAX_TAGS;
  const limitTagsMessage = limitTags ? HelperMessagesEnum.MAX_TAGS : '';

  const tagParams = !!categoryDefault
    ? { community_category: categoryDefault, page: 0, size: 15 }
    : { page: 0, size: 15 };
  const { data: returnTagsOption, isLoading: isLoadingTags } = useGetTagsByCategoryAPI(tagParams, {
    enabled: !!categoryDefault,
  });
  const { mutate: createTag, isLoading: isLoadingCreateTag } = useCreateTagByCategoryAPI();
  const tagsOption = returnTagsOption?.items ?? [];

  const handleCreateTag = (tag: string, fun: (res: TTag) => void) => {
    createTag(
      { label: tag, community_category: categoryDefault },
      {
        onSuccess: (res: TTag) => {
          fun(res);
        },
      },
    );
  };

  return (
    <FormGroup>
      <Box display="flex" gap={1}>
        <FormLabel
          sx={(theme) => ({
            color: theme.palette.secondary.main,
            fontWeight: 500,
            display: 'flex',
            gap: 1,
          })}
          htmlFor="tags"
        >
          <Text sx={{ fontWeight: 500 }}>Tags</Text>
        </FormLabel>
        <Box display="flex" gap={2}>
          <Tooltip
            arrow
            title="Adicione tags relevantes para descrever ainda mais o assunto e interesses da sua comunidade. Essas tags podem ser palavras-chave ou frases curtas que representem os tópicos discutidos na comunidade. Elas facilitam a busca e a descoberta da sua comunidade por outros usuários."
          >
            <InfoIcon sx={{ height: '16px', width: '16px' }} />
          </Tooltip>
          <Text variant="caption">
            {tagLength ?? 0}/{MAX_TAGS}
          </Text>
        </Box>
      </Box>
      <TagInputAutocomplete
        tagsDefault={tagDefault}
        tagsOption={tagsOption}
        category={categoryDefault ?? ''}
        maxTags={MAX_TAGS}
        limitTags={limitTags}
        loadingNewTag={isLoadingCreateTag}
        loadingTags={isLoadingTags}
        handleCreateTag={handleCreateTag}
      />
      {limitTags && <FormHelperText id="component-helper-text-for-tag">{limitTagsMessage}</FormHelperText>}
    </FormGroup>
  );
};

export default TagGroupForm;
