import { get } from 'lodash';
import { FormGroup, FormHelperText, InputBase } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box } from 'common/components';
import { HelperMessagesEnum, InputErrorMessageEnum, InputValueEnum } from 'common/utils/validation.schemas';

const NameInputTopic = () => {
  const maxCharacteres = 60;
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const namValue = useWatch({ control, name: InputValueEnum.NAME });
  const nameLength = namValue?.length;
  const limitCharacteres = nameLength > 1 && nameLength === maxCharacteres;
  const limitCharacteresMessage = limitCharacteres ? HelperMessagesEnum.MAX_CHARACTERS : '';
  return (
    <FormGroup>
      <Box display="flex" gap={1}></Box>
      <InputBase
        error={!!`${get(errors, InputErrorMessageEnum.NAME, '')}`}
        sx={{ marginTop: 1 }}
        inputProps={{
          maxLength: maxCharacteres,
          sx: {
            backgroundColor: 'white',
            border: 'none',
            fontSize: '2.5rem',
            fontWeight: 700,
            '@media (max-width: 900px)': {
              fontSize: '1rem',
            },
          },
        }}
        placeholder="Título"
        {...register(InputValueEnum.NAME)}
      />
      <FormHelperText sx={{ color: !!get(errors, InputErrorMessageEnum.NAME, '') ? 'error.main' : undefined }}>
        {`${get(errors, InputErrorMessageEnum.NAME, '')}` || limitCharacteresMessage}
      </FormHelperText>
    </FormGroup>
  );
};

export default NameInputTopic;
