import * as React from 'react';
import Logout from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import { Box, Menu, MenuItem, ListItemIcon, Divider, IconButton, CircularProgress } from '@mui/material';

import { useNavigate } from 'app-router';
import useAuthContext from 'providers/AuthProvider';
import { getRoutePathname } from 'routes/getRoutePathname';

interface AccountMenuMobileProps {
  avatar: React.ReactNode;
  name: React.ReactNode;
  size: number;
}

export default function AccountMenuMobile({ avatar, name, size }: AccountMenuMobileProps) {
  const { logout, logoutLoading } = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleProfileRoute = () => {
    navigate(getRoutePathname.Profile());
    handleClose();
  };
  const handleCreateRoute = () => {
    navigate(getRoutePathname.CommunitiesAdd());
    handleClose();
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout(handleClose);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClickMenu}
          size="small"
          sx={{ padding: 0 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {avatar}
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: (theme) => ({
            overflow: 'visible',
            boxShadow: 'none',
            border: `1px solid ${theme.palette.primary.light}`,
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: size,
              height: size,
              ml: -0.5,
              mr: 1,
            },
          }),
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfileRoute}>
          <Box display="flex" alignItems="center">
            {avatar} {name}
          </Box>
        </MenuItem>
        <MenuItem onClick={handleCreateRoute}>
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          Criar comunidade
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleLogout}>
          {!logoutLoading ? (
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
          ) : (
            <ListItemIcon>
              <CircularProgress size="32px" color="secondary" />
            </ListItemIcon>
          )}
          Sair
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
