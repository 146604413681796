import { faker } from '@faker-js/faker';

import { categoriesObj, typesArr } from 'common/utils/filters.community.constants';
import {
  ChannelProps,
  CommunityPropsMOCK,
  PostProps,
  createRandomComment,
  createRandomHighlight,
  createRandomUser,
  network_types,
} from '../mockData';
import { languageCommunities } from './constants';
import { JSONContent } from '@tiptap/react';

export function createRandomPost(post: { title: string; content: JSONContent }): PostProps {
  return {
    user: createRandomUser(),
    title: post.title,
    description: post.content,
    upVotes: Math.floor(Math.random() * 40),
    downVotes: Math.floor(Math.random() * 40),
    comments: Array.from({ length: 5 }, () => createRandomComment()),
    share: Math.floor(Math.random() * 10),
    id: faker.datatype.uuid(),
    date: faker.date.recent(),
    files: [],
  };
}
type channel = {
  name: string;
  description: string;
  posts: {
    title: string;
    content: JSONContent;
  }[];
};

export function createRandomChannel(channel: channel): ChannelProps {
  return {
    name: channel.name,
    description: channel.description,
    owner: createRandomUser(),
    posts: channel.posts.map((post) => createRandomPost(post)),
    date: faker.date.past(),
    favoritePosts: ['postId2'],
    id: faker.datatype.uuid(),
    avatar: faker.image.business(600, 600, true),
    settings: {
      allow_minor_members: true,
      allow_unverifies_users: false,
      is_channel_format: false,
      topics: {
        displayLastPost: true,
      },
      members: {
        canCreateTopics: true,
        canCreatePolls: true,
      },
      billing: {
        join_fee: 0,
        monthly_Fee: 0,
      },
    },
  };
}

export function createRandomCommunityLanguage(): CommunityPropsMOCK {
  const community = faker.helpers.arrayElement(languageCommunities);
  const index = languageCommunities.indexOf(community);
  languageCommunities.splice(index, 1);
  return {
    founder: createRandomUser(),
    usersMembers: Array.from({ length: Math.floor(Math.random() * 1500) }, () => createRandomUser()),
    category: categoriesObj.entertainment,
    posts: [createRandomPost(community.channels[0].posts[0])],
    type: faker.helpers.arrayElement(typesArr),
    channels: community.channels.map((channel) => createRandomChannel(channel)),
    date: faker.date.past(),
    favoritePosts: ['postId2'],
    name: community.name,
    description: community.description,
    highlight: createRandomHighlight(),
    _id: faker.datatype.uuid(),
    avatar_url: faker.image.business(600, 600, true),
    banner_url: faker.image.business(1800, 600, true),
    admins: [createRandomUser()],
    moderators: [],
    most_active_members: [],
    related_communities: [],
    resources: [],
    social_network: [{ type: network_types.facebook, url: 'empty' }],
    settings: {
      allow_minor_members: true,
      allow_unverifies_users: false,
      is_channel_format: false,
      topics: {
        displayLastPost: true,
      },
      members: {
        canCreateTopics: true,
        canCreatePolls: true,
      },
      billing: {
        join_fee: 0,
        monthly_Fee: 0,
      },
    },
    invite: true,
    firstTime: false,
  };
}
