import React from 'react';
import { QueryClient, QueryClientConfig, QueryClientProvider as ReactQueryProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// const DEFAULT_CONFIG: QueryClientConfig = {
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//       retry: (failureCount: number, error: any) => (error.response?.status !== 500 ? false : failureCount <= 2),
//     },
//   },
// };

// const getQueryClient = (customConfig?: QueryClientConfig) => new QueryClient(customConfig ?? DEFAULT_CONFIG);

export interface APIClientProviderProps {
  children: React.ReactNode;
  queryClientConfig?: QueryClientConfig;
  queryClient: QueryClient;
}

const APIClientProvider = ({ children, queryClient }: APIClientProviderProps) => {
  return (
    <ReactQueryProvider client={queryClient}>
      <ReactQueryDevtools />
      {children}
    </ReactQueryProvider>
  );
};

export default APIClientProvider;
