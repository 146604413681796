import { Box, Card } from 'common/components';

const SkeletonCommunityCard = () => {
  return (
    <Card
      sx={() => ({
        alignItems: 'flex-start',
        overflow: 'hidden',
        gap: 1,
        padding: 0,
        minHeight: '294px',
        width: '280px',
        maxWidth: '280px',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          position: 'relative',
          width: '100%',
          gap: 1,
          flex: 1,
        }}
      >
        <Box sx={{ width: '100%', height: 'calc(280px / 6)', position: 'absolute', bgcolor: 'primary.light' }} />

        <Box
          sx={{
            padding: '16px 16px 0px',
            zIndex: 1,
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'auto',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              borderRadius: theme.spacing(1),
              minWidth: '160px',
              maxWidth: '160px',
              minHeight: '90px',
              maxHeight: '90px',
              border: `1px solid white`,
              overflow: 'hidden',
              backgroundColor: 'white',
              zIndex: 10,
              bgcolor: 'primary.light',
            })}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            gap: 0.5,
            padding: '0px 16px',
          }}
        >
          <Box width="120px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />
          <Box width="90px" height="20px" sx={{ borderRadius: 0, bgcolor: 'white' }} />
          <Box width="140px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />
          <Box width="140px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />
          <Box width="140px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0px 16px 16px',
            flex: 1,
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <Box sx={{ width: '24px', height: '24px', borderRadius: '50%', bgcolor: 'primary.light' }} />
            <Box width="70px" height="20px" sx={{ bgcolor: 'primary.light', borderRadius: 0 }} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default SkeletonCommunityCard;
