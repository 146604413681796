import { get } from 'lodash';
import { FormGroup, FormLabel, TextField } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Text } from 'common/components';
import { HelperMessagesEnum, InputErrorMessageEnum, InputValueEnum } from 'common/utils/validation.schemas';

type DescriptionInputCommunityProps = {
  maxCharacteres?: number;
  changeBorder?: boolean;
  label?: string;
};

const DescriptionInputCommunity = ({
  maxCharacteres,
  changeBorder,
  label = 'Descrição',
}: DescriptionInputCommunityProps) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const descriptionValue = useWatch({ control, name: InputValueEnum.DESCRIPTION });
  const descriptionLength = descriptionValue?.length;
  const limitCharacteres = descriptionLength > 1 && descriptionLength === maxCharacteres;
  const limitCharacteresMessage = limitCharacteres ? HelperMessagesEnum.MAX_CHARACTERS : '';
  return (
    <FormGroup>
      <Box display="flex" gap={2}>
        <FormLabel
          sx={(theme) => ({
            color: theme.palette.secondary.main,
            display: 'flex',
            '&.Mui-required': { color: 'error.main' },
          })}
          required
          htmlFor={InputValueEnum.DESCRIPTION}
        >
          <Text sx={{ fontWeight: 500 }}>{label}</Text>
        </FormLabel>
        {maxCharacteres && (
          <Text variant="caption">
            {descriptionLength ?? 0}/{maxCharacteres}
          </Text>
        )}
      </Box>
      <TextField
        id={InputValueEnum.DESCRIPTION}
        error={!!`${get(errors, InputErrorMessageEnum.DESCRIPTION, '')}`}
        helperText={`${get(errors, InputErrorMessageEnum.DESCRIPTION, '')}` || limitCharacteresMessage}
        sx={{
          marginTop: 1,
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: changeBorder ? '8px 8px 0px 0px' : undefined,
            border: changeBorder ? '1px solid #E2E8F0' : undefined,
          },
        }}
        inputProps={{ maxLength: maxCharacteres, style: { backgroundColor: 'white' } }}
        size="small"
        {...register(InputValueEnum.DESCRIPTION)}
      />
    </FormGroup>
  );
};

export default DescriptionInputCommunity;
