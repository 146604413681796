import { AxiosError } from 'axios';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { Button, LoadingButton } from 'common/components';
import useAuthContext from 'providers/AuthProvider';
import useNotification from 'providers/NotificationProvider';

import { PasswordInput } from '../../../common/components';
import { useUpdateUserPasswordAPI } from '../../../common/apis/users.api';
import { ChangePasswordSchema, TChangePasswordForm } from '../profile.schemas';

interface ChangePasswordDialogProps {
  closeDialog: () => void;
  open: boolean;
}

const ChangePasswordDialog = ({ closeDialog, open }: ChangePasswordDialogProps) => {
  const { user } = useAuthContext();
  const { toast } = useNotification();
  const { mutate: updateUserPasswordMutation, isLoading: updatePasswordLoading } = useUpdateUserPasswordAPI();

  const formMethods = useForm<TChangePasswordForm>({ resolver: zodResolver(ChangePasswordSchema) });
  const { handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<TChangePasswordForm> = (values) => {
    updateUserPasswordMutation(
      {
        userId: user?._id ?? '',
        data: {
          current_password: values.current_password,
          new_password: values.password,
        },
      },
      {
        onSuccess: () => {
          closeDialog();
          formMethods.reset();
          toast({ title: 'Senha atualizada com sucesso!', type: 'success' });
        },
        onError: (error: any) => {
          if (error instanceof AxiosError) {
            const { statusCode } = error.response?.data;
            if (statusCode === 401) {
              formMethods.setError('current_password', {
                type: 'server',
                message: 'Senha atual não é válida',
              });
            } else {
              toast({ title: 'Algo deu errado. Tente novamente', type: 'error' });
            }
          }
        },
      },
    );
  };

  const handleClose = () => {
    closeDialog();
    formMethods.reset();
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle textAlign="center">Mudar senha</DialogTitle>
          <DialogContent>
            <DialogContentText textAlign="center">A nova senha não pode ser igual a senha atual.</DialogContentText>
            <Box
              height="290px"
              mt={1}
              gap={0}
              p={2}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <PasswordInput registerName="current_password" label="Senha atual" helperText="Digite sua senha atual." />
              <PasswordInput label="Nova senha" helperText="Digite sua nova senha." />
              <PasswordInput
                registerName="confirm_password"
                label="Confirmar nova senha"
                helperText="Digite sua nova senha novamente."
              />
              <DialogActions sx={{ padding: 0 }}>
                <Button variant="outlined" disabled={updatePasswordLoading} onClick={handleClose}>
                  Cancelar
                </Button>
                <LoadingButton
                  loading={updatePasswordLoading}
                  variant="contained"
                  disabled={updatePasswordLoading}
                  type="submit"
                >
                  Confirmar
                </LoadingButton>
              </DialogActions>
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </FormProvider>
  );
};

export default ChangePasswordDialog;
