import React from 'react';
import { QueryClient } from 'react-query';

import { RouteObject } from 'app-router';
import { ERoutesCommunities } from 'routes/ERoutesCommunities';
import ChannelShell from 'modules/communities/common/components/ChannelShell';

import CreateChannel from './CreateChannel.screen';
import { loaderChannelDetail } from './ChannelDetails.screen';

const ChannelDetails = React.lazy(() => import('./ChannelDetails.screen'));

const ChannelRoutes: (queryClient: QueryClient) => RouteObject[] = (queryClient) => [
  {
    path: ERoutesCommunities.Communities_Details_ChannelAdd,
    element: <CreateChannel />,
  },
  {
    path: ERoutesCommunities.Communities_Details_Channel,
    element: <ChannelShell />,
    children: [{ index: true, element: <ChannelDetails />, loader: loaderChannelDetail(queryClient) }],
  },
];
export default ChannelRoutes;
