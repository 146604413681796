import { QueryClient } from 'react-query';

import { RouteObject } from 'app-router';
import { ERoutesCommunities } from 'routes/ERoutesCommunities';
import CreateTopic from './CreateTopic.screen';
import TopicShell from 'modules/communities/common/components/TopicShell';

import TopicDetails, { loaderTopicDetail } from './TopicDetails.screen';
import TopicEdit, { loaderTopicEdit } from './TopicEdit.screen';

const PostRoutes: (queryClient: QueryClient) => RouteObject[] = (queryClient) => [
  {
    path: ERoutesCommunities.Communities_Details_PostsAdd,
    element: <TopicShell />,
    children: [{ index: true, element: <CreateTopic /> }],
  },
  {
    path: ERoutesCommunities.Communities_Details_PostsDetails,
    element: <TopicShell />,
    children: [
      { index: true, element: <TopicDetails />, loader: loaderTopicDetail(queryClient) },
      {
        path: ERoutesCommunities.Communities_Details_PostsEdit,
        element: <TopicEdit />,
        loader: loaderTopicEdit(queryClient),
      },
    ],
  },
];
export default PostRoutes;
