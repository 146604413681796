import { styled } from '@mui/material/styles';

import Button from './Button';

const TagButton = styled(Button)(({ theme }) => ({
  minWidth: 'max-content',
  backgroundColor: theme.palette.info.dark,
  color: 'white',
  '&:hover': {
    backgroundColor: theme.palette.info.dark,
  },
  padding: '2px 8px',
  fontWeight: 400,
  height: '32px',
  lineHeight: '20px',
}));

export default TagButton;
