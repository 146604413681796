import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Divider, Collapse } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Button, Card, Text } from 'common/components';
import { TChannelType } from '../constants';
import { TCreateChannelForm } from '../channel.schemas';

type TTypeChannelCard = {
  channelType: TChannelType;
  icon?: React.ReactNode;
};

const TypeChannelCard = ({ channelType, icon }: TTypeChannelCard) => {
  const [expanded, setExpanded] = React.useState(false);
  const { control, setValue } = useFormContext<TCreateChannelForm>();
  const selected = useWatch({ control, name: 'type' });

  const channelIsSelected = selected === channelType.type;

  const handleSetExpanded = () => {
    setExpanded((pre) => !pre);
  };

  const handleSelect = () => {
    setValue('type', channelType.type);
  };

  return (
    <Card
      height={expanded ? undefined : 'max-content'}
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth="340px"
      minWidth="280px"
      sx={(theme) => ({
        background: channelIsSelected
          ? 'linear-gradient(180deg, #FFF 65.63%, #FFB573 100%)'
          : 'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.75) 100%)',
        boxShadow: channelIsSelected ? theme.yethosCustoms.shadows.cardHover : undefined,
        gap: 1,
      })}
    >
      <Box display="flex" textAlign="center" alignItems="center" flexDirection="column" gap={1}>
        <Box display="flex" gap={1}>
          <Text variant="h4">{channelType.title}</Text>
          {icon}
        </Box>
        <Text>{channelType.description}</Text>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box display="flex" flexDirection="column" gap={1} alignItems="center">
          <Divider sx={{ width: '100%' }} />
          <Text variant="h5" sx={{ color: 'primary.main' }}>
            Benefícios deste formato
          </Text>
          <Text>
            <Text variant="h5" component="span">
              {channelType.benefit1.title}:
            </Text>{' '}
            {channelType.benefit1.description}
          </Text>
          <Text>
            <Text variant="h5" component="span">
              {channelType.benefit2.title}:
            </Text>{' '}
            {channelType.benefit2.description}
          </Text>
          <Divider sx={{ width: '100%' }} />
          <Text variant="h5" sx={{ color: 'primary.main' }}>
            Exemplo
          </Text>
          <Box display="flex" flexDirection="column" width="100%" gap={1}>
            <Box display="flex" gap={1}>
              <CheckIcon sx={{ width: '20px', height: '20px', color: 'primary.main' }} />
              <Text>{channelType.exemple1}</Text>
            </Box>
            <Box display="flex" gap={1}>
              <CheckIcon sx={{ width: '20px', height: '20px', color: 'primary.main' }} />
              <Text>{channelType.exemple2}</Text>
            </Box>
            <Box display="flex" gap={1}>
              <CheckIcon sx={{ width: '20px', height: '20px', color: 'primary.main' }} />
              <Text>{channelType.exemple3}</Text>
            </Box>
          </Box>
        </Box>
      </Collapse>
      <Box width="100%" justifyContent="flex-end" display="flex" gap={1} flex={1} flexDirection="column">
        <Button
          onClick={handleSetExpanded}
          variant="text"
          size="small"
          disableRipple
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}
          endIcon={<KeyboardArrowUpIcon sx={{ transform: !expanded ? 'rotate(180deg)' : undefined }} />}
        >
          {expanded ? 'Mostrar menos' : 'Mostrar mais'}
        </Button>
        <Button
          onClick={handleSelect}
          disabled={channelType.disabled || channelIsSelected}
          variant="contained"
          fullWidth
        >
          {channelType.disabled ? 'Em breve' : channelIsSelected ? 'Selecionado' : 'Escolher'}
        </Button>
      </Box>
    </Card>
  );
};

export default TypeChannelCard;
