import { zodResolver } from '@hookform/resolvers/zod';
import { get } from 'lodash';
import { Box, Divider, Stack } from '@mui/material';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';

import { useNavigate } from 'app-router';
import { getRoutePathname } from 'routes/getRoutePathname';

import { Text, Button, EmailInput, LoadingButton } from 'common/components';

import useNotification from 'providers/NotificationProvider';
import { TNewUser } from 'modules/accounts/common/types';
import { GenderEnum, UserStatusEnum } from 'modules/accounts/common/enums';

import { useCreateUserAPI } from '../../common/apis/users.api';
import { NameInput, PasswordInput } from '../../common/components';

import { GenderSelect, BirthDateSelect } from './components';
import { SignUpSchema, TSignUpForm } from './authentication.schemas';

const DEFAULT_USER_VALUES: TSignUpForm = {
  first_name: '',
  last_name: '',
  email: '',
  day_of_birth: '01',
  month_of_birth: '01',
  year_of_birth: '2023',
  password: '',
  gender: GenderEnum.FEMALE,
};

const normalizeUserData = (values: TSignUpForm) => {
  const user: TNewUser = {
    first_name: values.first_name,
    last_name: values.last_name,
    gender: values.gender,
    email: values.email,
    date_of_birth: {
      year_of_birth: Number(values.year_of_birth),
      month_of_birth: Number(values.month_of_birth),
      day_of_birth: Number(values.day_of_birth),
    },
    password: values.password,
  };
  return user;
};

const SignUp = () => {
  const navigate = useNavigate();
  const { toast } = useNotification();
  const { mutate: createUser, isLoading: isCreatingUser } = useCreateUserAPI();
  const formMethods = useForm<TSignUpForm>({
    defaultValues: DEFAULT_USER_VALUES,
    resolver: zodResolver(SignUpSchema),
  });

  const { handleSubmit } = formMethods;
  const handleLoginRoute = () => navigate(getRoutePathname.Login());
  const defaultValues = formMethods.getValues();

  const onSubmit: SubmitHandler<TSignUpForm> = (values) => {
    const user = normalizeUserData(values);
    createUser(user, {
      onSuccess: (newUser) => {
        if (newUser.status === UserStatusEnum.WAITING_ACTIVATION) {
          const activateRoute = getRoutePathname.AuthActivate(newUser._id);
          navigate(activateRoute);
        }
      },
      onError: (error) => {
        const errStatus: number = get(error, 'response.data.status', 0);
        if (errStatus === 422) {
          toast({ title: 'E-mail já cadastrado.', type: 'error' });
        }
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Text variant="h1" fontSize={{ xs: '1.25rem', sm: '2.25rem' }}>
              Crie sua conta Yethos
            </Text>
          </Box>
          <Divider />
          <NameInput firstNameProps={{ autoFocus: true }} />
          <EmailInput />
          <PasswordInput trigger={['onBlur', 'onChange']} />
          <BirthDateSelect />
          <GenderSelect defaultValue={defaultValues.gender} />
          <Stack spacing={1}>
            <LoadingButton loading={isCreatingUser} size="small" variant="contained" type="submit">
              Criar conta!
            </LoadingButton>
            <Button disabled={isCreatingUser} onClick={handleLoginRoute} size="small" fullWidth variant="outlined">
              Já possui conta? Acesse!
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default SignUp;
