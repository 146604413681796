import React from 'react';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';
import { useForm } from 'react-hook-form';
import { QueryClient } from 'react-query';

import { useSearchParams } from 'app-router';
import { Box, WrapperSearchInput, Text } from 'common/components';
import TagButton from 'common/components/TagButton';
import { categoriesArr } from 'common/utils/filters.community.constants';
import SelectFilter from 'modules/communities/common/components/SelectFilter';
import Carousel from 'modules/communities/common/components/Carousel';

import { CommunitiesFiltered } from './components';
import { fetchCommunitiesByMany, useGetTagsByCategoryAPI } from './community.api';
import { COMMUNITIES_KEY } from 'common/utils/queryKeys';

export type filteredProps = {
  category: {
    label: string;
    value: string;
  };
  type: string[];
  size: string;
};

export const loaderInfiniteCommunities = (queryClient: QueryClient) => async () => {
  const query = COMMUNITIES_KEY.list({ infinite: true });
  await queryClient.prefetchInfiniteQuery({
    queryKey: query,
    queryFn: () => fetchCommunitiesByMany({ userParams: { page: 0, size: 15 } }),
  });
  return null;
};

const getSearchString = (tagSelected: string | null, filterValue: string, queryValue: string) => {
  const tag = !!tagSelected ? ` ${tagSelected}` : '';
  const filter = !!filterValue ? ` ${filterValue}` : '';
  const query = !!queryValue ? ` ${queryValue}` : '';
  return query + tag + '' + filter;
};

const Communities = () => {
  const [searchParams] = useSearchParams();
  const searchParamsValue = searchParams.get('q') ?? '';
  const { register, setValue } = useForm({
    defaultValues: {
      search: searchParamsValue,
    },
  });
  const [tagSelected, setTagSelected] = React.useState<string | null>('');
  const [query, setQuery] = React.useState(searchParamsValue ?? '');
  const [filtered, setFiltered] = React.useState<filteredProps>({
    category: { label: 'Todos', value: '' },
    type: [],
    size: 'all',
  });

  const searchString = getSearchString(tagSelected, filtered.category.value, query);
  const searchParam = searchString || undefined;

  const tagParams = !!filtered.category?.value
    ? { community_category: filtered.category.value, page: 0, size: 15 }
    : { page: 0, size: 25 };
  const { data: returnTags, isLoading: isLoadginTags } = useGetTagsByCategoryAPI(tagParams);

  const tags = returnTags?.items ?? [];

  const handleSelectTag = (tagLabel: string) => {
    setTagSelected((prev) => (prev === tagLabel ? '' : tagLabel));
  };

  const handleOnChangeQuery = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuery(event.target.value.toLowerCase());
  };

  const debounceFn = debounce(handleOnChangeQuery, 400);

  const handleChangeFilter = (value: { label: string; value: string }) => {
    if (value.value === filtered.category.value) return;
    else {
      setFiltered((prev) => ({ ...prev, category: value }));
      setTagSelected('');
    }
  };

  React.useEffect(() => {
    setValue('search', searchParamsValue);
    setQuery(searchParamsValue);
  }, [searchParamsValue]);

  return (
    <Box
      paddingBottom={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        marginTop: { xs: 2, sm: 1 },
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Text variant="h1">Faça parte de algo maior.</Text>
      <Box>
        <Text>Descubra comunidades que compartilham dos seus interesses.</Text>
      </Box>
      <Box width={{ xs: '90%', sm: '70%', md: '35vw' }}>
        <WrapperSearchInput>
          <Box
            sx={{
              padding: '0px 5px',
              height: '100%',
              position: 'absolute',
              pointerEvents: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SearchIcon sx={(theme) => ({ color: theme.palette.info.dark })} />
          </Box>
          <InputBase
            size="small"
            placeholder={'Pesquisar comunidades'}
            {...register('search')}
            onChange={(event) => {
              register('search').onChange(event);
              debounceFn(event);
            }}
            sx={{
              color: 'inherit',
              width: '100%',
              '& .MuiInputBase-input': {
                padding: 1,
                paddingLeft: 5,
                width: '100%',
              },
            }}
          />
        </WrapperSearchInput>
      </Box>
      <Box
        // paddingRight="24px"
        width="100%"
        display="grid"
        gridTemplateRows={{ xs: '1fr 1fr', sm: '1fr' }}
        gridTemplateColumns={{ xs: '1fr', sm: 'auto 1fr' }}
        gap={3}
        alignItems="center"
      >
        <Box display="flex" justifyContent="center">
          <SelectFilter
            minWidth="0px"
            filters={categoriesArr}
            filtered={filtered.category}
            handleChangeFilter={handleChangeFilter}
            noFilterLabel="Categoria"
          />
        </Box>
        {tags && !isLoadginTags && (
          <Carousel resetScroll={filtered.category.value}>
            <>
              <TagButton
                sx={{
                  backgroundColor: tagSelected === '' ? 'secondary.main' : undefined,
                  '&:hover': { backgroundColor: tagSelected === '' ? 'secondary.main' : undefined },
                }}
                onClick={() => handleSelectTag('')}
                key={`tag_all_no_filter`}
              >
                Tudo
              </TagButton>
              {tags.map((tag) => (
                <TagButton
                  sx={{
                    backgroundColor: tagSelected === tag.label ? 'secondary.main' : undefined,
                    '&:hover': { backgroundColor: tagSelected === tag.label ? 'secondary.main' : undefined },
                  }}
                  onClick={() => handleSelectTag(tag.label)}
                  key={`tag_${tag._id}`}
                >
                  {tag.label}
                </TagButton>
              ))}
            </>
          </Carousel>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexWrap: 'wrap',
          marginTop: 1,
          gap: 2,
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <CommunitiesFiltered hasSearch={!!searchString} searchParam={searchParam} query={query} />
      </Box>
    </Box>
  );
};

export default Communities;
