import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { useNavigate, useParams } from 'app-router';
import { Button, LoadingButton } from 'common/components';
import { getRoutePathname } from 'routes/getRoutePathname';

import { useDeleteTopicAPI } from '../post.api';

type DeleteTopicProps = {
  open: boolean;
  handleClose: () => void;
  id: string;
};

const DeleteTopic = ({ open, handleClose, id }: DeleteTopicProps) => {
  const { id: communityId = '', channelId = '', postId = '' } = useParams();
  const navigate = useNavigate();
  const { mutate: deleteTopic, isLoading: isDeleting } = useDeleteTopicAPI();

  const handleDelete = () => {
    deleteTopic(id, {
      onSuccess: () => {
        handleClose();
        if (postId) {
          navigate(getRoutePathname.CommunitiesDetailsChannel(communityId, channelId));
        }
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deletar Tópico</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Após deletar não será possível recuperar essas informações. Tem certeza que deseja fazer isso?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isDeleting} variant="outlined" size="small" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton loading={isDeleting} size="small" variant="contained" color="error" onClick={handleDelete}>
          Deletar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTopic;
