import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Divider, Stack } from '@mui/material';
import { QueryClient } from 'react-query';

import { useNavigate } from 'app-router';
import { Button, Text, EmailInput, LoadingButton } from 'common/components';
import { USERS_KEY } from 'common/utils/queryKeys';
import { fetchMe } from 'modules/accounts/common/apis/users.api';
import useAuthContext from 'providers/AuthProvider';
import { getRoutePathname } from 'routes/getRoutePathname';

import { PasswordInput } from '../../common/components';
import { LoginSchema, TLoginForm } from './authentication.schemas';

export const loaderMe = (queryClient: QueryClient) => async () => {
  const query = [USERS_KEY._base, 'me'];
  queryClient.prefetchQuery({ queryKey: query, queryFn: () => fetchMe() });
  return null;
};

const forgotPasswordRoute = getRoutePathname.AuthForgotPassword();
const signupRoute = getRoutePathname.Signup();

const Login = () => {
  const { login, loginLoading } = useAuthContext();
  const navigate = useNavigate();

  const formMethods = useForm<TLoginForm>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { handleSubmit } = formMethods;

  const handleForgotPasswordRoute = () => navigate(forgotPasswordRoute);
  const handleSignUpRoute = () => navigate(signupRoute);

  const onSubmit: SubmitHandler<TLoginForm> = (values) => {
    login({ email: values.email, password: values.password });
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} textAlign="center">
          <Text variant="h1" fontSize={{ xs: '1.25rem', sm: '2.25rem' }}>
            Entre na sua conta Yethos
          </Text>
          <Divider />
          <EmailInput />
          <PasswordInput />
          <Stack spacing={1}>
            <Button disabled={loginLoading} onClick={handleForgotPasswordRoute} size="small" fullWidth variant="text">
              Esqueci minha senha
            </Button>
            <LoadingButton loading={loginLoading} size="small" variant="contained" type="submit">
              Fazer login
            </LoadingButton>
            <Button onClick={handleSignUpRoute} disabled={loginLoading} size="small" fullWidth variant="outlined">
              Crie sua conta
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default Login;
