import { QueryClient } from 'react-query';

import { LoaderFunctionArgs, Navigate, useParams } from 'app-router';
import SkeletonCommunityCard from 'assets/skeletons/SkeletonCommunityCard';
import { Avatar, Box, TabList, Text } from 'common/components';
import { PROFILES_KEY } from 'common/utils/queryKeys';
import useAuthContext from 'providers/AuthProvider';
import { fetchProfileById, useGetProfileByIdAPI } from 'modules/accounts/common/apis/profiles.api';
import { getRoutePathname } from 'routes/getRoutePathname';

import { TabCommunities } from './components';

export const loaderProfile =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const profileId = params.profileId ?? '';
    const query = PROFILES_KEY.details(profileId);
    queryClient.prefetchQuery({ queryKey: query, queryFn: () => fetchProfileById(profileId) });
    return null;
  };

const ProfileDetails = () => {
  const { user } = useAuthContext();
  const { profileId = '' } = useParams();
  const { data: profile, isLoading } = useGetProfileByIdAPI(profileId);
  const communities = profile?.communities ?? [];

  const tabs = [
    {
      id: 'communities',
      title: 'Comunidades',
      component: <TabCommunities communities={communities} />,
    },
  ];

  if (isLoading) {
    return (
      <Box display="flex" width="100%" marginTop={{ xs: 2, sm: 0 }} paddingBottom={2} flexDirection="column" gap={2}>
        <Box
          height={{ xs: '70px', sm: '140px' }}
          bgcolor="primary.light"
          sx={(theme) => ({
            borderRadius: theme.spacing(1),
            position: 'relative',
            boxShadow: theme.yethosCustoms.shadows.card,
          })}
        />
        <Box position="relative" display="flex" alignItems="center" gap={3}>
          <Box bgcolor="primary.light" sx={{ width: '120px', height: '120px', borderRadius: '50%' }} />
          <Box display="flex" width="100%" flexDirection="column" gap={2}>
            <Box width="90px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />
            <Box width="160px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />
          </Box>
        </Box>
        <Box>
          <Text variant="h4" sx={{ marginBottom: '10px', color: 'info.dark' }}>
            Admin
          </Text>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '24px',
              justifyContent: 'flex-start',
            }}
          >
            {Array.from({ length: 3 }, (_i, idx) => (
              <SkeletonCommunityCard key={`skeleton_${idx}`} />
            ))}
          </Box>
          <Text variant="h4" sx={{ marginBottom: '10px', color: 'info.dark' }}>
            Membro
          </Text>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '24px',
              justifyContent: 'flex-start',
            }}
          >
            {Array.from({ length: 3 }, (_i, idx) => (
              <SkeletonCommunityCard key={`skeleton_${idx}`} />
            ))}
          </Box>
        </Box>
      </Box>
    );
  }

  if (user?.main_profile._id === profileId) {
    return <Navigate to={getRoutePathname.Profile()} />;
  }

  return (
    <Box display="flex" width="100%" marginTop={{ xs: 2, sm: 0 }} paddingBottom={2} flexDirection="column" gap={2}>
      <Box
        height={{ xs: '70px', sm: '140px' }}
        sx={(theme) => ({
          borderRadius: theme.spacing(1),
          position: 'relative',
          boxShadow: theme.yethosCustoms.shadows.card,
          backgroundColor: theme.palette.info.contrastText,
          overflow: 'hidden',
        })}
      >
        <Box width="100%" height="100%" display="flex">
          <img
            width="100%"
            alt="banner_profile"
            style={{ position: 'relative', objectFit: 'cover' }}
            src={profile?.banner_url}
          />
        </Box>
      </Box>
      <Box position="relative" display="flex" alignItems="center" gap={3}>
        <Avatar
          profileName={profile?.profile_name ?? ''}
          sx={{ width: '120px', height: '120px' }}
          src={profile?.avatar_url}
        />
        <Box display="flex" width="100%" flexDirection="column" gap={2}>
          <Text fontWeight={700} variant="h4">
            {profile?.profile_name}
          </Text>
          <Text fontWeight={400} variant="subtitle2" sx={{ color: 'info.dark' }}>
            {profile?.short_bio}
          </Text>
        </Box>
      </Box>
      <TabList tabs={tabs} />
    </Box>
  );
};

export default ProfileDetails;
