import { Box } from '@mui/material';

interface CommunityAvatarIconProps {
  avatarUrl: string | undefined;
}

const CommunityAvatarIcon = ({ avatarUrl }: CommunityAvatarIconProps) => {
  return (
    <Box width="32px" height="32px" fontSize={0} sx={() => ({ overflow: 'hidden' })}>
      <Box fontSize={0} height="32px">
        <img src={avatarUrl} alt="community-navbutton" height="100%" width="100%" style={{ objectFit: 'cover' }} />
      </Box>
    </Box>
  );
};

export default CommunityAvatarIcon;
