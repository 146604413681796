const AdminIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0.666504L14 3.33317V7.33317C14 11.0332 11.44 14.4932 8 15.3332C4.56 14.4932 2 11.0332 2 7.33317V3.33317L8 0.666504ZM8 2.11984L3.33333 4.19984V7.47984C3.33333 10.3598 5.5 13.3332 8 13.9998C10.5 13.3332 12.6667 10.3598 12.6667 7.47984V4.19984L8 2.11984ZM10.6667 9.33317V10.3932C10.64 10.5398 10.52 10.6398 10.3533 10.6665H5.64667C5.48 10.6398 5.36 10.5398 5.33333 10.3932V9.33317H10.6667ZM11.3333 5.33317L10.6667 8.6665H5.33333L4.66667 5.33317L6.44667 7.11317L8 5.55984L9.55333 7.11317L11.3333 5.33317Z"
        fill="#2D3748"
      />
    </svg>
  );
};

export default AdminIcon;
