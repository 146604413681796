import React from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import Button from './Button';

interface MoreIconMenuProps {
  options?: {
    label: JSX.Element;
    onClick: (args?: any) => void;
    shouldRender: boolean;
  }[];
  variant?: 'ghost' | 'outlined';
  size?: 'sm' | 'xs';
  isLoading?: boolean;
  height?: string;
  side?: 'left' | 'right';
}

const defaultOptions = [
  {
    label: (
      <>
        <ListItemIcon>
          <CloseIcon sx={{ width: '12px', height: '12px' }} />
        </ListItemIcon>
        <ListItemText>Deletar</ListItemText>
      </>
    ),
    onClick: () => alert('Delet click'),
    shouldRender: true,
  },
  {
    label: (
      <>
        <ListItemIcon>
          <ReportGmailerrorredIcon />
        </ListItemIcon>
        <ListItemText>Denunciar</ListItemText>
      </>
    ),
    onClick: () => alert('Report click'),
    shouldRender: true,
  },
];

const MoreIconMenu: React.FC<MoreIconMenuProps> = ({
  options = defaultOptions,
  variant = 'ghost',
  size = 'sm',
  isLoading = false,
  side = 'right',
  height,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const xs = size === 'xs';
  const right = side === 'right';

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  return (
    <>
      {variant === 'ghost' ? (
        <IconButton
          aria-label="more-button"
          id="open-more-button"
          aria-controls={open ? 'open-more-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          disabled={isLoading}
          disableRipple
          sx={(theme) => ({
            height: height ? height : theme.spacing(3),
            width: theme.spacing(3),
            color: 'secondary.main',
            '&:hover': { backgroundColor: 'transparent' },
          })}
        >
          <MoreHorizIcon />
        </IconButton>
      ) : (
        <Button
          disabled={isLoading}
          aria-label="more-button"
          id="open-more-button"
          aria-controls={open ? 'open-more-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          variant="outlined"
          size="small"
          sx={{
            minWidth: xs ? '24px' : '32px',
            height: xs ? '24px' : height ? height : undefined,
            padding: xs ? '6px' : undefined,
          }}
        >
          <MoreHorizIcon sx={{ width: xs ? '12px' : '16px', height: xs ? '12px' : '16px' }} />
        </Button>
      )}
      <Menu
        id="open-menu"
        MenuListProps={{
          'aria-labelledby': 'open-more-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: side, vertical: 'top' }}
        anchorOrigin={{ horizontal: side, vertical: 'bottom' }}
        PaperProps={{
          sx: (theme) => ({
            overflow: 'visible',
            boxShadow: theme.yethosCustoms.shadows.cardHover,
            border: `1px solid ${theme.palette.primary.light}`,
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: right ? (xs ? 8 : 14) : undefined,
              left: !right ? 6 : undefined,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              borderLeft: `1px solid ${theme.palette.primary.light}`,
              borderTop: `1px solid ${theme.palette.primary.light}`,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          }),
        }}
      >
        {options.map((option, idx) => {
          const onMenuClick = (args?: any) => {
            setAnchorEl(null);
            option.onClick(args ?? null);
            return;
          };
          return option.shouldRender ? (
            <MenuItem
              sx={(theme) => ({
                '& .MuiListItemText-root': {
                  '> span': {
                    fontWeight: 600,
                    fontSize: '.75rem',
                  },
                },
                '& .MuiListItemIcon-root': {
                  minWidth: '24px',
                  color: theme.palette.secondary.main,
                },
              })}
              key={`option_menu_${idx}`}
              onClick={() => onMenuClick()}
            >
              {option.label}
            </MenuItem>
          ) : null;
        })}
      </Menu>
    </>
  );
};

export default MoreIconMenu;
