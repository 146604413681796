const DAYS = [
  { id: '01', label: '01' },
  { id: '02', label: '02' },
  { id: '03', label: '03' },
  { id: '04', label: '04' },
  { id: '05', label: '05' },
  { id: '06', label: '06' },
  { id: '07', label: '07' },
  { id: '08', label: '08' },
  { id: '09', label: '09' },
  { id: '10', label: '10' },
  { id: '11', label: '11' },
  { id: '12', label: '12' },
  { id: '13', label: '13' },
  { id: '14', label: '14' },
  { id: '15', label: '15' },
  { id: '16', label: '16' },
  { id: '17', label: '17' },
  { id: '18', label: '18' },
  { id: '19', label: '19' },
  { id: '20', label: '20' },
  { id: '21', label: '21' },
  { id: '22', label: '22' },
  { id: '23', label: '23' },
  { id: '24', label: '24' },
  { id: '25', label: '25' },
  { id: '26', label: '26' },
  { id: '27', label: '27' },
  { id: '28', label: '28' },
  { id: '29', label: '29' },
  { id: '30', label: '30' },
  { id: '31', label: '31' },
];

const MONTHS = [
  { id: '01', label: 'Janeiro' },
  { id: '02', label: 'Fevereiro' },
  { id: '03', label: 'Março' },
  { id: '04', label: 'Abril' },
  { id: '05', label: 'Maio' },
  { id: '06', label: 'Junho' },
  { id: '07', label: 'Julho' },
  { id: '08', label: 'Agosto' },
  { id: '09', label: 'Setembro' },
  { id: '10', label: 'Outubro' },
  { id: '11', label: 'Novembro' },
  { id: '12', label: 'Dezembro' },
];

const YEARS = getYears();

function getYears() {
  const today = new Date().getFullYear();
  const years = [];
  const maxYear = today - 100;

  for (let i = maxYear; i <= today; i++) {
    const obj = { id: i.toString(), label: i.toString() };
    years.push(obj);
  }
  return years.reverse();
}

export { DAYS, MONTHS, YEARS };
