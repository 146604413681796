import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { axiosService } from 'common/services/axiosService';
import { USERS_KEY } from 'common/utils/queryKeys';
import useNotification from 'providers/NotificationProvider';

import { ACCOUNTS_API_PATH } from '../constants';
import { TNewUser, TUser, TUserUpdate, TPasswordUpdate } from '../types';

export function useCreateUserAPI() {
  const { toast } = useNotification();

  async function fetchCreateUserAPI(newUser: TNewUser) {
    const { data: user } = await axiosService.post<TUser>(`${ACCOUNTS_API_PATH}/users`, newUser);
    return user;
  }

  return useMutation({
    mutationFn: (newUser: TNewUser) => fetchCreateUserAPI(newUser),
    onError: (error) => {
      if (error instanceof AxiosError) {
        const { status } = error?.response?.data;
        let title;
        switch (status) {
          case 422:
            title = 'E-mail já existente.';
            break;
          default:
            title = 'Falha ao conectar no servidor';
            break;
        }
        return toast({ title, type: 'error' });
      }
      return toast({ title: 'Falha ao conectar no servidor', type: 'error' });
    },
  });
}

export function useGetUserByIdAPI(userId: string) {
  const queryClient = useQueryClient();
  async function fetchUser() {
    const response = await axiosService.get<TUser>(`${ACCOUNTS_API_PATH}/users/${userId}`);
    return response.data;
  }
  return useQuery(USERS_KEY.details(userId ?? ''), fetchUser, {
    enabled: !!userId,
    onSuccess: (response) => {
      queryClient.setQueryData(USERS_KEY.details(response._id), response);
    },
  });
}

export async function fetchMe() {
  const response = await axiosService.post<TUser>(`${ACCOUNTS_API_PATH}/users/me`);
  return response.data;
}

export function useMeAPI(options: UseQueryOptions<TUser>) {
  return useQuery({
    queryKey: [USERS_KEY._base, 'me'],
    queryFn: fetchMe,
    ...options,
  });
}

export function useUpdateUserAPI() {
  async function fetchUpdateUser({ userId, newData }: { userId: string; newData: TUserUpdate }) {
    const updatedUser = await axiosService.patch<TUser>(`${ACCOUNTS_API_PATH}/users/${userId}`, { ...newData });
    return updatedUser;
  }

  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchUpdateUser,
    onSuccess: (res) => {
      queryClient.setQueryData(USERS_KEY.details(res.data._id), res.data);
      queryClient.invalidateQueries(USERS_KEY._base);
    },
  });
}

export function useUpdateUserPasswordAPI() {
  async function fetchUpdateUserPassword({ userId, data }: { userId: string; data: TPasswordUpdate }) {
    const updatedUser = await axiosService.put<TUser>(`${ACCOUNTS_API_PATH}/users/${userId}/update-password`, {
      ...data,
    });
    return updatedUser;
  }

  return useMutation({
    mutationFn: fetchUpdateUserPassword,
  });
}
