import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

import { Box } from 'common/components';
import { Search } from 'common/components/SearchWithTransition';
import { TMember } from 'modules/communities/common/types/community.types';
import SelectFilter from 'modules/communities/common/components/SelectFilter';
import { MemberRoleEnum, MemberStatusEnum } from 'modules/communities/common/enums/members.enums';
import useAuthContext from 'providers/AuthProvider';

import CardMemberForPanel from './CardMemberForPanel';
import { useGetMembersByCommunityIdAPI } from '../communityMembers.api';
import { useParams } from 'react-router-dom';

type PanelMembersCommunityProps = {
  founderId: string;
  meMember: TMember | null;
};

const filters = [
  {
    label: 'Últimos 7 dias',
    value: '7',
  },
  {
    label: 'Últimos 14 dias',
    value: '14',
  },
  {
    label: 'Últimos 30 dias',
    value: '30',
  },
];

const PanelMembersCommunity = ({ founderId, meMember }: PanelMembersCommunityProps) => {
  const { user } = useAuthContext();
  const { id = '' } = useParams();
  const [filter, setFilter] = React.useState(filters[0]);
  const { data: returnMembers } = useGetMembersByCommunityIdAPI(id, {
    page: 0,
    size: 15,
  });

  const members = returnMembers?.items ?? [];

  const visibleMembers = members.filter(
    (member) =>
      member.status === MemberStatusEnum.ACTIVE ||
      member.status === MemberStatusEnum.BANNED ||
      member.status === MemberStatusEnum.SUSPENDED,
  );

  const handleChangeFilter = (value: { label: string; value: string }) => {
    setFilter(value);
  };
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={2}>
        <SelectFilter filtered={filter} filters={filters} handleChangeFilter={handleChangeFilter} />
        <Search.Container>
          <Search.IconWrapper>
            <SearchIcon sx={(theme) => ({ color: theme.palette.info.dark, width: '20px', height: '20px' })} />
          </Search.IconWrapper>
          <Search.InputBase placeholder="Membro..." />
        </Search.Container>
      </Box>
      <Box
        display="flex"
        sx={(theme) => ({ [theme.breakpoints.down('sm')]: { flexDirection: 'column' } })}
        flexWrap="wrap"
        gap={1}
      >
        {visibleMembers?.map((member, idx) => {
          const isUserFounder = founderId === user?.main_profile._id;
          const notShowOptions = member.role === MemberRoleEnum.ADMIN && !isUserFounder;
          const shouldRenderOptions =
            founderId !== member.profile._id && user?.main_profile._id !== member.profile._id && !notShowOptions;
          return (
            <CardMemberForPanel
              key={`member_panel_${member._id}`}
              founderId={founderId}
              meMember={meMember}
              member={member}
              shouldRenderOptions={shouldRenderOptions}
              idx={idx}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default PanelMembersCommunity;
