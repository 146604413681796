import * as React from 'react';
import Logout from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';

import { useNavigate } from 'app-router';
import useAuthContext from 'providers/AuthProvider';
import { getRoutePathname } from 'routes/getRoutePathname';
import useGetDefaultsNavigate from 'common/utils/useGetDefaultsNavigate';

interface AccountMenuProps {
  avatar: React.ReactNode;
  name: React.ReactNode;
}

export default function AccountMenu({ avatar, name }: AccountMenuProps) {
  const { logout, logoutLoading } = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { handleClickCreateCommunity } = useGetDefaultsNavigate();
  const navigate = useNavigate();
  const theme = useTheme();
  const matchSmallWidth = useMediaQuery(theme.breakpoints.down('sm'));
  const open = Boolean(anchorEl);

  const optionsSmallWidth = [
    { label: 'Criar comunidade', icon: <AddIcon fontSize="small" />, onClick: handleClickCreateCommunity },
  ];

  const handleProfileRoute = () => {
    navigate(getRoutePathname.Profile());
    handleClose();
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    logout(handleClose);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClickMenu}
          size="small"
          sx={{ ml: 2, padding: 0 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          {avatar}
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: (theme) => ({
            overflow: 'visible',
            boxShadow: 'none',
            border: `1px solid ${theme.palette.primary.light}`,
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              borderLeft: `1px solid ${theme.palette.primary.light}`,
              borderTop: `1px solid ${theme.palette.primary.light}`,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          }),
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleProfileRoute}>
          <Box display="flex" alignItems="center">
            {avatar} {name}
          </Box>
        </MenuItem>
        <Divider />
        {matchSmallWidth &&
          optionsSmallWidth.map((item, idx) => (
            <MenuItem
              key={`menu_item_${item.label}_${idx}`}
              onClick={() => {
                item.onClick();
                handleClose();
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {item.label}
            </MenuItem>
          ))}
        <MenuItem onClick={handleLogout}>
          {!logoutLoading ? (
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
          ) : (
            <ListItemIcon>
              <CircularProgress size="32px" color="secondary" />
            </ListItemIcon>
          )}
          Sair
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
