import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Dialog, DialogContent, InputBase } from '@mui/material';
import { debounce } from 'lodash';

import { Box, WrapperSearchInput } from 'common/components';
import { useGetImagesByManyAPI } from 'common/utils/useGetImagesByMany';
import { Editor } from '@tiptap/react';

type TDialogSearchImage = {
  handleClose: () => void;
  open: boolean;
  editor: Editor;
};

const DialogSearchImage = ({ handleClose, open, editor }: TDialogSearchImage) => {
  const [query, setQuery] = React.useState('');
  const { data, isLoading } = useGetImagesByManyAPI(query);

  const handleOnChangeQuery = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuery(event.target.value.toLowerCase());
  };

  const handleSelectImage = (src: string) => {
    if (src) {
      editor.chain().focus().setImage({ src }).run();
    }
    handleClose();
  };

  const debounceFn = debounce(handleOnChangeQuery, 400);
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          height: '600px',
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <DialogContent sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <Box width={{ xs: '90%', sm: '70%', md: '35vw' }}>
          <WrapperSearchInput>
            <Box
              sx={{
                padding: '0px 5px',
                height: '100%',
                position: 'absolute',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SearchIcon sx={(theme) => ({ color: theme.palette.info.dark })} />
            </Box>
            <InputBase
              size="small"
              placeholder={'Pesquisar comunidades'}
              onChange={debounceFn}
              sx={{
                color: 'inherit',
                width: '100%',
                '& .MuiInputBase-input': {
                  padding: 1,
                  paddingLeft: 5,
                  width: '100%',
                },
              }}
            />
          </WrapperSearchInput>
        </Box>
        <Box justifyContent="center" overflow="auto" padding={1} marginTop={2} gap={2} display="flex" flexWrap="wrap">
          {isLoading &&
            Array.from({ length: 30 }, (_i, idx) => (
              <Box
                overflow="hidden"
                minHeight="150px"
                minWidth="150px"
                key={`key_skeleton_${idx}`}
                sx={{ bgcolor: 'primary.light' }}
              />
            ))}
          {data?.results?.length > 0 &&
            data.results.map((it: any) => {
              return (
                <Box
                  key={`key_id_${it.urls.small}`}
                  onClick={() => handleSelectImage(it.urls.small)}
                  overflow="hidden"
                  height="150px"
                  sx={(theme) => ({
                    cursor: 'pointer',
                    boxSizing: 'border-box',
                    '&:hover': {
                      boxShadow: `0px 0px 0px 3px ${theme.palette.primary.main}`,
                    },
                  })}
                >
                  <img alt="img-1" width="100%" height="100%" src={it.urls.small} style={{ objectFit: 'cover' }} />
                </Box>
              );
            })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSearchImage;
