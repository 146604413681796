import React from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import { Collapse, useMediaQuery, useTheme } from '@mui/material';

import { createSearchParams, useNavigate } from 'app-router';
import { Box, Image, Text } from 'common/components';
import { getTimePassed } from 'common/utils/functions';
import CompleteAvatar from 'common/components/CompleteAvatar';
import { TTopic } from 'modules/communities/screens/posts/topic.types';
import { getRoutePathname } from 'routes/getRoutePathname';

import TopicButtons from './TopicButtons';
import EditorReadOnly from '../editor/EditorReadOnly';

type TTopicCard = {
  channelInfo?: React.ReactNode;
  channelId: string;
  post: TTopic;
};

const TopicCard = ({ channelInfo, post }: TTopicCard) => {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const postRoute = getRoutePathname.CommunitiesDetailsPostsDetails(post.community, post.channel, post._id);
  const handleClickPost = () => {
    navigate(postRoute);
  };
  const images = post.content?.content?.filter((it) => it.type === 'image') ?? [];

  const datePosted = getTimePassed(post.created_at);

  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasChannelInfo = !!channelInfo;

  const handleShowMore = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setExpanded((prevState) => !prevState);
    event.stopPropagation();
  };

  const handleEdit = () => {
    navigate(getRoutePathname.CommunitiesDetailsPostsEdit(post.community, post.channel, post._id));
  };

  const handleComment = () => {
    navigate({
      pathname: postRoute,
      search: `?${createSearchParams({ comment: 'true' })}`,
    });
  };

  return (
    <Box display="flex" gap={2} padding={2} flex={1} width="100%">
      {!matchMobile && (
        <Box
          sx={{ cursor: 'pointer', backgroundColor: 'info.dark' }}
          onClick={handleClickPost}
          display="flex"
          width="120px"
          height="120px"
          alignItems="center"
          justifyContent="center"
          overflow="hidden"
        >
          {images.length > 0 ? (
            <Image src={images?.[0].attrs?.src} />
          ) : (
            <DescriptionIcon sx={{ color: 'white', width: '44px', height: '44px' }} />
          )}
        </Box>
      )}
      <Box display="flex" gap={1} flexDirection="column" justifyContent="space-between" flex={1}>
        <Box display={!matchMobile ? 'flex' : 'none'} gap={1} alignItems="center" flexWrap="wrap">
          <CompleteAvatar
            profileId={post.created_by._id}
            fullName={post.created_by.profile_name}
            src={post.created_by.avatar_url}
          />
          {hasChannelInfo && (
            <>
              <Text variant="caption" sx={{ lineHeight: 1, fontSize: '13px' }}>
                no canal
              </Text>
              {channelInfo}
            </>
          )}

          <Text variant="caption" sx={{ lineHeight: 1, fontSize: '13px' }}>
            há {datePosted}
          </Text>
        </Box>
        <Box display={matchMobile ? 'flex' : 'none'} flexDirection="column" gap={1} justifyContent="center">
          <Box display="flex" flexWrap="wrap" gap={1} alignItems="center" justifyContent="space-between">
            <CompleteAvatar
              profileId={post.created_by._id}
              fullName={post.created_by.profile_name}
              src={post.created_by.avatar_url}
            />

            <Text variant="caption" sx={{ lineHeight: 1, fontSize: '13px' }}>
              há 11 dias
            </Text>
          </Box>
          {hasChannelInfo && (
            <Box display="flex" gap={1} alignItems="center">
              <Text variant="caption" sx={{ lineHeight: 1, fontSize: '13px' }}>
                tópico criado no canal
              </Text>
              {channelInfo}
            </Box>
          )}
        </Box>
        {!expanded && (
          <Box sx={{ cursor: 'pointer' }} onClick={handleClickPost}>
            <Text variant="h4" sx={{ color: 'black' }}>
              {post.title}
            </Text>
          </Box>
        )}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={{ cursor: 'pointer' }} display="flex" flexDirection="column" onClick={handleClickPost} gap={1}>
            <Box display="flex" textAlign="center" width="100%" justifyContent="center">
              <Text variant="h4" sx={{ color: 'black' }}>
                {post.title}
              </Text>
            </Box>
            <EditorReadOnly content={post.content} />
          </Box>
        </Collapse>

        <Box display="flex" gap={1} alignItems="center">
          {/* {post.comments?.[0] ? ( */}
          {/* <>
              <Text variant="caption2">
                Último{' '}
                <Box component="span" fontSize="13px" sx={{ color: 'secondary.main', cursor: 'pointer' }}>
                  comentário
                </Box>
              </Text>
              <CompleteAvatar onlyName profileId="" fullName={post.comments[0].user.full_name} src="" />

              <Text variant="caption2">2 min</Text>
            </>
          ) : ( */}
          <Text variant="caption">
            Tópico novo - Seja o primeiro a{' '}
            <Box
              onClick={handleComment}
              component="span"
              fontSize=".875rem"
              sx={{ color: 'secondary.main', cursor: 'pointer' }}
            >
              comentar
            </Box>
          </Text>
          {/* )} */}
        </Box>

        <TopicButtons topic={post} handleEdit={handleEdit} expanded={expanded} handleShowMore={handleShowMore} />
      </Box>
    </Box>
  );
};

export default TopicCard;
