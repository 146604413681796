import React from 'react';
import { Command } from 'cmdk';
import SearchIcon from '@mui/icons-material/Search';
import { Dialog } from '@mui/material';

import { useNavigate, useParams } from 'app-router';
import { Box, Button } from 'common/components';
import { useGetTopicsByCommunityIdAPI } from 'modules/communities/screens/posts/post.api';
import { getRoutePathname } from 'routes/getRoutePathname';

import './cmdk.css';
import TopicCardForSearch from './TopicCardForSearch';

const SearchTopics = () => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const { id = '' } = useParams();
  const { data } = useGetTopicsByCommunityIdAPI(id);
  const navigate = useNavigate();

  const topics = data?.items ?? [];

  const handleClick = (channelId: string, topicId: string) => {
    navigate(getRoutePathname.CommunitiesDetailsPostsDetails(id, channelId, topicId));
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  return (
    <>
      <Button onClick={handleOpen} variant="outlined" sx={{ minWidth: '32px', minHeight: '30.75px' }} size="small">
        <SearchIcon sx={{ width: '16px', height: '16px' }} />
      </Button>
      <Dialog sx={{ '& .MuiPaper-root': { width: '100%', maxWidth: '640px' } }} onClose={handleClose} open={open}>
        <Box className="raycast">
          <Command value={value} onValueChange={setValue} label="Command Menu">
            <Command.Input autoFocus={true} placeholder="Procurar conteúdo..." />
            <Command.Separator alwaysRender />
            <Command.List>
              <Command.Empty>Nenhum resultado.</Command.Empty>
              {topics.length > 0 &&
                topics.map((topic) => {
                  return (
                    <Command.Item
                      key={`topic_search_cmdk_${topic._id}`}
                      value={`${topic.title} ${topic._id}`}
                      onSelect={() => handleClick(topic.channel, topic._id)}
                    >
                      <TopicCardForSearch topic={topic} />
                    </Command.Item>
                  );
                })}
            </Command.List>
          </Command>
        </Box>
      </Dialog>
    </>
  );
};

export default SearchTopics;
