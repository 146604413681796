import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';

import { Box } from 'common/components';

type TCarousel = {
  children: React.ReactNode;
  resetScroll?: string;
};

const Carousel = ({ children, resetScroll }: TCarousel) => {
  const carouselRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [scrollLeft, setScrollLeft] = React.useState(0);
  let limitRight =
    carouselRef.current &&
    carouselRef.current.offsetWidth + carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth;

  const notShowRight = !!limitRight;
  const notShowLeft = scrollLeft === 0;

  const handleScrollLeft = () => {
    if (carouselRef.current) {
      const slide = matchMobile ? 120 : 200;
      carouselRef.current.scrollLeft -= slide;
      setScrollLeft((prev) => (prev === 0 ? 0 : prev - slide));
    } else return;
  };
  const handleScrollRight = () => {
    if (carouselRef.current) {
      const slide = matchMobile ? 120 : 200;
      carouselRef.current.scrollLeft += slide;
      setScrollLeft((prev) => (!!limitRight ? prev : prev + slide));
    } else return;
  };

  React.useEffect(() => {
    setScrollLeft(0);
  }, [resetScroll]);

  return (
    <Box
      display="flex"
      position="relative"
      justifyContent="flex-start"
      overflow="hidden"
      gap={0.5}
      sx={{ borderRadius: 0 }}
    >
      {!notShowLeft && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            zIndex: 999,
            background: !matchMobile
              ? 'linear-gradient(90deg, rgba(247,250,252,1) 0%, rgba(247,250,252,0.9416141456582633) 74%, rgba(247,250,252,0) 100%);'
              : 'linear-gradient(90deg, rgba(247,250,252,1) 0%, rgba(247,250,252,0.9416141456582633) 15%, rgba(247,250,252,0) 100%);',
            width: matchMobile ? '30px' : '60px',
            borderRadius: '0',
          }}
        >
          <IconButton size="small" sx={{ height: '32px', width: '32px' }} onClick={handleScrollLeft}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="flex-start"
        ref={carouselRef}
        overflow="hidden"
        gap={1}
        sx={{
          borderRadius: 0,
        }}
      >
        {children}
      </Box>
      {!notShowRight && (
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            zIndex: 999,
            background: !matchMobile
              ? 'linear-gradient(270deg, rgba(247,250,252,1) 0%, rgba(247,250,252,0.9416141456582633) 74%, rgba(247,250,252,0) 100%)'
              : 'linear-gradient(270deg, rgba(247,250,252,1) 0%, rgba(247,250,252,0.9416141456582633) 15%, rgba(247,250,252,0) 100%)',
            width: matchMobile ? undefined : '60px',
            display: 'flex',
            justifyContent: 'flex-end',
            borderRadius: 0,
          }}
        >
          <IconButton size="small" sx={{ height: '32px', width: '32px' }} onClick={handleScrollRight}>
            <ChevronRightIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default Carousel;
