import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import { axiosService } from 'common/services/axiosService';
import { COMMUNITIES_KEY, MEMBERS_KEY, PROFILES_KEY, USERS_KEY } from 'common/utils/queryKeys';
//TODO import keys and types from anothe module*
import { COMMUNITIES_API_PATH } from 'modules/communities/common/constants';
import { TMember } from 'modules/communities/common/types/community.types';
import { omit } from 'lodash';
import { TPaginatedResource } from 'common/types/api.types';
import useNotification from 'providers/NotificationProvider';

export type TCreateMemberData = {
  profile: string;
  community: string;
  role: string;
};
export type TUpdateMemberData = {
  id: string;
  update: Pick<TMember, 'role'> | Pick<TMember, 'status'>;
};

type TParams = {
  page?: number;
  size?: number;
  [key: string]: unknown;
};

export async function fetchInfiniteMember({
  userParams,
  pageParam,
  id,
}: {
  userParams: TParams;
  pageParam?: number;
  id: string;
}) {
  const params = { ...userParams, page: pageParam ?? userParams.page };
  const { data } = await axiosService.get<TPaginatedResource<TMember>>(
    `${COMMUNITIES_API_PATH}/members/communities/${id}`,
    {
      params,
    },
  );
  return data;
}

export function useGetInfiniteMembersAPI(
  id: string,
  userParams: TParams = { page: 0, size: 15 },
  options?: Omit<
    UseInfiniteQueryOptions<
      TPaginatedResource<TMember>,
      unknown,
      TPaginatedResource<TMember>,
      TPaginatedResource<TMember>,
      (string | TParams)[]
    >,
    'staleTime'
  >,
) {
  return useInfiniteQuery(
    MEMBERS_KEY.list({ id, infinite: true }),
    ({ pageParam }) => fetchInfiniteMember({ pageParam, userParams, id }),
    {
      getNextPageParam: (lastPage) => {
        const items = (lastPage.page + 1) * lastPage.size;
        if (lastPage.totalItems - items >= 1) {
          const nextPage = lastPage.page + 1;
          return nextPage;
        } else return undefined;
      },

      staleTime: 1500,
      ...(options ? omit(options, 'onSuccess') : {}),
    },
  );
}

async function fetchMembersByCommunityId(id: string, params: TParams = {}) {
  const { data } = await axiosService.get<TPaginatedResource<TMember>>(
    `${COMMUNITIES_API_PATH}/members/communities/${id}`,
    {
      params,
    },
  );
  return data;
}

export function useGetMembersByCommunityIdAPI(
  id: string,
  params: TParams = {},
  options?: Omit<
    UseQueryOptions<TPaginatedResource<TMember>, unknown, TPaginatedResource<TMember>, (string | TParams)[]>,
    'staleTime'
  >,
) {
  return useQuery(MEMBERS_KEY.details(id, params), () => fetchMembersByCommunityId(id, params), {
    enabled: !!id,
    ...(options ? omit(options, 'onSuccess') : {}),
  });
}

async function fetchCreateMemberByProfileId({ profile, community, role }: TCreateMemberData) {
  const body = { profile, community, role };
  const { data } = await axiosService.post<TMember>(`${COMMUNITIES_API_PATH}/members`, body);

  return data;
}

export function useCreateMemberByProfileIdAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchCreateMemberByProfileId,

    onSettled: (_data, _error, variables) => {
      queryClient.invalidateQueries([USERS_KEY._base, 'me']);
      queryClient.invalidateQueries(PROFILES_KEY.details(variables.profile));
      queryClient.invalidateQueries(COMMUNITIES_KEY.details(variables.community));
      queryClient.invalidateQueries(MEMBERS_KEY.details(variables.community));
    },
  });
}

async function fetchDeleteMemberByMemberId({ id }: { id: string }) {
  const { data } = await axiosService.delete<TMember>(`${COMMUNITIES_API_PATH}/members/${id}`);
  return data;
}

export function useDeleteMemberByMemberIdAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchDeleteMemberByMemberId,
    onSuccess: (res) => {
      const previousDataRaw = queryClient.getQueryData(MEMBERS_KEY.details(res.community));
      const previousData = Array.isArray(previousDataRaw) ? previousDataRaw : [];
      const members = previousData.filter((member) => member._id !== res._id);
      queryClient.setQueryData(MEMBERS_KEY.details(res.community), [...members]);
      queryClient.invalidateQueries([USERS_KEY._base, 'me']);
      queryClient.invalidateQueries(COMMUNITIES_KEY.details(res.community));
      queryClient.invalidateQueries(PROFILES_KEY.details(res.profile._id));
    },
  });
}

async function fetchUpdateMemberByMemberId({ id, update }: TUpdateMemberData) {
  const { data } = await axiosService.patch<TMember>(`${COMMUNITIES_API_PATH}/members/${id}`, { ...update });
  return data;
}

export function useUpdateMemberByMemberIdAPI(communityId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, update }: TUpdateMemberData) => fetchUpdateMemberByMemberId({ id, update }),
    onSettled: () => {
      queryClient.invalidateQueries(MEMBERS_KEY.details(communityId));
      queryClient.invalidateQueries(PROFILES_KEY._base);
      queryClient.invalidateQueries([USERS_KEY._base, 'me']);
    },
  });
}

async function fetchInviteMembers(inviteData: { emails: string[]; community: string }) {
  const body = { ...inviteData };
  const { data } = await axiosService.post(`${COMMUNITIES_API_PATH}/communities/invite-new-members`, body);

  return data;
}

export function useInviteMembersByIdAPI() {
  const { toast } = useNotification();

  return useMutation({
    mutationFn: fetchInviteMembers,

    onSuccess: () => {
      toast({ title: 'Membros convidados.' });
    },
  });
}
