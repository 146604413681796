import { AxiosError } from 'axios';
import { orderBy } from 'lodash';

import { TPaginatedResource } from 'common/types/api.types';
import { TTopic } from 'modules/communities/screens/posts/topic.types';

const returnTimePassed = (minutes: number) => {
  const minute = 60;
  const day = minute * 24;
  const week = day * 7;
  const month = day * 30;
  const year = month * 12;

  if (minutes < minute) {
    return `${minutes} minutos`;
  }
  if (minutes >= minute && minutes < day) {
    const time = Math.floor(minutes / minute);
    const title = time === 1 ? 'hora' : 'horas';
    return `${time} ${title}`;
  }
  if (minutes >= day && minutes < week) {
    const time = Math.floor(minutes / day);
    const title = time === 1 ? 'dia' : 'dias';
    return `${time} ${title}`;
  }
  if (minutes >= week && minutes < month) {
    const time = Math.floor(minutes / week);
    const title = time === 1 ? 'semana' : 'semanas';
    return `${time} ${title}`;
  }
  if (minutes >= month && minutes < year) {
    const time = Math.floor(minutes / month);
    const title = time === 1 ? 'mês' : 'meses';
    return `${time} ${title}`;
  } else {
    const time = Math.floor(minutes / year);
    const title = time === 1 ? 'ano' : 'anos';
    return `${time} ${title}`;
  }
};

export const getTimePassed = (date: Date) => {
  const nowDate = new Date();
  const postDate = new Date(date);
  const differenceDate = nowDate.getTime() - postDate.getTime();
  const minutes = Math.floor(differenceDate / (1000 * 60));
  return returnTimePassed(minutes);
};

export const orderTopicsByRecently = (topics: TTopic[]) => {
  return orderBy(topics, ['created_at'], ['desc']);
};

export async function handleFetchMany<T>(promise: Promise<TPaginatedResource<T>>) {
  try {
    const data = await promise;
    return data;
  } catch (error) {
    if (error instanceof AxiosError && error.response && error.response.status === 404) {
      const emptyData: TPaginatedResource<T> = { items: [], page: 0, size: 0, totalItems: 0 };
      return emptyData;
    }
    throw error;
  }
}
