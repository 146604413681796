import { QueryClient } from 'react-query';

import { RouteObject } from 'app-router';
import { ERoutesProfile } from 'routes/ERoutesProfile';

import UserScreen from './User.screen';
import ProfileDetails, { loaderProfile } from './ProfileDetails.screen';

const profileRoutes: (queryClient: QueryClient) => RouteObject = (queryClient) => ({
  path: ERoutesProfile.Profile,
  children: [
    {
      index: true,
      element: <UserScreen />,
    },
    {
      path: ERoutesProfile.Profile_Details,
      element: <ProfileDetails />,
      loader: loaderProfile(queryClient),
    },
  ],
});

export default profileRoutes;
