import { HelperMessagesEnum } from 'common/utils/validation.schemas';
import { TypeChannelEnum } from 'modules/communities/common/channels.enum';
import { z } from 'zod';

const typeValues = Object.values(TypeChannelEnum);
const Type = [typeValues[0], ...typeValues.slice(1)] as const;

const name = z
  .string()
  .min(5, { message: 'Nome é obrigatório.' })
  .max(30, { message: HelperMessagesEnum.MAX_CHARACTERS });
const description = z
  .string()
  .min(5, { message: 'Descrição é obrigatório.' })
  .max(90, { message: HelperMessagesEnum.MAX_CHARACTERS });
const type = z.enum(Type).refine((val) => val.length > 0, 'Precisa selecionar o tipo do canal');

//CREATE CHANNEL SETTINGS SCHEMA
export const CreateChannelSchema = z.object({
  name: name,
  description: description,
  type: type,
});

export type TCreateChannelForm = z.infer<typeof CreateChannelSchema>;

//EDIT CHANNEL SETTINGS SCHEMA
export const EditChannelSchema = z.object({
  name: name,
  description: description,
});
export type TEditChannelForm = z.infer<typeof EditChannelSchema>;

//UPDATE CHANNEL SCHEMA
const FileSchema = z
  .custom<string | File | FileList>((val) => {
    if (typeof val === 'string' || val instanceof File || val instanceof FileList) {
      return true;
    } else return false;
  }, 'Arquivo inválido')
  .optional();

export const UpdateChannelImgsSchema = z.object({
  avatarUrl: FileSchema,
});

export type TUpdateChannelImgsForm = z.infer<typeof UpdateChannelImgsSchema>;
