import { TMember } from '../types/community.types';
import { MemberRoleEnum } from '../enums/members.enums';

const useCommunityPermissions = () => {
  const isFounder = (member: TMember | null, founderId: string) => (member ? member.profile._id === founderId : false);
  const isAdmin = (member: TMember | null) => (member ? member.role === MemberRoleEnum.ADMIN : false);
  const isModerator = (member: TMember | null) => (member ? member.role === MemberRoleEnum.MODERATOR : false);
  const isMember = (member: TMember | null) => (member ? member.role === MemberRoleEnum.MEMBER : false);

  const publicChannels = {
    canCreate: isAdmin,
    canRead: isAdmin || isModerator || isMember,
    canUpdate: isAdmin,
    canDelete: isFounder,
    canLock: isAdmin,
  };

  const memberActionsToMembers = {
    canPromoveAdmin: isFounder,
    canDowngradeAdmin: isFounder,
    canPromoveModerator: isAdmin,
    canDowngradeModerator: isAdmin,
    canRemoveMember: isAdmin || isModerator,
    canBanMember: isAdmin || isModerator,
    canUnbanMember: isAdmin || isModerator,
  };

  return { publicChannels, memberActionsToMembers };
};

export default useCommunityPermissions;
