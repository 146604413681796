import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'app-router';
import { worker } from 'common/services/mocks/browser';

import router from './routes';
import reportWebVitals from './reportWebVitals';
import { CssBaseline } from '@mui/material';

if (process.env.NODE_ENV === 'development') {
  worker.start();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
