import { useTheme, useMediaQuery } from '@mui/material';

import { Box, Card } from 'common/components';
import { orderTopicsByRecently } from 'common/utils/functions';
import { useGetTopicsByChannelIdAPI } from 'modules/communities/screens/posts/post.api';
import TopicCard from 'modules/communities/common/components/TopicCard';

const TabBookmarks = () => {
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data } = useGetTopicsByChannelIdAPI('64c440b199b2dc233054718a');

  const posts = data?.items ?? [];
  const orderedPostsByRecently = orderTopicsByRecently(posts);

  return orderedPostsByRecently.length > 0 ? (
    <Box display="flex" flex={2} gap={1} width="100%" paddingTop="16px" flexDirection="column" alignItems="center">
      {!matchMobile && (
        <Card
          width="100%"
          sx={(theme) => ({
            [theme.breakpoints.up('md')]: { width: '100%', maxWidth: '1080px' },
            padding: 0,
            gap: 0,
          })}
        >
          {orderedPostsByRecently.map((post, index) => (
            <Box
              width="100%"
              key={`post_mural_${post._id}`}
              sx={(theme) => ({
                borderBottom:
                  index < orderedPostsByRecently.length - 1 ? `1px solid ${theme.palette.primary.light}` : undefined,
                borderRadius: 0,
              })}
            >
              <TopicCard channelId={post._id} post={post} />
            </Box>
          ))}
        </Card>
      )}
      {matchMobile &&
        orderedPostsByRecently.map((post, index) => (
          <Card
            width="100%"
            key={`post_mural_${post._id}_${index}`}
            sx={() => ({
              padding: 0,
              gap: 0,
            })}
          >
            <TopicCard channelId={post._id} post={post} />
          </Card>
        ))}
    </Box>
  ) : null;
};

export default TabBookmarks;
