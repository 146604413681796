import { useMutation, useQueryClient } from 'react-query';

import { axiosService } from 'common/services/axiosService';

import { ACCOUNTS_API_PATH } from '../constants';
import { TUser, TActivateUser, TCredentials, TResetPassword } from '../types';
import { USERS_KEY } from 'common/utils/queryKeys';

function useActivateUserAPI() {
  async function fetchActivateUserAPI(activateUser: TActivateUser) {
    const { userId, activationCode } = activateUser;
    const user = await axiosService.post<TUser>(`${ACCOUNTS_API_PATH}/users/${userId}/activate/${activationCode}`);
    return user;
  }

  return useMutation({
    mutationFn: (activateUser: TActivateUser) => fetchActivateUserAPI(activateUser),
  });
}

function useResendActivateUserAPI() {
  async function fetchResendActivateUserAPI(userId: string) {
    const user = await axiosService.post(`${ACCOUNTS_API_PATH}/users/${userId}/resend-activation-code`);
    return user;
  }

  return useMutation({
    mutationFn: (userId: string) => fetchResendActivateUserAPI(userId),
  });
}

function useLoginAPI() {
  const queryClient = useQueryClient();
  async function fetchLoginAPI(credentials: TCredentials) {
    const user = await axiosService.post(`${ACCOUNTS_API_PATH}/auth/login`, { ...credentials });
    return user;
  }

  return useMutation({
    mutationFn: (credentials: TCredentials) => fetchLoginAPI(credentials),
    onSuccess: (response) => {
      queryClient.setQueryData([USERS_KEY._base, 'me'], response.data);
    },
  });
}

function useLogoutAPI() {
  const queryClient = useQueryClient();
  async function fetchLogoutAPI() {
    const response = await axiosService.post(`${ACCOUNTS_API_PATH}/auth/logout`);
    return response;
  }

  return useMutation({
    mutationFn: fetchLogoutAPI,
    onSuccess: () => {
      queryClient.setQueryData([USERS_KEY._base, 'me'], null);
    },
  });
}

function useForgotPasswordAPI() {
  async function fetchForgotPasswordAPI(email: string) {
    const response = await axiosService.post(`${ACCOUNTS_API_PATH}/users/forgot-password`, { email });
    return response;
  }

  return useMutation({ mutationFn: (email: string) => fetchForgotPasswordAPI(email) });
}

function useResetPasswordAPI() {
  async function fetchResetPasswordAPI(body: TResetPassword) {
    const response = await axiosService.post(`${ACCOUNTS_API_PATH}/users/reset-password`, body);
    return response;
  }

  return useMutation({ mutationFn: (body: TResetPassword) => fetchResetPasswordAPI(body) });
}

export {
  useActivateUserAPI,
  useLoginAPI,
  useLogoutAPI,
  useResendActivateUserAPI,
  useForgotPasswordAPI,
  useResetPasswordAPI,
};
