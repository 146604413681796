import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, Divider } from '@mui/material';
import { useForm, useWatch, FormProvider } from 'react-hook-form';

import CropImage from 'common/components/CropImage';
import { Text, Box, Button, Image, LoadingButton } from 'common/components';

import { useUpdateChannelAvatarByIdAPI } from '../channel.api';
import { getImgFile, getImgSrc } from 'common/utils/imageResource';
import { TUpdateChannelImgsForm, UpdateChannelImgsSchema } from '../channel.schemas';

type EditCommunityDialogProps = {
  open: boolean;
  handleClose: () => void;
  avatarUrl: string;
  channelId: string;
  communityId: string;
};

const EditCommunityDialog = ({ open, handleClose, avatarUrl, channelId, communityId }: EditCommunityDialogProps) => {
  const [openCrop, setOpenCrop] = React.useState('none');
  const [originalImage, setOriginalImage] = React.useState(avatarUrl);

  const { mutate: updateAvatar, isLoading: updatingAvatarLoading } = useUpdateChannelAvatarByIdAPI(
    channelId,
    communityId,
  );
  const formMethods = useForm<TUpdateChannelImgsForm>({
    resolver: zodResolver(UpdateChannelImgsSchema),
    defaultValues: {
      avatarUrl: avatarUrl,
    },
  });
  const { register, control, reset } = formMethods;
  const avatar = useWatch({ control, name: 'avatarUrl' });
  const urlAvatar = getImgSrc(avatar);

  const handleCancel = () => {
    reset();
  };

  const handleCloseCrop = () => {
    setOpenCrop('none');
  };

  const handleSaveImg = () => {
    const avatarFile = getImgFile(avatar);

    updateAvatar(avatarFile, {
      onSuccess: () => {
        reset({ avatarUrl: urlAvatar });
      },
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            gap: 2,
            padding: 2,
            minWidth: '300px',
            maxWidth: '920px',
            width: '100%',
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Text variant="h3">Alterar foto</Text>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon sx={{ width: '24px', height: '24px' }} />
          </IconButton>
        </Box>
        <Divider sx={{ width: '100%', color: 'info.dark' }} />
        <Box display="flex" flexDirection="column" gap={1}>
          <Text variant="h4">Foto do avatar</Text>
          <Box
            width="280px"
            minWidth="280px"
            maxWidth="280px"
            minHeight="157.5px"
            maxHeight="157.5px"
            height="157.5px"
            bgcolor="white"
            sx={(theme) => ({ border: `1px solid ${theme.palette.primary.light}` })}
            overflow="hidden"
          >
            <Image src={urlAvatar ?? ''} />
          </Box>
          <Box display="flex" gap={2}>
            {typeof avatar !== 'string' ? (
              <>
                <Button disabled={updatingAvatarLoading} variant="outlined" size="small" onClick={() => handleCancel()}>
                  Cancelar
                </Button>
                <Button
                  disabled={updatingAvatarLoading}
                  variant="outlined"
                  size="small"
                  onClick={() => setOpenCrop('avatarUrl')}
                >
                  Editar
                </Button>
                <LoadingButton
                  loading={updatingAvatarLoading}
                  size="small"
                  variant="contained"
                  onClick={() => handleSaveImg()}
                >
                  Salvar
                </LoadingButton>
              </>
            ) : (
              <Button variant="outlined" size="small" component="label">
                Alterar imagem
                <input
                  hidden
                  accept="image/png, image/jpg, image/jpeg"
                  type="file"
                  {...register('avatarUrl')}
                  onChange={(e) => {
                    register('avatarUrl').onChange(e);
                    if (!!e.target?.files?.[0]) {
                      setOriginalImage(getImgSrc(e.target.files[0]));
                    }
                    setOpenCrop('avatarUrl');
                  }}
                />
              </Button>
            )}
            <CropImage handleClose={handleCloseCrop} type="avatarUrl" open={openCrop} image={originalImage} />
          </Box>
        </Box>
        <Box />
      </Dialog>
    </FormProvider>
  );
};

export default EditCommunityDialog;
