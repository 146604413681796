import { Box, Card, Text } from 'common/components';
import CompleteAvatar from 'common/components/CompleteAvatar';
import { TMember } from 'modules/communities/common/types/community.types';

import MoreIconMenuMember from './MoreIconMenuMember';

type TCardMemberForPanel = {
  member: TMember;
  shouldRenderOptions: boolean;
  founderId: string;
  meMember: TMember | null;
  idx?: number;
};

const CardMemberForPanel = ({ member, shouldRenderOptions, founderId, meMember, idx = 0 }: TCardMemberForPanel) => {
  return (
    <Card sx={{ padding: '8px 16px', flexDirection: 'row', gap: 1, minWidth: '297px' }}>
      <Box>
        <Text variant="h4">{idx + 1}</Text>
      </Box>
      <Box display="flex" flexDirection="column" width="100%" gap={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CompleteAvatar
            profileId={member.profile._id}
            fullName={member.profile.profile_name}
            src={member.profile.avatar_url}
            role={member.role}
            status={member.status}
          />
          {shouldRenderOptions && <MoreIconMenuMember founderId={founderId} meMember={meMember} member={member} />}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Text variant="body2">{member.topics_count} Tópicos</Text>
          <Text variant="body2">{member.comments_count} Comentários</Text>
        </Box>
      </Box>
    </Card>
  );
};

export default CardMemberForPanel;
