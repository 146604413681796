import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { Outlet, useNavigate, useParams } from 'app-router';
import { Box, Button, Image, Text } from 'common/components';
import { useGetCommunityByIdAPI } from 'modules/communities/screens/community/community.api';
import { useGetChannelByIdAPI } from 'modules/communities/screens/channel/channel.api';
import { getRoutePathname } from 'routes/getRoutePathname';

const TopicShell = () => {
  const { id = '', channelId = '' } = useParams();
  const navigate = useNavigate();

  const { data: community, isLoading: isLoadingCommunity } = useGetCommunityByIdAPI(id);
  const { data: channel, isLoading: isLoadingChannel } = useGetChannelByIdAPI(channelId);

  const isLoading = isLoadingCommunity || isLoadingChannel;

  const handleBackToCommunity = () => {
    navigate(getRoutePathname.CommunitiesDetails(id));
  };
  const handleBackToChannel = () => {
    navigate(getRoutePathname.CommunitiesDetailsChannel(id, channelId));
  };

  if (isLoading) {
    return null;
  }

  if (!community || !channel) {
    return <div>No community.</div>;
  }

  return (
    <Box
      sx={(theme) => ({
        bgcolor: theme.palette.info.light,
      })}
      display="flex"
      flexDirection="column"
      position="relative"
      paddingBottom={2}
      alignItems="center"
      width="100%"
      marginTop={{ xs: 1, sm: 0 }}
    >
      <Box
        position="sticky"
        zIndex={900}
        paddingBottom={1}
        top={{ xs: '0', sm: '56px' }}
        sx={(theme) => ({ bgcolor: theme.palette.info.light })}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
        gap={1}
      >
        <Button
          onClick={handleBackToCommunity}
          disableTouchRipple
          sx={{
            height: '40px',
            minWidth: '40px',
            gap: 1,
            justifyContent: 'flex-start',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          <KeyboardArrowLeftIcon />
          <Box display={{ xs: 'none', sm: 'block' }} height="32px" width="32px" overflow="hidden">
            <Image src={community.avatar_url} />
          </Box>
          <Text variant="h4" sx={{ fontSize: { xs: '.875rem', sm: '1rem' } }}>
            {community.name}
          </Text>
        </Button>
        <Button
          onClick={handleBackToChannel}
          disableTouchRipple
          sx={{
            height: '40px',
            minWidth: '40px',
            gap: 1,
            justifyContent: 'flex-start',
            '&:hover': { backgroundColor: 'transparent' },
          }}
        >
          <KeyboardArrowLeftIcon />
          <Box display={{ xs: 'none', sm: 'block' }} height="32px" width="32px" overflow="hidden">
            <Image src={channel.photo_url} />
          </Box>
          <Text variant="h4" sx={{ fontSize: { xs: '.875rem', sm: '1rem' } }}>
            {channel.name}
          </Text>
        </Button>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" padding={0.5}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default TopicShell;
