import { rest } from 'msw';
import { getApiMockData } from 'common/services/axiosService';
import { CommunityPropsMOCK, communitiesMockedData } from '../mockData';

const BASE_URL = getApiMockData();
const communitiesDB = communitiesMockedData;

const HandlersPosts = (delay: number) => {
  return [
    rest.get(BASE_URL + '/communities/channel', async (_req, res, ctx) => {
      const community = communitiesDB[0];
      const channel = community.channels?.[0];
      return res(ctx.status(200), ctx.json(channel?.posts), ctx.delay(delay));
    }),

    rest.get(BASE_URL + '/communities/:id/channel/:channelId/posts/:postId', async (req, res, ctx) => {
      const { id, postId, channelId } = req.params;
      const community = communitiesDB.find((community) => community._id === id);
      const channel = community?.channels?.find((channel) => channel.id === channelId);
      const currentPost = channel?.posts.find((post) => post.id === postId);
      return res(ctx.status(200), ctx.json(currentPost), ctx.delay(delay));
    }),

    rest.put(BASE_URL + '/communities/:id/posts/:postId', async (req, res, ctx) => {
      const newData: any = req.body;
      const { id: communityId, postId } = req.params;
      const community = communitiesDB.find((community) => community._id === communityId) as CommunityPropsMOCK;
      const { posts } = community;

      const index = posts?.findIndex((post) => post.id === postId);
      posts[index] = { ...posts[index], ...newData };

      return res(ctx.status(200), ctx.json(posts[index]), ctx.delay(delay));
    }),
  ];
};

export default HandlersPosts;
