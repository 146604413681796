import { useNavigate } from 'app-router';
import AdminIcon from 'assets/icons/AdminIcon';
import ModeratorIcon from 'assets/icons/ModeratorIcon';
import { Avatar, Box, Button, Card, Image, Text } from 'common/components';
import { TMember } from 'modules/communities/common/types/community.types';
import { MemberRoleEnum } from 'modules/communities/common/enums/members.enums';
import { getRoutePathname } from 'routes/getRoutePathname';

import WrapperCommunitiesCardMember from './WrapperCommunitiesCardMember';
import useAuthContext from 'providers/AuthProvider';

type CardMemberProps = {
  user: TMember;
};

const CardMember = ({ user: member }: CardMemberProps) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const onClickProfile = () => {
    if (member.profile._id === user?.main_profile._id) {
      return navigate(getRoutePathname.Profile());
    }
    return navigate(getRoutePathname.ProfileDetails(member.profile._id));
  };
  return (
    <Card
      width="280px"
      height="258px"
      sx={(theme) => ({
        gap: 1,
        padding: 0,
        paddingBottom: 2,
        overflow: 'hidden',
        boxShadow: theme.yethosCustoms.shadows.cardHover,
      })}
      alignItems="center"
    >
      {/*banner is 6:1, so width=280 height=280 / 6 */}
      <Box width="280px" position="relative">
        <Box
          sx={{
            background: 'linear-gradient(180deg, rgba(221, 107, 32, 0.64) 0%, rgba(156, 66, 33, 0.64) 100%)',
            borderRadius: 0,
          }}
          width="280px"
          minHeight="calc(280px / 6)"
          maxHeight="calc(280px / 6)"
          height="calc(280px / 6)"
        >
          <Image src={member.profile.banner_url} />
        </Box>
        <Box position="absolute" left="108px" top="14.67px">
          <Avatar
            sx={{ width: '64px', height: '64px', fontSize: '26px', fontWeight: 500 }}
            profileName={member.profile.profile_name}
            src={member.profile.avatar_url}
          />
        </Box>
      </Box>
      {/*margin top = height avatar / 2  */}
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        marginTop="32px"
        flexDirection="column"
        padding="0px 16px"
        gap={1}
      >
        <Box display="flex" gap={1} alignItems="center">
          <Text fontWeight={700}>{member.profile.profile_name}</Text>
          {member.role === MemberRoleEnum.ADMIN && <AdminIcon />}
          {member.role === MemberRoleEnum.MODERATOR && <ModeratorIcon />}
        </Box>
        <Text variant="body1">{member.profile.short_bio}</Text>
      </Box>
      <Box display="flex" flex={1} flexDirection="column" gap={1} justifyContent="flex-end" alignItems="center">
        <WrapperCommunitiesCardMember communities={member.profile.communities} />
        <Button
          onClick={onClickProfile}
          variant="outlined"
          sx={{ minHeight: '24px', padding: '4px 8px', fontSize: '.75rem', fontWeight: 600, lineHeight: '1rem' }}
        >
          Ver perfil
        </Button>
      </Box>
    </Card>
  );
};

export default CardMember;
