import React from 'react';
import { Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const SwitchYethosStyled = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  marginRight: 8,
  marginLeft: 8,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 0,
      },
    },

    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

interface SwitchYethosProps {
  handleChange?: () => void;
}

const SwitchYethos: React.FC<SwitchYethosProps> = ({ handleChange }) => {
  const [checked, setChecked] = React.useState(true);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange?.();
    setChecked(event.target.checked);
  };
  return <SwitchYethosStyled checked={checked} onChange={onChange} inputProps={{ 'aria-label': 'controlled' }} />;
};

export default SwitchYethos;
