export enum ERoutesProfile {
  Profile = 'profile',
  Profile_Details = ':profileId',
}

// TODO find a better name
// this will be used to use on the "navigateTo" function.
export const profileRoutePathnames = {
  Profile: () => '/profile',
  ProfileDetails: (profileId: string) => `/profile/${profileId}`,
};
