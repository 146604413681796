import { Box, Divider } from '@mui/material';

import { Image, Text } from 'common/components';
import { TCommunity } from 'modules/communities/common/types/community.types';

interface CommunityCardAutocompleteProps {
  community: TCommunity;
}

const CommunityCardAutocomplete = ({ community }: CommunityCardAutocompleteProps) => {
  const createdAt = new Date(community.created_at);
  const dateString = createdAt.toLocaleString('pt-br', { dateStyle: 'short' });

  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '80px 1fr',
        gridTemplateRows: '78.45px',
        alignItems: 'center',
        gap: 2,
        cursor: 'pointer',
        '&:active': {
          cursor: 'default',
          border: `1px solid ${theme.palette.info.dark}`,
        },
      })}
    >
      <Box
        className="communityImg"
        height="72px"
        width="80px"
        sx={(theme) => ({
          borderRadius: theme.spacing(1),
          boxShadow: theme.yethosCustoms.shadows.card,
          overflow: 'hidden',
        })}
      >
        <Image src={community.avatar_url ?? ''} />
      </Box>
      <Box alignSelf="flex-start">
        <Text fontWeight={700} variant="subtitle2">
          {community.name}
        </Text>
        <Text
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
          }}
          fontWeight={400}
          variant="subtitle2"
          fontSize={'12px'}
        >
          {community.description}
        </Text>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Text fontWeight={400} variant="subtitle2" fontSize={'12px'}>
            {community.members_count} membros
          </Text>
          <Divider flexItem orientation="vertical" />
          <Text fontWeight={400} variant="subtitle2" fontSize={'12px'}>
            Criada em {dateString}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CommunityCardAutocomplete;
