import React from 'react';
import { useGetInfiniteMembersAPI } from '../communityMembers.api';
import { useParams } from 'react-router-dom';
import { MemberStatusEnum } from 'modules/communities/common/enums/members.enums';
import { Box } from 'common/components';
import CardMember from './CardMember';
import { TMember } from 'modules/communities/common/types/community.types';

const MembersCommunity = () => {
  const { id = '' } = useParams();

  const {
    data: returnMembers,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useGetInfiniteMembersAPI(id, {
    page: 0,
    size: 15,
  });

  const membersPage = returnMembers?.pages ?? [];
  const members = membersPage.reduce<TMember[]>((acc, page) => {
    return [...acc, ...page.items];
  }, []);

  React.useEffect(() => {
    let loading = false;
    const onScroll = async (event: any) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement;
      if (!loading && scrollHeight - scrollTop <= clientHeight * 1.4) {
        loading = true;
        if (hasNextPage) {
          await fetchNextPage();
          loading = false;
        }
      }
    };
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isLoading]);

  return (
    <Box
      display="flex"
      width="100%"
      gap={1}
      flexWrap="wrap"
      justifyContent={{ xs: 'center', sm: 'flex-start' }}
      paddingTop={1}
    >
      {members.map((member) => {
        if (member.status === MemberStatusEnum.ACTIVE) {
          return <CardMember key={`member_tab_${member._id}`} user={member} />;
        } else return null;
      })}
    </Box>
  );
};

export default MembersCommunity;
