import React from 'react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import { Editor } from '@tiptap/react';
import { Button } from 'common/components';

import DialogSearchImage from './DialogSearchImage';
// import { getImgSrc } from 'common/utils/imageResource';

const styleIcon = { width: '20px', height: '20px' };

const MenuBar = ({ editor }: { editor: Editor | null }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!editor) {
    return null;
  }

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const files: FileList | null = event.target.files;
  //   const file = files ? files[0] : null;
  //   const src = file ? getImgSrc(file) : null;
  //   if (src) {
  //     editor.chain().focus().setImage({ src }).run();
  //   }
  // };

  return (
    <>
      <Button
        size="small"
        onClick={() => editor.chain().focus().toggleBold().run()}
        sx={{ bgcolor: editor.isActive('bold') ? 'primary.light' : undefined, minWidth: '36px' }}
        disabled={!editor.can().chain().focus().toggleBold().run()}
      >
        <FormatBoldIcon sx={{ ...styleIcon }} />
      </Button>
      <Button
        sx={{ bgcolor: editor.isActive('italic') ? 'primary.light' : undefined, minWidth: '36px' }}
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
      >
        <FormatItalicIcon sx={{ ...styleIcon }} />
      </Button>
      <Button
        sx={{ bgcolor: editor.isActive('strike') ? 'primary.light' : undefined, minWidth: '36px' }}
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
      >
        <StrikethroughSIcon sx={{ ...styleIcon }} />
      </Button>
      {/* <Button sx={{ minWidth: '36px' }} component="label">
        Image
        <input value="" hidden accept="image/*" type="file" onChange={handleChange} />
      </Button> */}
      <Button onClick={handleClickOpen}>Procurar Imagem</Button>
      <DialogSearchImage editor={editor} handleClose={handleClose} open={open} />
    </>
  );
};

export default MenuBar;
