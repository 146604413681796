import { useMutation, useQuery, useQueryClient } from 'react-query';
import { axiosMock, axiosService } from 'common/services/axiosService';
import { PostProps, UserProps } from 'common/services/mocks/mockData';
import { COMMUNITIES_KEY, POSTS_KEY } from 'common/utils/queryKeys';
import { TFormDataTopic, TTopic } from './topic.types';
import { COMMUNITIES_API_PATH } from 'modules/communities/common';
import { TPaginatedResource } from 'common/types/api.types';
import { HEADER_CONFIG_UPLOAD_FILE } from 'common/utils/api.constants';
import useNotification from 'providers/NotificationProvider';
import { handleFetchMany } from 'common/utils/functions';

type TParams = {
  page?: number;
  size?: number;
  [key: string]: unknown;
};

interface useGetManyPostsProps {
  communityId: string;
  channelId: string;
}
interface useGetPostProps {
  communityId: string;
  channelId: string;
  postId: string;
}

export interface postFormData {
  name: string;
  description: string;
  imgs: File[];
  user: UserProps;
}

async function fetchCreateTopic(formData: TFormDataTopic) {
  const { data } = await axiosService.post<TTopic>(`${COMMUNITIES_API_PATH}/topics`, formData);
  return data;
}

export function useCreateTopicAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchCreateTopic,
    onSuccess: () => {
      queryClient.invalidateQueries(POSTS_KEY._base);
    },
  });
}

async function fetchDeleteTopic(id: string) {
  const { data } = await axiosService.delete<TTopic>(`${COMMUNITIES_API_PATH}/topics/${id}`);

  return data;
}

export function useDeleteTopicAPI() {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  return useMutation({
    mutationFn: fetchDeleteTopic,
    onSettled: () => {
      queryClient.invalidateQueries(POSTS_KEY._base);
      toast({ title: 'Tópico deletado!' });
    },
  });
}

async function fetchUpdateTopicAPI(topicId: string, formData: Pick<TFormDataTopic, 'title' | 'content'>) {
  const { data } = await axiosService.patch<TTopic>(`${COMMUNITIES_API_PATH}/topics/${topicId}`, formData);
  return data;
}

export function useUpdateTopicAPI(topicId: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formData: Pick<TFormDataTopic, 'title' | 'content'>) => fetchUpdateTopicAPI(topicId, formData),
    onSettled: () => {
      queryClient.invalidateQueries(POSTS_KEY._base);
    },
  });
}

export async function fetchTopicByIdAPI(topicId: string) {
  const { data } = await axiosService.get<TTopic>(`${COMMUNITIES_API_PATH}/topics/${topicId}`);
  return data;
}

export function useGetTopicByIdAPI(topicId: string) {
  // const queryClient = useQueryClient();
  return useQuery(POSTS_KEY.details(topicId), () => fetchTopicByIdAPI(topicId), {
    enabled: !!topicId,
  });
}

async function fetchTopicsByCommunityIdAPI(params: TParams) {
  const { data } = await axiosService.get<TPaginatedResource<TTopic>>(`${COMMUNITIES_API_PATH}/topics`, {
    params,
  });
  return data;
}

export function useGetTopicsByCommunityIdAPI(communityId: string, userParams: TParams = { page: 0, size: 15 }) {
  const params = { community: communityId, ...userParams };
  // const queryClient = useQueryClient();
  return useQuery(
    POSTS_KEY.list({ community: communityId }),
    () => handleFetchMany(fetchTopicsByCommunityIdAPI(params)),
    {
      enabled: !!communityId,
    },
  );
}

async function fetchTopicsByChannelIdAPI(params: TParams) {
  const { data } = await axiosService.get<TPaginatedResource<TTopic>>(`${COMMUNITIES_API_PATH}/topics`, {
    params,
  });
  return data;
}

export function useGetTopicsByChannelIdAPI(channelId: string, userParams: TParams = { page: 0, size: 15 }) {
  const params = { channel: channelId, ...userParams };
  // const queryClient = useQueryClient();
  return useQuery(POSTS_KEY.list({ channel: channelId }), () => handleFetchMany(fetchTopicsByChannelIdAPI(params)), {
    enabled: !!channelId,
  });
}

async function fetchUploadImgByTopicId({ topicId, file }: { topicId: string; file: File | null }) {
  const body = {
    file: file,
    ref_id: topicId,
    association: 'posts',
    association_kind: 'image',
  };
  const { data } = await axiosService.post('upload/api/v1/media/images', body, HEADER_CONFIG_UPLOAD_FILE);
  return data;
}

export function useUpdateImgTopic(topicId: string) {
  return useMutation({
    mutationFn: (file: File | null) => fetchUploadImgByTopicId({ topicId, file }),
    onSuccess: () => {},
  });
}

//MOCK DATA

export function useGetManyPosts({ communityId, channelId }: useGetManyPostsProps) {
  const queryClient = useQueryClient();
  async function fetchManyPosts() {
    const response = await axiosMock.get<PostProps[]>(`communities/channel`);
    return response.data;
  }
  return useQuery(POSTS_KEY.list({ community: communityId, channel: channelId }), fetchManyPosts, {
    onSuccess: (response) => {
      response.forEach((item) => {
        queryClient.setQueryData(POSTS_KEY.details(item.id, { community: communityId, channel: channelId }), item);
      });
    },
  });
}

export function useGetPost({ communityId, channelId, postId }: useGetPostProps) {
  async function fetchPost() {
    const response = await axiosService.get<PostProps>(
      `communities/${communityId}/channel/${channelId}/posts/${postId}`,
    );
    return response.data;
  }
  return useQuery(POSTS_KEY.details(postId, { community: communityId }), fetchPost);
}

export function useCreatePost() {
  async function fetchCreatePost({
    formData,
    communityId,
    channelId,
  }: {
    formData: postFormData;
    communityId: string;
    channelId: string;
  }) {
    const newPost = await axiosService.post<PostProps>(`communities/${communityId}/channel/${channelId}/posts`, {
      ...formData,
    });
    return newPost;
  }
  return useMutation({ mutationFn: fetchCreatePost });
}

async function fetchUpdatePost({ id, postId, newData }: { id: string; postId: string; newData: any }) {
  const updatedPost = await axiosService.put<PostProps>(`communities/${id}/channel/:channelId/posts/${postId}`, {
    ...newData,
  });
  return updatedPost;
}

export function useUpdatePost({ communityId }: { communityId: string }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchUpdatePost,
    onSuccess: (res) => {
      const previousDataRaw = queryClient.getQueryData<PostProps[]>([POSTS_KEY._base, {}]);
      const previousData = Array.isArray(previousDataRaw) ? previousDataRaw : [];
      queryClient.setQueryData(
        [POSTS_KEY._base, {}],
        previousData.map((item) => (item.id === res.data.id ? res.data : item)),
      );
      queryClient.setQueryData(POSTS_KEY.details(res.data.id), res.data);
      queryClient.invalidateQueries(POSTS_KEY._base);
      queryClient.invalidateQueries(COMMUNITIES_KEY.details(communityId));
    },
  });
}
