import { Dialog, DialogContent, TextField, DialogTitle, Divider, DialogActions } from '@mui/material';
import { useForm } from 'react-hook-form';

import { Box, Button, Text } from 'common/components';
import { validEmailRegex } from 'common/utils/validation.schemas';
import { useInviteMembersByIdAPI } from 'modules/communities/screens/community/communityMembers.api';
import { useParams } from 'react-router-dom';

type TDialogInviteMembers = {
  open: boolean;
  handleClose: () => void;
};

type TPostEmails = {
  emails: string;
};

function normalizeValueEmails(emails: string) {
  const arrayOfEmails = emails.split(/,|\n/);
  const normalizedArrayEmails = arrayOfEmails.map((item) => item.trim());
  const filteredArray = normalizedArrayEmails.filter((item) => validEmailRegex.test(item));

  return filteredArray;
}

const DialogInviteMembers = ({ open, handleClose }: TDialogInviteMembers) => {
  const { id = '' } = useParams();
  const { mutate: inviteMembers } = useInviteMembersByIdAPI();

  const { register, handleSubmit, reset } = useForm<TPostEmails>({
    defaultValues: {
      emails: '',
    },
  });

  const handleCancel = () => {
    handleClose();
    reset();
  };

  const handleInvite = ({ emails }: TPostEmails) => {
    const normalizeArray = normalizeValueEmails(emails);
    const inviteData = { emails: normalizeArray, community: id };
    inviteMembers(inviteData, {
      onSuccess: () => {
        reset();
      },
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      sx={{
        '.MuiDialog-paper': {
          minHeight: '100px',
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <form onSubmit={handleSubmit(handleInvite)}>
        <DialogTitle sx={{ padding: 2, fontWeight: 700 }} id="invite-members">
          Convidar
        </DialogTitle>
        <Box width="100%" padding="0px 16px">
          <Divider />
        </Box>
        <DialogContent
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '920px',
            maxHeight: '450px',
            gap: 2,
            padding: 2,
          }}
        >
          <Box sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
            <Text variant="h4">Adicione emails para convidar</Text>
            <Text>Separe os emails por vírgula.</Text>
          </Box>
          <Box width={'100%'}>
            <TextField
              size="small"
              fullWidth
              multiline
              maxRows={10}
              placeholder="Adicionar e-mails..."
              autoFocus
              {...register('emails')}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            Convidar
          </Button>
          <Button onClick={handleCancel} variant="outlined">
            Cancelar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogInviteMembers;
