import { QueryClient, QueryClientConfig } from 'react-query';

import { Navigate, createBrowserRouter } from 'app-router';
import ShellProvider from 'ShellProviders';
import { authtenticationRoutes } from 'modules/accounts/screens/authentication';
import { communityRoutes } from 'modules/communities/screens/community';
import { profileRoutes } from 'modules/accounts/screens/profile';
import AppShell from 'modules/shell/AppShell';
import NotFound from 'modules/shell/NotFound.screen';

const DEFAULT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount: number, error: any) => (error.response?.status !== 500 ? false : failureCount <= 2),
    },
  },
};

const queryClient = new QueryClient(DEFAULT_CONFIG);

const router = createBrowserRouter([
  {
    element: <ShellProvider queryClient={queryClient} />,
    children: [
      ...authtenticationRoutes(queryClient),
      {
        element: <AppShell />,
        children: [
          { ...profileRoutes(queryClient) },
          { ...communityRoutes(queryClient) },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <Navigate to="/communities" />,
  },
]);

export default router;
