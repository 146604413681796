import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import { Box, Button, Text } from 'common/components';

import CreateHighlight from './CreateHighlight';
import DeleteHighlight from './DeleteHighlight';
import { JSONContent } from '@tiptap/react';
import EditorReadOnly from 'modules/communities/common/editor/EditorReadOnly';

interface AboutCommunityProps {
  highlight: JSONContent | undefined;
  isAdmin: boolean;
}

const AboutCommunity = ({ highlight, isAdmin }: AboutCommunityProps) => {
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const filteredHighlight = highlight?.content?.filter((it) => it.content?.[0].text) ?? [];
  const isEmpty = filteredHighlight.length === 0;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  return open && isAdmin ? (
    <CreateHighlight highlight={highlight} handleClose={handleClose} />
  ) : isEmpty ? (
    <Box paddingTop={1} display="flex" flexDirection="column" gap={1}>
      <Text fontWeight={400} style={{ wordBreak: 'break-word' }} variant="subtitle2">
        Nenhum destaque
      </Text>
      {isAdmin && (
        <Button onClick={handleOpen} variant="outlined" startIcon={<AddIcon />}>
          Criar Destaque
        </Button>
      )}
    </Box>
  ) : (
    <>
      <DeleteHighlight open={openDelete} handleClose={handleCloseDelete} />
      <Box paddingTop={1} gap={1} display="flex" flexDirection="column" width="100%" alignItems="flex-start">
        {isAdmin && (
          <Box display="flex" gap={2}>
            <Button onClick={handleOpen} variant="outlined" startIcon={<EditIcon />}>
              Editar
            </Button>
            <IconButton onClick={handleOpenDelete} color="error">
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={1}>
          <EditorReadOnly content={highlight} />
        </Box>
      </Box>
    </>
  );
};

export default AboutCommunity;
