import React from 'react';

import { useNavigate } from 'app-router';
import SkeletonCommunityCard from 'assets/skeletons/SkeletonCommunityCard';
import { CommunityCard, Text } from 'common/components';
import { getRoutePathname } from 'routes/getRoutePathname';

import { useGetCommunitiesByManyAPI, useGetCommunitiesByQueryAPI } from '../community.api';
import { TCommunity } from 'modules/communities/common/types/community.types';

interface CommunitiesFilteredProps {
  query: string;
  searchParam: string | undefined;
  hasSearch: boolean;
}

const CommunitiesFiltered: React.FC<CommunitiesFilteredProps> = ({ searchParam, hasSearch, query }) => {
  const navigate = useNavigate();
  const onClickCommunity = (communityId: string) => {
    navigate(getRoutePathname.CommunitiesDetails(communityId));
  };

  const {
    data: returnCommunities,
    isLoading: isLoadingCommunities,
    isFetchingNextPage: isFetchingNextPageCommunities,
    hasNextPage: hasNextPageCommunities,
    fetchNextPage: fetchNextPageCommunities,
  } = useGetCommunitiesByManyAPI({ page: 0, size: 15 }, { enabled: query.length < 1 });

  const {
    data: returnCommunitiesParams,
    isLoading: isLoadingCommunitiesSearch,
    isFetchingNextPage: isFetchingNextPageCommunitiesSearch,
    hasNextPage: hasNextPageCommunitiesSearch,
    fetchNextPage: fetchNextPageCommunitiesSearch,
  } = useGetCommunitiesByQueryAPI(
    {
      q: searchParam,
      page: 0,
      size: 15,
    },
    { keepPreviousData: true, enabled: hasSearch },
  );

  const isLoading = isLoadingCommunities || isLoadingCommunitiesSearch;
  const communitiesPages = returnCommunities?.pages ?? [];
  const communitiesSearchPages = returnCommunitiesParams?.pages ?? [];

  const filteredCommunities = hasSearch ? communitiesSearchPages : communitiesPages;

  const communities = filteredCommunities.reduce<TCommunity[]>((acc, page) => {
    return [...acc, ...page.items];
  }, []);

  const isFetching = isFetchingNextPageCommunities || isFetchingNextPageCommunitiesSearch;
  const hasCommunities = isLoading ? true : communities.length > 0;

  React.useEffect(() => {
    let loading = false;
    const onScroll = async (event: any) => {
      const { scrollHeight, scrollTop, clientHeight } = event.target.scrollingElement;
      if (!loading && scrollHeight - scrollTop <= clientHeight * 1.4) {
        loading = true;
        if (hasSearch && hasNextPageCommunitiesSearch) {
          await fetchNextPageCommunitiesSearch();
          loading = false;
        }
        if (hasNextPageCommunities) {
          await fetchNextPageCommunities();
          loading = false;
        }
      }
    };
    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [isLoading]);

  if (isLoading) {
    return (
      <>
        {Array.from({ length: 15 }, (_i, idx) => (
          <SkeletonCommunityCard key={`skeleton_${idx}`} />
        ))}
      </>
    );
  }
  if (!hasCommunities) {
    return <Text>Não ha comunidades.</Text>;
  }

  return (
    <>
      {communities.map((community) => (
        <CommunityCard
          key={`communities_${community._id}_key`}
          onClickCommunity={() => onClickCommunity(community._id)}
          community={community}
        />
      ))}
      {isFetching && Array.from({ length: 8 }, (_i, idx) => <SkeletonCommunityCard key={`skeleton_${idx}`} />)}
    </>
  );
};

export default CommunitiesFiltered;
