import { useErrorBoundary } from 'react-error-boundary';

import notFound from 'assets/notFound.svg';
import { Box, Button, Text } from 'common/components';

const FallbackErrorBoundary = () => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 5,
        flexWrap: 'wrap',
        padding: 2,
      }}
    >
      <Box display="flex" flexDirection="column" gap={6}>
        <Box>
          <Text fontWeight={700} fontSize="4rem" sx={{ color: '#DD6B20' }}>
            Yethos
          </Text>
        </Box>
        <Box gap={1} display="flex" flexDirection="column">
          <Box display="flex" gap={0.6}>
            <Text fontWeight={700} sx={{ color: '#2D3748' }}>
              404.
            </Text>
            <Text sx={{ color: '#718096' }}>Houve um error.</Text>
          </Box>
          <Text sx={{ color: '#2D3748' }}>Para voltar ao aplicativo clique no botao abaixo.</Text>
          <Button onClick={resetBoundary} variant="contained">
            Ir para Yethos
          </Button>
        </Box>
      </Box>
      <img src={notFound} />
    </Box>
  );
};

export default FallbackErrorBoundary;
