import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { FormGroup, FormLabel, MenuItem, Stack, TextField, useMediaQuery, useTheme } from '@mui/material';

import { DAYS, MONTHS, YEARS } from 'common/utils/date.constants';

const BirthDateSelect = () => {
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext();

  const day = watch('day_of_birth');
  const month = watch('month_of_birth');
  const year = watch('year_of_birth');
  return (
    <FormGroup>
      <FormLabel sx={(theme) => ({ color: theme.palette.secondary.main })}>Data de nascimento</FormLabel>
      <Stack marginTop={1} direction="row" spacing={{ xs: 1, sm: 2 }}>
        <TextField
          error={!!`${get(errors, 'day_of_birth.message', '')}`}
          size="small"
          select
          //use default value to eliminate DOM warning.
          value={day}
          label="Dia"
          fullWidth={matchMobile ? false : true}
          sx={{ minWidth: '70px' }}
          SelectProps={{
            native: matchMobile ? true : false,
          }}
          id="day-dob"
          {...register('day_of_birth')}
        >
          {DAYS.map((d) => {
            if (matchMobile)
              return (
                <option key={`day-${d.id}-mobile`} id={`day-${d.id}-mobile`} value={d.id}>
                  {d.label}
                </option>
              );
            else
              return (
                <MenuItem key={`day-${d.id}`} id={`day-${d.id}`} value={d.id}>
                  {d.label}
                </MenuItem>
              );
          })}
        </TextField>
        <TextField
          size="small"
          error={!!`${get(errors, 'month_of_birth.message', '')}`}
          select
          //use default value to eliminate DOM warning.
          value={month}
          label="Mês"
          fullWidth={matchMobile ? false : true}
          sx={{ minWidth: '120px' }}
          SelectProps={{
            native: matchMobile ? true : false,
          }}
          id="month-dob"
          placeholder="Mês"
          {...register('month_of_birth')}
        >
          {MONTHS.map((m) => {
            if (matchMobile)
              return (
                <option key={`month-${m.id}-mobile`} id={`month-${m.id}-mobile`} value={m.id}>
                  {m.label}
                </option>
              );
            else
              return (
                <MenuItem key={`month-${m.id}`} id={`month-${m.id}`} value={m.id}>
                  {m.label}
                </MenuItem>
              );
          })}
        </TextField>
        <TextField
          fullWidth
          SelectProps={{
            native: matchMobile ? true : false,
          }}
          select
          //use default value to eliminate DOM warning.
          value={year}
          error={!!`${get(errors, 'year_of_birth.message', '')}`}
          label="Ano"
          size="small"
          id="year-dob"
          placeholder="Ano"
          {...register('year_of_birth')}
        >
          {YEARS.map((y) => {
            if (matchMobile)
              return (
                <option key={`year-${y.id}-mobile`} id={`year-${y.id}-mobile`} value={y.id}>
                  {y.label}
                </option>
              );
            else
              return (
                <MenuItem key={`year-${y.id}`} id={`year-${y.id}`} value={y.id}>
                  {y.label}
                </MenuItem>
              );
          })}
        </TextField>
      </Stack>
    </FormGroup>
  );
};

export default BirthDateSelect;
