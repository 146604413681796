import { useEditor, EditorContent, JSONContent } from '@tiptap/react';
import Image from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';

import { Box } from 'common/components';

import './readonly.css';

type TEditorReadOnly = {
  content?: JSONContent;
};

const EditorReadOnly = ({ content }: TEditorReadOnly) => {
  const editor = useEditor({
    editable: false,
    extensions: [
      StarterKit,
      Image.configure({
        HTMLAttributes: {
          class: 'image',
        },
      }),
    ],
    content: content,
    editorProps: {
      attributes: {
        class: 'readonly',
      },
    },
  });

  return (
    <Box padding={{ xs: '0px', sm: '0px 32px' }}>
      <EditorContent editor={editor} />
    </Box>
  );
};

export default EditorReadOnly;
