import App from 'modules/shell/App';

import { APIClientProvider, ThemeProviderMui } from 'providers';
import { APIClientProviderProps } from 'providers/APIClientProvider';
import { AuthProvider } from 'providers/AuthProvider';
import { NotificationProvider } from 'providers/NotificationProvider';

const ShellProvider = ({
  queryClientConfig,
  queryClient,
}: Pick<APIClientProviderProps, 'queryClientConfig' | 'queryClient'>) => {
  return (
    <APIClientProvider queryClient={queryClient} queryClientConfig={queryClientConfig}>
      <NotificationProvider>
        <AuthProvider>
          <ThemeProviderMui>
            <App />
          </ThemeProviderMui>
        </AuthProvider>
      </NotificationProvider>
    </APIClientProvider>
  );
};

export default ShellProvider;
