import * as React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Button } from 'common/components';

interface TSelectFilter {
  filters: {
    label: string;
    value: string;
  }[];
  filtered: {
    label: string;
    value: string;
  };
  size?: 'large' | 'medium' | 'small';
  minWidth?: string;
  variant?: 'text' | 'outlined' | 'contained';
  noFilterLabel?: string;

  handleChangeFilter: (value: { label: string; value: string }) => void;
}

const SelectFilter = ({
  filters,
  filtered,
  handleChangeFilter,
  size = 'small',
  minWidth = '140px',
  variant = 'text',
  noFilterLabel,
}: TSelectFilter) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectFilter = (value: typeof filtered) => {
    setAnchorEl(null);
    handleChangeFilter(value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const emptyLabel = noFilterLabel ? noFilterLabel : filtered.label;

  return (
    <div>
      <Button
        variant={variant}
        disableTouchRipple
        size={size}
        sx={{
          minWidth: minWidth,
          '&:hover': {
            backgroundColor: 'transparent',
          },
          height: '32px',
        }}
        id="basic-button"
        endIcon={<KeyboardArrowDownIcon fontWeight={400} />}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {filtered.value === '' ? emptyLabel : filtered.label}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {filters.map((filter) => (
          <MenuItem key={`_key_${filter.value}`} onClick={() => handleSelectFilter(filter)}>
            {filter.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default SelectFilter;
