import { useMediaQuery, useTheme } from '@mui/material';

import { Box, Card, Text } from 'common/components';
import { communitiesMockedData } from 'common/services/mocks/mockData';
import { orderTopicsByRecently } from 'common/utils/functions';
import TopicCard from 'modules/communities/common/components/TopicCard';

import RecommendedCommunity from './RecommendedCommunity';
import { useGetTopicsByCommunityIdAPI } from '../../posts/post.api';
import { useParams } from 'react-router-dom';

const WallCommunity = () => {
  const { id = '' } = useParams();
  const { data, isLoading: isLoadingTopics } = useGetTopicsByCommunityIdAPI(id);
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const posts = data?.items ?? [];
  const orderedPostsByRecently = orderTopicsByRecently(posts);

  const handleClickChannel = () => {};

  if (isLoadingTopics) {
    return null;
  }

  return orderedPostsByRecently.length > 0 ? (
    <Box display="flex" gap={2} width="100%" paddingTop={1}>
      <Box display="flex" flex={2} gap={1} width="100%" flexDirection="column" alignItems="center">
        {!matchMobile && (
          <Card
            width="100%"
            sx={(theme) => ({
              [theme.breakpoints.up('md')]: { width: '100%', maxWidth: '1080px' },
              padding: 0,
              gap: 0,
            })}
          >
            {orderedPostsByRecently.map((post, index) => (
              <Box
                width="100%"
                key={`post_mural_${post._id}`}
                sx={(theme) => ({
                  borderBottom:
                    index < orderedPostsByRecently.length - 1 ? `1px solid ${theme.palette.primary.light}` : undefined,
                  borderRadius: 0,
                })}
              >
                <TopicCard
                  channelId={post._id}
                  channelInfo={
                    <Box onClick={handleClickChannel} sx={{ cursor: 'pointer' }}>
                      <Text fontWeight={600} fontSize="13px" sx={{ lineHeight: 1 }}>
                        {communitiesMockedData[0].channels[0].name}
                      </Text>
                    </Box>
                  }
                  post={post}
                />
              </Box>
            ))}
          </Card>
        )}
        {matchMobile &&
          orderedPostsByRecently.map((post, index) => (
            <Card
              width="100%"
              key={`post_mural_${post._id}_${index}`}
              sx={() => ({
                padding: 0,
                gap: 0,
              })}
            >
              <TopicCard
                channelId={post._id}
                channelInfo={
                  <Box onClick={handleClickChannel} sx={{ cursor: 'pointer' }}>
                    <Text fontWeight={600} fontSize="13px" sx={{ lineHeight: 1 }}>
                      {communitiesMockedData[0].channels[0].name}
                    </Text>
                  </Box>
                }
                post={post}
              />
            </Card>
          ))}
      </Box>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: { display: 'none' },
          borderRight: '1px solid ',
          borderColor: 'primary.light',
        })}
      />
      <Box paddingTop={3} sx={(theme) => ({ [theme.breakpoints.down('md')]: { display: 'none' } })}>
        <RecommendedCommunity />
      </Box>
    </Box>
  ) : (
    <Box paddingTop={1}>
      <Text>Não há tópicos nesta comunidade.</Text>
    </Box>
  );
};

export default WallCommunity;
