import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';

import { Button, Box, LoadingButton, Text } from 'common/components';
import { DescriptionInputCommunity, NameInputChannel } from 'modules/communities/common';
import { TChannel } from 'modules/communities/common/types/channel.types';

import { useUpdateChannelByIdAPI } from '../channel.api';
import DeleteChannel from './DeleteChannel';
import { EditChannelSchema, TEditChannelForm } from '../channel.schemas';

export const MAX_CHARACTERS = 90;

type TSettingsTabChannel = {
  channel: TChannel;
};

const SettingsTabChannel = ({ channel }: TSettingsTabChannel) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const { mutate: updateChannel, isLoading: isLoadingUpdateChannel } = useUpdateChannelByIdAPI(channel._id);
  const formMethods = useForm<TEditChannelForm>({
    resolver: zodResolver(EditChannelSchema),
    defaultValues: {
      description: channel.description,
      name: channel.name,
    },
  });

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { handleSubmit, reset } = formMethods;
  const onSubmit: SubmitHandler<TEditChannelForm> = (values) => {
    if (values.name === channel.name && values.description === channel.description) {
      return null;
    }
    updateChannel(
      { update: values },
      {
        onSuccess: () => {
          reset({ name: values.name, description: values.description });
        },
      },
    );
  };

  const handleCancel = () => {
    reset();
  };

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        maxWidth="1080px"
        gap={2}
        marginTop={1}
      >
        <Box display="flex" flexDirection="column" gap={2} width="100%">
          <NameInputChannel />
          <DescriptionInputCommunity maxCharacteres={90} />
          <Box display="flex" gap={1} alignItems="center">
            <Text variant="h5">Deletar canal</Text>
            <IconButton onClick={handleOpenDialog}>
              <DeleteIcon color="error" />
            </IconButton>
          </Box>
        </Box>
        <DeleteChannel handleClose={handleCloseDialog} open={openDialog} />
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            justifyContent: 'center',
          }}
        >
          <Button variant="outlined" onClick={handleCancel}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoadingUpdateChannel}
            disabled={isLoadingUpdateChannel}
            variant="contained"
            type="submit"
          >
            Salvar
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default SettingsTabChannel;
