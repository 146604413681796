import { ListItemText } from '@mui/material';

import { MemberRoleEnum, MemberStatusEnum } from 'modules/communities/common/enums/members.enums';
import { TMember } from 'modules/communities/common/types/community.types';
import { MoreIconMenu } from 'common/components';

import { TUpdateMemberData, useUpdateMemberByMemberIdAPI } from '../communityMembers.api';
import useCommunityPermissions from 'modules/communities/common/utils/useCommunityPermissions';

type TOptionsMoreMenu = {
  member: TMember;
  meMember: TMember | null;
  founderId: string;
};

const MoreIconMenuMember = ({ member, meMember, founderId }: TOptionsMoreMenu) => {
  const { memberActionsToMembers } = useCommunityPermissions();
  const { community, _id, role, status, profile } = member;
  const { mutate: updateMember, isLoading: isLoadingUpdate } = useUpdateMemberByMemberIdAPI(community);
  const isFounder = founderId === profile._id;

  const handleUpdateMember = ({ id, update }: TUpdateMemberData) => {
    updateMember({ id, update });
  };

  const hasPermission = ({
    options,
  }: {
    options: {
      label: JSX.Element;
      onClick: () => void;
      shouldRender: boolean;
    }[];
  }) => {
    return options.filter((it) => !!it.shouldRender);
  };

  const options = [
    {
      label: <ListItemText>Desbanir membro</ListItemText>,
      onClick: () => handleUpdateMember({ id: _id, update: { status: MemberStatusEnum.ACTIVE } }),
      shouldRender: memberActionsToMembers.canUnbanMember(meMember) && status === MemberStatusEnum.BANNED,
    },
    {
      label: <ListItemText>Promover a administrador</ListItemText>,
      onClick: () => handleUpdateMember({ id: _id, update: { role: MemberRoleEnum.ADMIN } }),
      shouldRender:
        memberActionsToMembers.canPromoveAdmin(meMember, founderId) &&
        role !== MemberRoleEnum.ADMIN &&
        status !== MemberStatusEnum.BANNED,
    },
    {
      label: <ListItemText>Promover a moderador</ListItemText>,
      onClick: () => handleUpdateMember({ id: _id, update: { role: MemberRoleEnum.MODERATOR } }),
      shouldRender:
        memberActionsToMembers.canPromoveModerator(meMember) &&
        role === MemberRoleEnum.MEMBER &&
        status !== MemberStatusEnum.BANNED,
    },
    {
      label: <ListItemText>Rebaixar a moderador</ListItemText>,
      onClick: () => handleUpdateMember({ id: _id, update: { role: MemberRoleEnum.MODERATOR } }),
      shouldRender:
        memberActionsToMembers.canDowngradeAdmin(meMember, founderId) &&
        role === MemberRoleEnum.ADMIN &&
        status !== MemberStatusEnum.BANNED,
    },
    {
      label: <ListItemText>Rebaixar a membro</ListItemText>,
      onClick: () => handleUpdateMember({ id: _id, update: { role: MemberRoleEnum.MEMBER } }),
      shouldRender:
        memberActionsToMembers.canDowngradeModerator(meMember) &&
        !isFounder &&
        (role === MemberRoleEnum.ADMIN || role === MemberRoleEnum.MODERATOR) &&
        status !== MemberStatusEnum.BANNED,
    },
    {
      label: <ListItemText>Remover</ListItemText>,
      onClick: () =>
        handleUpdateMember({ id: _id, update: { status: MemberStatusEnum.INACTIVE, role: MemberRoleEnum.MEMBER } }),
      shouldRender:
        memberActionsToMembers.canRemoveMember(meMember) && !isFounder && status !== MemberStatusEnum.BANNED,
    },
    {
      label: <ListItemText sx={{ color: 'error.main' }}>Banir</ListItemText>,
      onClick: () =>
        handleUpdateMember({ id: _id, update: { status: MemberStatusEnum.BANNED, role: MemberRoleEnum.MEMBER } }),
      shouldRender: memberActionsToMembers.canBanMember(meMember) && !isFounder && status !== MemberStatusEnum.BANNED,
    },
  ];

  const allowedOptions = hasPermission({ options });

  return <MoreIconMenu isLoading={isLoadingUpdate} variant="outlined" size="xs" options={allowedOptions} />;
};

export default MoreIconMenuMember;
