import React from 'react';

interface ImageProps {
  src: string | undefined;
}

const Image: React.FC<ImageProps> = ({ src }) => {
  return <img style={{ objectFit: 'cover' }} src={src} alt="bg profile" height="100%" width="100%" />;
};

export default Image;
