import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import AddIcon from '@mui/icons-material/Add';

import NavCommunityButton from './NavCommunityButton';
import { Tooltip } from '@mui/material';
import useGetDefaultsNavigate from 'common/utils/useGetDefaultsNavigate';

const NavDefaultLinks = () => {
  const { handleClickHome, handleClickBookmarks, handleClickCreateCommunity, handleClickMyCommunities } =
    useGetDefaultsNavigate();
  return (
    <>
      <Tooltip title="Início" placement="right">
        <NavCommunityButton sx={{ width: 'fit-content' }} onClick={handleClickHome} colorIcon="secondary" size="medium">
          <HomeOutlinedIcon />
        </NavCommunityButton>
      </Tooltip>
      <Tooltip title="Minhas comunidades" placement="right">
        <NavCommunityButton
          sx={{ width: 'fit-content' }}
          onClick={handleClickMyCommunities}
          colorIcon="secondary"
          size="medium"
        >
          <GroupsIcon />
        </NavCommunityButton>
      </Tooltip>
      <Tooltip title="Salvos" placement="right">
        <NavCommunityButton
          sx={{ width: 'fit-content' }}
          onClick={handleClickBookmarks}
          colorIcon="secondary"
          size="medium"
        >
          <BookmarksOutlinedIcon />
        </NavCommunityButton>
      </Tooltip>
      <Tooltip title="Criar comunidade" placement="right">
        <NavCommunityButton
          sx={{ width: 'fit-content' }}
          onClick={handleClickCreateCommunity}
          size="medium"
          colorIcon="secondary"
        >
          <AddIcon />
        </NavCommunityButton>
      </Tooltip>
    </>
  );
};

export default NavDefaultLinks;
