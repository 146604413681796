import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import LogoLoading from 'assets/logo/LogoLoading';
import useAuthContext from 'providers/AuthProvider';

import FallbackErrorBoundary from './FallbackErrorBoundary';

const App = () => {
  const { authLoading } = useAuthContext();

  return authLoading ? (
    <LogoLoading />
  ) : (
    <ErrorBoundary FallbackComponent={FallbackErrorBoundary}>
      <React.Suspense fallback={<LogoLoading />}>
        <Outlet />
      </React.Suspense>
    </ErrorBoundary>
  );
};

export default App;
