export enum MemberStatusEnum {
  ACTIVE = 'active',
  BANNED = 'banned',
  INACTIVE = 'inactive',
  SUSPENDED = 'suspended',
}

export enum MemberRoleEnum {
  ADMIN = 'admin',
  MODERATOR = 'moderator',
  MEMBER = 'member',
}
