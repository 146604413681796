import { styled } from '@mui/material/styles';
import { Typography, TypographyProps } from '@mui/material';

interface TextProps extends TypographyProps {
  component?: string;
}

const Text = styled(Typography, {
  shouldForwardProp: (props) => props !== 'gradient',
})<TextProps>(({ theme, variant }) => ({
  color: theme.palette.secondary.main,
  ...(variant === 'caption2' && {
    color: theme.palette.info.dark,
  }),
  ...(variant === 'caption' && {
    color: theme.palette.info.dark,
  }),
}));

export default Text;
