import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { trim } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { Box, LoadingButton, Text } from 'common/components';
import { TTag } from 'modules/communities/common/types/community.types';

type TTagInputAutocomplete = {
  tagsDefault: TTag[];
  tagsOption: TTag[];
  category: string;
  maxTags: number;
  limitTags: boolean;
  loadingNewTag: boolean;
  loadingTags: boolean;
  handleCreateTag: (tag: string, fun: (res: TTag) => void) => void;
};

const TagInputAutocomplete = ({
  tagsDefault = [],
  tagsOption,
  category,
  maxTags,
  limitTags,
  loadingNewTag,
  loadingTags,
  handleCreateTag,
}: TTagInputAutocomplete) => {
  const [selectedTag, setSelectedTag] = React.useState(tagsDefault);
  const [input, setInput] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const { setValue } = useFormContext();

  const hasCategory = !!category;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetInput = (_event: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
    setInput(newInputValue);
    setOpen(!!newInputValue ? true : false);
  };

  const selectedTagsAutocomplete = (tags: TTag[] | null) => {
    if (!!tags) {
      setValue('tags', tags);
      setSelectedTag(tags);
      return handleClose();
    }
    handleClose();
    setSelectedTag([]);
    return setValue('tags', undefined);
  };

  const addTagsByKeyUp = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const replaceCommaValue = input.replace(/,/g, '');
    const normalizeValueSpace = trim(replaceCommaValue);
    const callAction = event.code === 'Space' || event.code === 'Comma';
    const checkTagValue = normalizeValueSpace !== '';
    if (callAction && checkTagValue && !limitTags) {
      const selectTag = tagsOption.find((tag) => tag.label.includes(normalizeValueSpace)) ?? null;
      const tagAlreadyExist = selectTag ? selectedTag.find((tag) => tag._id === selectTag._id) : null;
      if (!!selectTag && !!tagAlreadyExist) {
        const indexTag = selectedTag.indexOf(tagAlreadyExist);
        const removedTags = selectedTag.filter((_, index) => index !== indexTag);
        selectedTagsAutocomplete(removedTags);
        setInput('');
        return;
      }
      if (!!selectTag) {
        const recentTags = !!selectedTag ? [...selectedTag, selectTag] : [selectTag];
        selectedTagsAutocomplete(recentTags);
        setInput('');
        return;
      } else {
        handleCreateTag(normalizeValueSpace, (res: TTag) => {
          selectedTagsAutocomplete([...selectedTag, res]);
        });
      }
    }
  };

  React.useEffect(() => {
    setSelectedTag([]);
    selectedTagsAutocomplete([]);
  }, [category]);

  return (
    <Autocomplete
      id="multiple-limit-tags-by-category"
      size="small"
      fullWidth
      sx={{ bgcolor: 'white', mt: '8px' }}
      multiple
      limitTags={maxTags}
      disabled={!hasCategory}
      open={hasCategory ? open : false}
      onOpen={handleOpen}
      onFocus={handleOpen}
      onClose={handleClose}
      options={tagsOption}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      inputValue={input}
      onInputChange={handleSetInput}
      value={selectedTag}
      onChange={(_event, newValue: TTag[] | null) => {
        selectedTagsAutocomplete(newValue);
      }}
      noOptionsText={
        <Box display="flex" flexDirection="column" gap={1}>
          {loadingTags ? (
            <Text variant="caption">Carregando...</Text>
          ) : (
            <>
              <Text variant="caption">
                {!!input
                  ? 'Seja o primeiro a utilizar esta tag.'
                  : 'Essa categoria ainda não possui tags, seja o primeiro a criar.'}
              </Text>
              {!!input && (
                <LoadingButton
                  loading={loadingNewTag}
                  onClick={() =>
                    handleCreateTag(input, (res: TTag) => {
                      selectedTagsAutocomplete([...tagsDefault, res]);
                    })
                  }
                  sx={{ justifyContent: 'flex-start', fontWeight: 400 }}
                >
                  Adicionar tag {input}
                </LoadingButton>
              )}
            </>
          )}
        </Box>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params.inputProps, onKeyUp: addTagsByKeyUp }}
          placeholder={hasCategory ? 'Tags' : 'Precisa escolher uma categoria'}
        />
      )}
    />
  );
};

export default TagInputAutocomplete;
