import axios from 'axios';

export function getApiBasePathLocal() {
  return 'https://api.yethos.io';
}

export function getApiBasePathProd() {
  return 'https://api.yethos.io';
}
export function getApiMockData() {
  return 'https://ap.yethos.io';
}

export function getApiBasePath() {
  return process.env.NODE_ENV === 'production' ? getApiBasePathProd() : getApiBasePathLocal();
}

const axiosService = axios.create({
  baseURL: getApiBasePath(),
  withCredentials: true,
});
const axiosMock = axios.create({
  baseURL: getApiMockData(),
  withCredentials: true,
});

export { axiosService, axiosMock };
