import { get } from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import { FormControl, FormLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';

import { Box, Text } from 'common/components';
import { categoriesArr } from 'common/utils/filters.community.constants';

const CategoryInput = () => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const categoryDefault: string = useWatch({ control, name: 'category' });

  return (
    <FormControl fullWidth>
      <Box display="flex" gap={1}>
        <FormLabel
          required
          sx={(theme) => ({
            color: theme.palette.secondary.main,
            fontWeight: 500,
            '&.Mui-required': { color: 'error.main' },
            display: 'flex',
            gap: 1,
          })}
          htmlFor="category"
        >
          <Text sx={{ fontWeight: 500 }}>Categoria</Text>
        </FormLabel>
        <Box>
          <Tooltip arrow title="Escolha uma das categorias que se encaixa na temática da sua comunidade.">
            <InfoIcon sx={{ height: '16px', width: '16px', color: 'secondary.main' }} />
          </Tooltip>
        </Box>
      </Box>
      <Select
        sx={{ marginTop: 1, backgroundColor: 'white' }}
        size="small"
        displayEmpty
        id="category"
        error={!!`${get(errors, 'category.message', '')}`}
        value={categoryDefault ?? ''}
        MenuProps={{ PaperProps: { sx: { maxHeight: { xs: '220px', sm: '300px' } } } }}
        {...register('category')}
        onChange={(e) => {
          register('category').onChange(e);
        }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Text sx={{ color: 'rgba(0, 0, 0, 0.4)' }}>Selecione</Text>;
          }
          const selectedCategory = categoriesArr.find((item) => item.value === selected);
          return selectedCategory?.label;
        }}
      >
        <MenuItem sx={{ color: 'info.dark' }} disabled value="">
          <em>Selecione</em>
        </MenuItem>
        {categoriesArr.map((category, idx) => {
          if (idx === 0) return null;
          return (
            <MenuItem key={`category_menu_input_${category.label}`} value={category.value}>
              {category.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CategoryInput;
