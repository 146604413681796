import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { omit } from 'lodash';

import { COMMUNITIES_KEY, TAGS_KEY, USERS_KEY } from 'common/utils/queryKeys';
import { axiosService } from 'common/services/axiosService';
import { CommunityPropsMOCK } from 'common/services/mocks/mockData';
import { HEADER_CONFIG_UPLOAD_FILE } from 'common/utils/api.constants';
import { TPaginatedResource } from 'common/types/api.types';
import {
  TCommunity,
  TCreateCommunityRequest,
  TTag,
  TTagCreate,
} from 'modules/communities/common/types/community.types';
import { COMMUNITIES_API_PATH } from 'modules/communities/common/constants';
import useNotification from 'providers/NotificationProvider';
import { handleFetchMany } from 'common/utils/functions';

type TParams = {
  page?: number;
  size?: number;
  [key: string]: unknown;
};

//TAGS
async function fetchCreateTagByCategory(tag: TTagCreate) {
  const { data } = await axiosService.post<TTag>(`${COMMUNITIES_API_PATH}/tags`, tag);
  return data;
}

export function useCreateTagByCategoryAPI() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchCreateTagByCategory,
    onSuccess: () => {
      queryClient.invalidateQueries(TAGS_KEY._base);
    },
  });
}

async function fetchTagsByCategoryAPI(params: TParams = {}) {
  const { data } = await axiosService.get<TPaginatedResource<TTag>>(`${COMMUNITIES_API_PATH}/tags`, { params });
  return data;
}

export function useGetTagsByCategoryAPI(
  params: TParams = {},
  options?: Omit<
    UseQueryOptions<TPaginatedResource<TTag>, unknown, TPaginatedResource<TTag>, (string | TParams)[]>,
    'staleTime'
  >,
) {
  return useQuery(TAGS_KEY.list(params), () => handleFetchMany(fetchTagsByCategoryAPI(params)), {
    staleTime: 3000,
    ...(options ? omit(options, 'onSuccess') : {}),
  });
}

//COMMUNITIES

export async function fetchCommunitiesByMany({ userParams, pageParam }: { userParams: TParams; pageParam?: number }) {
  const params = { ...userParams, page: pageParam ?? userParams.page };
  const { data } = await axiosService.get<TPaginatedResource<TCommunity>>(`${COMMUNITIES_API_PATH}/communities`, {
    params,
  });
  return data;
}

export function useGetCommunitiesByManyAPI(
  userParams: TParams = { page: 0, size: 15 },
  options?: Omit<
    UseInfiniteQueryOptions<
      TPaginatedResource<TCommunity>,
      unknown,
      TPaginatedResource<TCommunity>,
      TPaginatedResource<TCommunity>,
      (string | TParams)[]
    >,
    'staleTime'
  >,
) {
  return useInfiniteQuery(
    COMMUNITIES_KEY.list({ infinite: true }),
    ({ pageParam }) => fetchCommunitiesByMany({ pageParam, userParams }),
    {
      getNextPageParam: (lastPage) => {
        const items = (lastPage.page + 1) * lastPage.size;
        if (lastPage.totalItems - items >= 1) {
          const nextPage = lastPage.page + 1;
          return nextPage;
        } else return undefined;
      },

      staleTime: 1500,
      ...(options ? omit(options, 'onSuccess') : {}),
    },
  );
}

export function useGetCommunitiesByQueryAPI(
  userParams: TParams = { page: 0, size: 15 },
  options?: Omit<
    UseInfiniteQueryOptions<
      TPaginatedResource<TCommunity>,
      unknown,
      TPaginatedResource<TCommunity>,
      TPaginatedResource<TCommunity>,
      (string | TParams)[]
    >,
    'staleTime'
  >,
) {
  async function fetchCommunitiesByQuery({ pageParam = userParams.page }) {
    const params = { ...userParams, page: pageParam };
    const { data } = await axiosService.get<TPaginatedResource<TCommunity>>(
      `${COMMUNITIES_API_PATH}/communities/search`,
      { params },
    );
    return data;
  }
  return useInfiniteQuery(
    COMMUNITIES_KEY.list({ search: userParams.q, infinite: true }),
    (args) => handleFetchMany(fetchCommunitiesByQuery(args)),
    {
      getNextPageParam: (lastPage) => {
        const items = (lastPage.page + 1) * lastPage.size;
        if (lastPage.totalItems - items >= 1) {
          const nextPage = lastPage.page + 1;
          return nextPage;
        } else return undefined;
      },
      staleTime: 3000,
      ...(options ? omit(options, 'onSuccess') : {}),
    },
  );
}

export async function fetchCommunityById(id: string) {
  const { data } = await axiosService.get<TCommunity>(`${COMMUNITIES_API_PATH}/communities/${id}`);
  return data;
}

export function useGetCommunityByIdAPI(id: string) {
  return useQuery(COMMUNITIES_KEY.details(id), () => fetchCommunityById(id), {
    enabled: !!id,
  });
}

async function fetchUploadImgById({
  id,
  file,
  associationKind,
}: {
  id: string;
  file: File | null;
  associationKind: 'avatar' | 'banner';
}) {
  const body = {
    file: file,
    ref_id: id,
    association: 'communities',
    association_kind: associationKind,
  };
  const { data } = await axiosService.post('upload/api/v1/media/images', body, HEADER_CONFIG_UPLOAD_FILE);
  return data;
}

export function useUpdateCommunityAvatarByIdAPI(id: string) {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  return useMutation({
    mutationFn: (file: File | null) => fetchUploadImgById({ id, file, associationKind: 'avatar' }),
    onSuccess: (res) => {
      const community: TCommunity | undefined = queryClient.getQueryData(COMMUNITIES_KEY.details(id));
      const avatar_url = res.image.url;
      const newCommunity = { ...community, avatar_url };
      toast({ title: 'Avatar atualizado.' });
      queryClient.setQueryData(COMMUNITIES_KEY.details(id), newCommunity);
    },
  });
}
export function useUpdateCommunityBannerByIdAPI(id: string) {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  return useMutation({
    mutationFn: (file: File | null) => fetchUploadImgById({ id, file, associationKind: 'banner' }),
    onSuccess: (res) => {
      const community: TCommunity | undefined = queryClient.getQueryData(COMMUNITIES_KEY.details(id));
      const banner_url = res.image.url;
      const newCommunity = { ...community, banner_url };
      toast({ title: 'Banner atualizado.' });
      queryClient.setQueryData(COMMUNITIES_KEY.details(id), newCommunity);
    },
  });
}

async function fetchCreateCommunity(formData: TCreateCommunityRequest) {
  const { data } = await axiosService.post<TCommunity>(`${COMMUNITIES_API_PATH}/communities`, formData);
  return data;
}

export function useCreateCommunity() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchCreateCommunity,
    onSuccess: () => {
      queryClient.invalidateQueries([USERS_KEY._base, 'me']);
    },
  });
}

async function fetchDeleteCommunityById({ id }: { id: string }) {
  const { data } = await axiosService.delete(`${COMMUNITIES_API_PATH}/communities/${id}`);
  return data;
}

export function useDeleteCommunityByIdAPI() {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  return useMutation({
    mutationFn: fetchDeleteCommunityById,
    onSuccess: () => {
      queryClient.invalidateQueries([USERS_KEY._base, 'me']);
      queryClient.invalidateQueries(COMMUNITIES_KEY.list());
      toast({ title: 'Comunidade deletada.' });
    },
  });
}
async function fetchUpdateCommunityById({ id, update }: { id: string; update: any }) {
  const { data } = await axiosService.patch(`${COMMUNITIES_API_PATH}/communities/${id}`, update);
  return data;
}

export function useUpdateCommunityByIdAPI(communityId: string) {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  return useMutation({
    mutationFn: ({ update }: { update: any }) => fetchUpdateCommunityById({ id: communityId, update }),
    onSuccess: () => {
      toast({ title: 'Configurações atualizadas.' });
      queryClient.invalidateQueries([USERS_KEY._base, 'me']);
      queryClient.invalidateQueries(COMMUNITIES_KEY.list());
      queryClient.invalidateQueries(COMMUNITIES_KEY.details(communityId));
    },
  });
}

async function fetchCommunitiesByManyMOCK(params: TParams = {}) {
  const response = await axiosService.get<CommunityPropsMOCK[]>('communities', { params });
  return response.data;
}

export function useGetCommunitiesByManyMOCK(
  params: TParams = {},
  options?: Omit<
    UseQueryOptions<CommunityPropsMOCK[], unknown, CommunityPropsMOCK[], (string | TParams)[]>,
    'staleTime'
  >,
) {
  return useQuery(COMMUNITIES_KEY.list({ mock: 'mockdata' }), () => fetchCommunitiesByManyMOCK(params), {
    onSuccess: (response) => {
      options?.onSuccess?.(response);
    },
    staleTime: 3000,
    ...(options ? omit(options, 'onSuccess') : {}),
  });
}

async function fetchCreateHighlight({ formData, id }: { formData: any; id: string }) {
  const updatedCommunity = await axiosService.post<any>(`communities/${id}/highlight`, { ...formData });
  return updatedCommunity;
}

export function useCreateHighlight() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: fetchCreateHighlight,
    onSuccess: ({ data: { newCommunity } }: any) => {
      const previousDataRaw = queryClient.getQueryData([COMMUNITIES_KEY._base, {}]);
      const previousData = Array.isArray(previousDataRaw) ? previousDataRaw : [];
      queryClient.setQueryData(
        [COMMUNITIES_KEY._base, {}],
        previousData.map((item) => (item.id === newCommunity.id ? newCommunity : item)),
      );
      queryClient.setQueryData([COMMUNITIES_KEY._base, { id: newCommunity.id }], newCommunity);
      queryClient.setQueryData(COMMUNITIES_KEY.details(newCommunity.id), newCommunity);
    },
  });
}

// get('/communities') => {id: '', userAdmin: '', member: ''}[] ---- post('communities') -> {id: '', userAdmin: '', member: ''}
// get('/communities', {params: {_populate: 'userAdmin'}}) => {id: '', userAdmin: {}, member: ''}
