import { useSearchParams } from 'react-router-dom';
import { Box, Divider, Stack } from '@mui/material';

import { Text } from 'common/components';

import { NewPasswordForm, ForgotPasswordEmailForm } from './components';

const ForgotPassword = () => {
  const [searchParams] = useSearchParams();

  const hasPasswordToken = searchParams.get('token');

  return (
    <Stack spacing={2}>
      <Box sx={{ textAlign: 'center' }}>
        <Text variant="h1" fontSize={{ xs: '1.25rem', sm: '2.25rem' }}>
          Esqueceu sua senha?
        </Text>
      </Box>
      <Divider />
      {hasPasswordToken && <NewPasswordForm />}
      {!hasPasswordToken && <ForgotPasswordEmailForm />}
    </Stack>
  );
};

export default ForgotPassword;
