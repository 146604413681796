import { styled } from '@mui/material/styles';
import { Button as ButtonMUI, ButtonProps as MUIButtonProps } from '@mui/material';

export interface ButtonProps extends Omit<MUIButtonProps, 'component'> {
  gradient?: boolean;
  component?: string;
}

const Button = styled(ButtonMUI, {
  shouldForwardProp: (props) => props !== 'gradient',
})<ButtonProps>(({ gradient, theme }) => ({
  position: 'relative',
  textTransform: 'none',
  borderRadius: '6px',
  fontWeight: '700',
  ...(gradient && {
    background: 'linear-gradient(90deg, #DD6B20 0%, #9C4221 100%)',
    justifyContent: 'space-between',
    '&.MuiButtonBase-root': {
      color: theme.palette.primary.contrastText,
    },
  }),
}));

export default Button;
