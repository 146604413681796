export enum ERoutesAuthentication {
  Login = 'login',
  Signup = 'signup',
  Auth = 'auth',
  Auth_Activate = 'activate',
  Auth_ForgotPassword = 'forgot-password',
}

// TODO find a better name
// this will be used to use on the "navigateTo" function.
export const authenticationRoutePathnames = {
  Login: () => '/auth/login',
  Signup: () => '/auth/signup',
  AuthActivate: (userId: string) => `/auth/activate?user=${userId}`,
  AuthForgotPassword: () => '/auth/forgot-password',
};
