import { Box } from '@mui/material';

import { Navigate, Outlet, useSearchParams } from 'app-router';
import useAuthContext from 'providers/AuthProvider';
import { UserStatusEnum } from 'modules/accounts/common/enums';
import { getRoutePathname } from 'routes/getRoutePathname';

import Header from './Header';

const BoxShellAuth = () => {
  const { user } = useAuthContext();
  const [searchParams] = useSearchParams();
  const profileRoute = getRoutePathname.Profile();
  const searchParamsValue = searchParams.get('redirectTo') ?? profileRoute;
  const validateParams = searchParamsValue.toLowerCase().includes('auth') ? profileRoute : searchParamsValue;

  if (!!user && user.status !== UserStatusEnum.ACTIVE) {
    const activateRoute = getRoutePathname.AuthActivate(user._id);
    return <Navigate to={activateRoute} />;
  }
  if (!!user) {
    return <Navigate to={validateParams} />;
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box
        flexGrow={1}
        justifyContent="flex-start"
        alignItems="center"
        display="flex"
        sx={{
          marginTop: { xs: '20px', md: '80px' },
          padding: { xs: '0px 45px', md: '0px' },
          gap: '20px',
        }}
        flexDirection="column"
      >
        <Header />
        <Box maxWidth="477px">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default BoxShellAuth;
