import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

import { useNavigate, useParams } from 'app-router';
import IconPaidChannel from 'assets/icons/IconPaidChannel';
import { Button, Text, Box, LoadingButton } from 'common/components';
import { TypeChannelEnum } from 'modules/communities/common/channels.enum';
import { TFormDataChannel } from 'modules/communities/common/types/channel.types';
import { getRoutePathname } from 'routes/getRoutePathname';

import TypeChannelCard from './components/TypeChannelCard';
import { TYPES_CHANNEL } from './constants';
import StepOneCreateChannel from './components/StepOneCreateChannel';
import { useCreateChannel } from './channel.api';
import { CreateChannelSchema, TCreateChannelForm } from './channel.schemas';
import ResponsiveCommunityShell from 'modules/communities/common/components/ResponsiveCommunityShell';

export const MAX_CHARACTERS = 90;

const CreateChannel = () => {
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { mutate: createChannel, isLoading: isLoadingCreateChannel } = useCreateChannel();
  const formMethods = useForm<TCreateChannelForm>({
    resolver: zodResolver(CreateChannelSchema),
    defaultValues: {
      name: '',
      description: '',
      type: TypeChannelEnum.PUBLIC,
    },
  });
  const { control, handleSubmit } = formMethods;
  const onSubmit = (values: TCreateChannelForm) => {
    const formData: TFormDataChannel = { ...values, community: id };
    createChannel(formData, { onSuccess: (res) => navigate(getRoutePathname.CommunitiesDetailsChannel(id, res._id)) });
  };
  const selectedChannelType = useWatch({ control, name: 'type' });

  const handleNextStep = () => {
    setPage((prevPage) => {
      if (prevPage === 3) return 3;
      if (prevPage >= 1) return prevPage + 1;
      return 1;
    });
  };
  const handleBackStep = () => {
    setPage((prevPage) => {
      if (prevPage === 1) {
        navigate(getRoutePathname.CommunitiesDetails(id));
        return 1;
      }
      if (prevPage <= 3) return prevPage - 1;
      return 1;
    });
  };
  const step1 = page === 1;
  const step2 = page === 2;

  return (
    <ResponsiveCommunityShell>
      <Box display="flex" flexDirection="column" gap={2}>
        <FormProvider {...formMethods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            display="flex"
            flexDirection="column"
            gap={2}
            marginTop={1}
          >
            <Text variant="h5" fontSize="1.25rem" fontWeight={700}>
              Criar Canal
            </Text>
            {step1 && <StepOneCreateChannel previousPage={handleBackStep} nextPage={handleNextStep} />}
            {step2 && (
              <Box display="flex" flexWrap="wrap" justifyContent="center" gap={4}>
                {TYPES_CHANNEL.map((channelType) => {
                  const channelIcon =
                    channelType.type === TypeChannelEnum.PUBLIC ? undefined : channelType.type ===
                      TypeChannelEnum.PAID ? (
                      <IconPaidChannel />
                    ) : (
                      <LockOpenOutlinedIcon sx={{ width: '20px', height: '20px' }} />
                    );

                  return (
                    <TypeChannelCard
                      key={`key_channelType_${channelType.title}`}
                      channelType={channelType}
                      icon={channelIcon}
                    />
                  );
                })}
              </Box>
            )}

            {step2 && (
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  justifyContent: 'center',
                }}
              >
                <Button variant="outlined" onClick={handleBackStep}>
                  Voltar
                </Button>
                {selectedChannelType === 'public' ? (
                  <LoadingButton
                    loading={isLoadingCreateChannel}
                    disabled={isLoadingCreateChannel}
                    variant="contained"
                    type="submit"
                  >
                    Concluir
                  </LoadingButton>
                ) : (
                  <Button variant="contained" onClick={handleNextStep}>
                    Próximo
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </FormProvider>
      </Box>
    </ResponsiveCommunityShell>
  );
};

export default CreateChannel;
