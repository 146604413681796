import { content } from 'modules/communities/common/editor/initialContent';

export const languageCommunities = [
  {
    name: 'Imersão em Inglês',
    description:
      'Comunidade dedicada à imersão no idioma inglês para aprimorar habilidades de conversação e compreensão.',
    channels: [
      {
        name: 'Conversação em Inglês',
        description: 'Pratique e aprimore suas habilidades de conversação em inglês com outros membros.',
        posts: [
          {
            title: 'Tópicos do Cotidiano',
            content: content,
          },
          {
            title: 'Exercícios de Pronúncia',
            content: content,
          },
        ],
      },
      {
        name: 'Material de Estudo',
        description: 'Compartilhe recursos, dicas e recomendações de materiais de estudo para aprender inglês.',
        posts: [
          {
            title: 'Livros e E-books',
            content: content,
          },
          {
            title: 'Aplicativos e Plataformas Online',
            content: content,
          },
        ],
      },
    ],
  },
  {
    name: 'Gramática Italiana',
    description: 'Comunidade focada no estudo e aprimoramento da gramática italiana.',
    channels: [
      {
        name: 'Regras Gramaticais',
        description: 'Aprenda e discuta as regras gramaticais da língua italiana.',
        posts: [
          {
            title: 'Verbos e Conjugação',
            content: content,
          },
          {
            title: 'Substantivos e Artigos',
            content: content,
          },
        ],
      },
      {
        name: 'Exercícios de Gramática',
        description: 'Compartilhe e resolva exercícios práticos para consolidar o conhecimento da gramática italiana.',
        posts: [
          {
            title: 'Exercícios de Verbos',
            content: content,
          },
          {
            title: 'Exercícios de Frases',
            content: content,
          },
        ],
      },
    ],
  },
  {
    name: 'Conversação em Inglês',
    description: 'Comunidade voltada para a prática e o desenvolvimento da habilidade de conversação em inglês.',
    channels: [
      {
        name: 'Intercâmbio de Idiomas',
        description: 'Encontre parceiros de estudo para intercâmbio de idiomas e pratique conversação em inglês.',
        posts: [
          {
            title: 'Parceiros de Conversação',
            content: content,
          },
          {
            title: 'Sessões de Conversação Online',
            content: content,
          },
        ],
      },
      {
        name: 'Pronúncia em Inglês',
        description: 'Compartilhe dicas, exercícios e recursos para aprimorar a pronúncia em inglês.',
        posts: [
          {
            title: 'Sons e Fonética',
            content: content,
          },
          {
            title: 'Expressões Idiomáticas',
            content: content,
          },
        ],
      },
    ],
  },
  {
    name: 'Cultura Italiana',
    description: 'Comunidade dedicada ao estudo da cultura italiana, incluindo tradições, culinária e história.',
    channels: [
      {
        name: 'Culinária Italiana',
        description: 'Compartilhe receitas, dicas e histórias relacionadas à culinária italiana.',
        posts: [
          {
            title: 'Pratos Típicos',
            content: content,
          },
          {
            title: 'Ingredientes e Especiarias',
            content: content,
          },
        ],
      },
      {
        name: 'História e Arte',
        description: 'Discuta a história, a arte e a cultura italiana, desde os tempos antigos até os dias atuais.',
        posts: [
          {
            title: 'Grandes Nomes da Arte Italiana',
            content: content,
          },
          {
            title: 'História da Itália',
            content: content,
          },
        ],
      },
    ],
  },
];
