import { Tooltip } from '@mui/material';

import { useNavigate } from 'app-router';
import { Box, Image } from 'common/components';
import { TCommunity, TMember } from 'modules/communities/common/types/community.types';
import { getRoutePathname } from 'routes/getRoutePathname';

type WrapperCommunitiesCardMemberProps = {
  communities?: {
    community: TCommunity;
    member: TMember;
  }[];
};

const WrapperCommunitiesCardMember = ({ communities = [] }: WrapperCommunitiesCardMemberProps) => {
  const navigate = useNavigate();
  const communitiesLength = communities?.length > 7 ? 7 : communities?.length;
  const cardCommunityWidth = (24 * 16) / 9;
  const itemsWidth = cardCommunityWidth * communitiesLength;
  const wrapperWidth = 246;
  const availableSpace = wrapperWidth - itemsWidth;
  const margin = availableSpace / (communitiesLength - 1);
  const marginRight = margin > 4 ? 4 : margin;

  const handleClickCommunity = (id: string) => {
    navigate(getRoutePathname.CommunitiesDetails(id));
  };

  return (
    <Box display="flex" justifyContent="center" width="100%">
      {communities.length > 0 &&
        communities.map((community, idx) => {
          if (idx > 6) {
            return null;
          }
          return (
            <Tooltip
              placement="top"
              key={`member_tooltip_${community.community._id}`}
              arrow
              title={community.community.name}
            >
              <Box
                onClick={() => handleClickCommunity(community.community._id)}
                marginRight={`${marginRight}px`}
                position="relative"
                sx={{ cursor: 'pointer' }}
                zIndex={99}
                overflow="hidden"
                border="2px solid white"
                minHeight="24px"
                maxHeight="24px"
                minWidth="calc(24px * 16 / 9)"
                maxWidth="calc(24px * 16 / 9)"
                bgcolor="white"
              >
                <Image src={community.community.avatar_url ?? ''} />T
              </Box>
            </Tooltip>
          );
        })}
    </Box>
  );
};

export default WrapperCommunitiesCardMember;
