import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ListItemText } from '@mui/material';

import { useParams } from 'app-router';
import { Button, LoadingButton } from 'common/components';
import { Menu, MenuItem } from 'common/components/Menu';
import { useCreateMemberByProfileIdAPI } from 'modules/communities/screens/community/communityMembers.api';
import useAuthContext from 'providers/AuthProvider';
import useNotification from 'providers/NotificationProvider';
import { getRoutePathname } from 'routes/getRoutePathname';

import { TMember } from '../types/community.types';
import { MemberRoleEnum, MemberStatusEnum } from '../enums/members.enums';
import DialogInviteMembers from './DialogInviteMembers';

type BeMemberButtonProps = {
  member: TMember | null;
};

const BeMemberButton = ({ member }: BeMemberButtonProps) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (isAdmin) {
      return setAnchorEl(event.currentTarget);
    } else {
      return copyClipBoard();
    }
  };

  const { user } = useAuthContext();
  const { id = '' } = useParams();
  const { toast } = useNotification();

  const userRole = member && !member.is_deleted ? member.role : null;
  const isAdmin = userRole === MemberRoleEnum.ADMIN;
  const isActive = member && member.status === MemberStatusEnum.ACTIVE;
  const isBanned = member && member.status === MemberStatusEnum.ACTIVE;

  const { mutate: createMember, isLoading: createMemberLoading } = useCreateMemberByProfileIdAPI();

  const isLoading = createMemberLoading;

  const handleBeMember = () => {
    createMember({ profile: user?.main_profile._id ?? '', community: id ?? '', role: 'member' });
  };

  const copyClipBoard = () => {
    const linkToCommunity = `localhost:3000${getRoutePathname.CommunitiesDetails(id)}`;
    setAnchorEl(null);
    navigator.clipboard.writeText(linkToCommunity).then(() => {
      toast({ title: 'Link copiado!' });
    });
  };

  return (
    <>
      {userRole && isActive ? (
        <>
          <DialogInviteMembers open={openDialog} handleClose={handleCloseDialog} />
          <Button onClick={handleClick} variant="outlined" size="small" startIcon={<AddIcon />}>
            Convidar
          </Button>
          {isAdmin && (
            <Menu
              id="open-menu"
              MenuListProps={{
                'aria-labelledby': 'open-more-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={handleOpenDialog}>
                <ListItemText>Inserir lista de emails</ListItemText>
              </MenuItem>
              <MenuItem onClick={copyClipBoard}>
                <ListItemText>Copiar link</ListItemText>
              </MenuItem>
            </Menu>
          )}
        </>
      ) : isBanned ? (
        <Button variant="text" sx={{ color: 'error.main', cursor: 'default' }} size="small">
          Banido
        </Button>
      ) : (
        <LoadingButton loading={isLoading} onClick={handleBeMember} variant="contained" size="small">
          Tornar membro
        </LoadingButton>
      )}
    </>
  );
};

export default BeMemberButton;
