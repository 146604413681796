import React from 'react';
import { get } from 'lodash';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';

type trigger = 'onChange' | 'onBlur';

interface PasswordInputProps {
  label?: string;
  placeholder?: string;
  helperText?: string;
  registerName?: string;
  trigger?: trigger[];
}

const PasswordInput: React.FC<PasswordInputProps> = ({ registerName = 'password', label, placeholder, trigger }) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const {
    formState: { errors },
    register,
    trigger: triggerForm,
  } = useFormContext();

  const triggerOnChange = trigger?.includes('onChange');
  const triggerOnBlur = trigger?.includes('onBlur');

  const handleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  const errorMessage = `${registerName}.message`;

  return (
    <TextField
      error={!!`${get(errors, errorMessage, '')}`}
      helperText={`${get(errors, errorMessage, '')}`}
      size="small"
      placeholder={placeholder ?? 'Senha'}
      label={label ?? 'Senha'}
      InputProps={{
        endAdornment: (
          <IconButton aria-label="toggle password visibility" onClick={handleShowPassword}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        ),
        sx: { '& ::-ms-reveal': { display: 'none' } },
      }}
      type={showPassword ? 'text' : 'password'}
      {...register(registerName)}
      onChange={(e) => {
        register(registerName).onChange(e);
        triggerOnChange && triggerForm(registerName);
      }}
      onBlur={() => triggerOnBlur && triggerForm(registerName)}
    />
  );
};

export default PasswordInput;
