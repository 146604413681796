import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { JSONContent } from '@tiptap/react';

import { useParams } from 'app-router';
import { Box, Button, LoadingButton } from 'common/components';

import { useCreateHighlight } from '../community.api';
import TiptapForDescription from 'modules/communities/common/editor/TiptapForDescription';
import { CreateCommunityHighlightSchema, TCreateCommunityHighlightSchema } from '../community.schemas';

interface CreateHighlightProps {
  handleClose: () => void;
  highlight: JSONContent | undefined;
}

const CreateHighlight = ({ handleClose, highlight }: CreateHighlightProps) => {
  const { id } = useParams();
  const mutation = useCreateHighlight();
  const formMethods = useForm<TCreateCommunityHighlightSchema>({
    resolver: zodResolver(CreateCommunityHighlightSchema),
    defaultValues: {
      description: highlight,
    },
  });
  const { handleSubmit } = formMethods;
  const onSubmit = (formData: TCreateCommunityHighlightSchema) => {
    mutation.mutate(
      { formData, id: id ?? '' },
      {
        onSuccess: () => {
          handleClose();
        },
      },
    );
  };

  return (
    <Box
      sx={{
        padding: 1,
        gap: 2,
      }}
      marginBottom={2}
      width="100% "
    >
      <FormProvider {...formMethods}>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <TiptapForDescription content={highlight} editable={true} />

          <Box
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
            <LoadingButton loading={mutation.isLoading} disabled={mutation.isLoading} variant="contained" type="submit">
              Confirmar
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined">
              Voltar
            </Button>
          </Box>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default CreateHighlight;
