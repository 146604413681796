import { QueryClient } from 'react-query';

import { LoaderFunctionArgs, useNavigate, useParams, useSearchParams } from 'app-router';
import { Box, Card, Text } from 'common/components';
import CompleteAvatar from 'common/components/CompleteAvatar';
import { getTimePassed } from 'common/utils/functions';
import TopicButtons from 'modules/communities/common/components/TopicButtons';
import EditorReadOnly from 'modules/communities/common/editor/EditorReadOnly';
import { getRoutePathname } from 'routes/getRoutePathname';

import TopicCommentButton from './components/TopicCommentButton';
// import TopicComments from './components/TopicComments';
import { fetchTopicByIdAPI, useGetTopicByIdAPI } from './post.api';
import { POSTS_KEY } from 'common/utils/queryKeys';

const ID_SCROLL = 'commentBox';
function scrollToElement(id: string) {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView();
  }
}

export const loaderTopicDetail =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const topicId = params.postId ?? '';
    const query = POSTS_KEY.details(topicId);
    queryClient.prefetchQuery({ queryKey: query, queryFn: () => fetchTopicByIdAPI(topicId) });
    return null;
  };

const TopicDetails = () => {
  const { postId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const openComment = !!searchParams.get('comment');
  const scrollToComments = !!searchParams.get('comments') || !!searchParams.get('comment');
  const navigate = useNavigate();
  const { data: topic, isSuccess } = useGetTopicByIdAPI(postId);

  const postEditRoute = getRoutePathname.CommunitiesDetailsPostsEdit(
    topic?.community ?? '',
    topic?.channel ?? '',
    postId,
  );

  const handleEdit = () => {
    navigate(postEditRoute);
  };

  const datePosted = topic ? getTimePassed(topic.created_at) : undefined;

  if (scrollToComments) {
    scrollToElement(ID_SCROLL);
  }

  if (isSuccess) {
    return (
      <Box
        sx={(theme) => ({ borderRadius: theme.spacing(1) })}
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <Card marginTop={{ xs: 0, sm: 2 }} maxWidth="1080px" width="100%">
          <Box display="flex" alignItems="center" gap={1}>
            <CompleteAvatar
              profileId={topic.created_by._id}
              fullName={topic.created_by.profile_name}
              src={topic.created_by.avatar_url}
            />
            <Text variant="caption" sx={{ lineHeight: 1, fontSize: '13px' }}>
              há {datePosted}
            </Text>{' '}
          </Box>
          <Box display="flex" textAlign="center" width="100%" justifyContent="center">
            <Text variant="h1">{topic.title}</Text>
          </Box>
          <EditorReadOnly content={topic.content} />
          <TopicButtons topic={topic} handleEdit={handleEdit} />
          <TopicCommentButton open={openComment} idScroll={ID_SCROLL} />
          {/* {comments && 
          <TopicComments comments={topic.comments} />
          } */}
        </Card>
      </Box>
    );
  } else return null;
};

export default TopicDetails;
