import React from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import { QueryClient } from 'react-query';

import { LoaderFunctionArgs, useParams } from 'app-router';
import SkeletonChannelShell from 'assets/skeletons/SkeletonChannelShell';
import { Image, Text, Box, TabList } from 'common/components';

import TopicsTabChannel from './components/TopicsTabChannel';
import EditChannelDialog from './components/EditChannelDialog';
import SettingsTabChannel from './components/SettingsTabChannel';
import { fetchChannelById, useGetChannelByIdAPI } from './channel.api';
import { CHANNELS_KEY } from 'common/utils/queryKeys';
import TableFiles from 'common/components/TableFiles';

export const loaderChannelDetail =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const channelId = params.channelId ?? '';
    const query = CHANNELS_KEY.details(channelId);
    queryClient.prefetchQuery({ queryKey: query, queryFn: () => fetchChannelById(channelId) });
    return null;
  };

const ChannelDetails = () => {
  const [openEdit, setOpenEdit] = React.useState(false);
  const { channelId = '' } = useParams();
  const { data: channel, isLoading: isLoadingChannel } = useGetChannelByIdAPI(channelId);
  const handleCloseEdit = () => setOpenEdit(false);
  const handleOpenEdit = () => setOpenEdit(true);

  const tabs = channel
    ? [
        {
          id: 'topics',
          title: 'Tópicos',
          component: <TopicsTabChannel />,
        },
        {
          id: 'files',
          title: 'Arquivos',
          component: (
            <Box marginTop={1} display="flex" width="100%" justifyContent="center">
              <TableFiles />
            </Box>
          ),
        },
        {
          id: 'video',
          title: 'Videos',
          component: <div>Videos</div>,
        },
        {
          id: 'settings',
          title: 'Configurações',
          component: <SettingsTabChannel channel={channel} />,
        },
      ]
    : [];

  if (isLoadingChannel || !channel) {
    return <SkeletonChannelShell />;
  }
  if (!channel) {
    return <div>No community.</div>;
  }

  return (
    <>
      <EditChannelDialog
        channelId={channel._id}
        avatarUrl={channel.photo_url}
        communityId={channel.community}
        open={openEdit}
        handleClose={handleCloseEdit}
      />

      <Box width="100%" display="flex" flexDirection="column" gap={2} alignItems="center" maxWidth="1080px">
        <Box
          marginTop={1}
          minWidth="280px"
          maxWidth="280px"
          width="280px"
          height="157.5px"
          minHeight="157.5px"
          maxHeight="157.5px"
          position="relative"
          sx={(theme) => ({ overflow: 'hidden', border: `1px solid ${theme.palette.primary.light}` })}
        >
          <Image src={channel.photo_url} />

          <Box
            position="absolute"
            sx={{
              right: '8px',
              bottom: '8px',
              zIndex: 800,
            }}
          >
            <IconButton
              size="small"
              color="secondary"
              onClick={handleOpenEdit}
              sx={(theme) => ({
                backgroundColor: theme.palette.info.main,
                '&:hover': { backgroundColor: theme.palette.primary.light },
                borderRadius: '6px',
              })}
            >
              <EditOutlinedIcon sx={{ width: '16px', height: '16px' }} />
            </IconButton>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Text variant="h4">{channel.name}</Text>
          <Text>{channel.description}</Text>
        </Box>

        <TabList withoutBorder justifyContent="center" initialIndex={0} tabs={tabs} />
      </Box>
    </>
  );
};

export default ChannelDetails;
