import { Box } from 'common/components';

const SkeletonCommunityAppShell = () => {
  return (
    <Box
      sx={(theme) => ({
        bgcolor: theme.palette.info.light,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateAreas: `"header"
                       "content"`,
      })}
      width="100%"
      marginTop={{ xs: 2, sm: 0 }}
    >
      <Box gridArea="header">
        <Box
          sx={() => ({
            backgroundColor: 'primary.light',
            borderRadius: '0px 0px 6px 6px',
            overflow: 'hidden',
          })}
          height={'100px'}
        />
        <Box display="flex" width="100%" alignItems="flex-start">
          <Box display="flex">
            <Box
              marginTop={1}
              minWidth="160px"
              maxWidth="160px"
              minHeight="90px"
              maxHeight="90px"
              position="relative"
              sx={() => ({ overflow: 'hidden', bgcolor: `primary.light` })}
            />

            <Box display="flex" flexDirection="column" width="100%" padding={1} gap={1}>
              <Box width="160px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />

              <Box width="90px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />

              <Box width="160px" height="20px" sx={{ borderRadius: 0, bgcolor: 'primary.light' }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SkeletonCommunityAppShell;
