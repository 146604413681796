import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useNavigate } from 'app-router';
import { axiosService } from 'common/services/axiosService';
import { TPaginatedResource } from 'common/types/api.types';
import { CHANNELS_KEY, COMMUNITIES_KEY } from 'common/utils/queryKeys';
import { handleFetchMany } from 'common/utils/functions';
import { HEADER_CONFIG_UPLOAD_FILE } from 'common/utils/api.constants';
import { COMMUNITIES_API_PATH } from 'modules/communities/common/constants';
import { TChannel, TFormDataChannel } from 'modules/communities/common/types/channel.types';
import useNotification from 'providers/NotificationProvider';
import { getRoutePathname } from 'routes/getRoutePathname';

type TParams = {
  page?: number;
  size?: number;
  [key: string]: unknown;
};

async function fetchCreateChannel(formData: TFormDataChannel) {
  const { data } = await axiosService.post<TChannel>(`${COMMUNITIES_API_PATH}/channels`, formData);
  return data;
}

export function useCreateChannel() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: fetchCreateChannel,
    onSuccess: (res) => {
      queryClient.invalidateQueries(COMMUNITIES_KEY.details(res.community));
    },
  });
}

async function fetchChannelsyByCommunityId(id: string, params: TParams) {
  const { data } = await axiosService.get<TPaginatedResource<TChannel>>(
    `${COMMUNITIES_API_PATH}/communities/${id}/channels`,
    { params },
  );
  return data;
}

export function useGetChannelsByCommunityIdAPI(id: string, userParams: TParams = { page: 0, size: 15 }) {
  return useQuery(
    CHANNELS_KEY.list({ community: id }),
    () => handleFetchMany(fetchChannelsyByCommunityId(id, userParams)),
    {
      enabled: !!id,
    },
  );
}

export async function fetchChannelById(id: string) {
  const { data } = await axiosService.get<TChannel>(`${COMMUNITIES_API_PATH}/channels/${id}`);
  return data;
}

export function useGetChannelByIdAPI(id: string) {
  return useQuery(CHANNELS_KEY.details(id), () => fetchChannelById(id), {
    enabled: !!id,
  });
}

async function fetchUploadImgById({ channelId, file }: { channelId: string; file: File | null }) {
  const body = {
    file: file,
    ref_id: channelId,
    association: 'channels',
    association_kind: 'avatar',
  };
  const { data } = await axiosService.post('upload/api/v1/media/images', body, HEADER_CONFIG_UPLOAD_FILE);
  return data;
}

export function useUpdateChannelAvatarByIdAPI(channelId: string, communityId: string) {
  const queryClient = useQueryClient();
  const { toast } = useNotification();
  return useMutation({
    mutationFn: (file: File | null) => fetchUploadImgById({ channelId, file }),
    onSuccess: (res) => {
      const channel: TChannel | undefined = queryClient.getQueryData(CHANNELS_KEY.details(channelId));
      const photo_url = res.image.url;
      const newChannel = { ...channel, photo_url };
      toast({ title: 'Capa atualziada.' });
      queryClient.setQueryData(CHANNELS_KEY.details(channelId), newChannel);
      queryClient.invalidateQueries(COMMUNITIES_KEY.details(communityId));
    },
  });
}

async function fetchUpdateChannelById({
  id,
  update,
}: {
  id: string;
  update: Partial<Omit<TFormDataChannel, 'community'>>;
}) {
  const { data } = await axiosService.patch<TChannel>(`${COMMUNITIES_API_PATH}/channels/${id}`, update);
  return data;
}

export function useUpdateChannelByIdAPI(channelId: string) {
  const { toast } = useNotification();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ update }: { update: Partial<Omit<TFormDataChannel, 'community' | 'type'>> }) =>
      fetchUpdateChannelById({ id: channelId, update }),
    onSuccess: (res) => {
      toast({ title: 'Canal atualizado com sucesso.' });
      queryClient.invalidateQueries(COMMUNITIES_KEY.details(res.community));
      queryClient.invalidateQueries(CHANNELS_KEY.details(channelId));
    },
  });
}

async function fetchDeleteChannelById({ channelId }: { channelId: string }) {
  const { data } = await axiosService.delete(`${COMMUNITIES_API_PATH}/channels/${channelId}`);
  return data;
}

export function useDeleteChannelByIdAPI() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { toast } = useNotification();
  return useMutation({
    mutationFn: fetchDeleteChannelById,
    onSuccess: (res) => {
      navigate(getRoutePathname.CommunitiesDetails(res.community));
      queryClient.invalidateQueries(COMMUNITIES_KEY.details(res.community));
      toast({ title: 'Comunidade deletada.' });
    },
  });
}
