import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import { useSearchParams } from 'app-router';
import { Avatar, Box, TabList, Text } from 'common/components';
import useAuthContext from 'providers/AuthProvider';
import { useGetProfileByIdAPI } from 'modules/accounts/common/apis/profiles.api';

import { TabCommunities, TabSecurity, MainProfileHeader } from './components';
import EditProfileDialog from './components/EditProfileDialog';
import { TUpdateProfileForm, UpdateProfileSchema } from './profile.schemas';
import TabBookmarks from './components/TabBookmarks';

const User = () => {
  const [open, setOpen] = React.useState(false);
  const [searchParams] = useSearchParams();
  const ininitalIndex = !!searchParams.get('bookmarks') ? 1 : 0;
  const { user } = useAuthContext();
  const { data: profile, isLoading: isLoadingProfile } = useGetProfileByIdAPI(user?.main_profile._id ?? '');

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const formMethods = useForm<TUpdateProfileForm>({
    resolver: zodResolver(UpdateProfileSchema),
    defaultValues: {
      profile_name: user?.main_profile.profile_name,
      short_bio: user?.main_profile?.short_bio ?? '',
    },
  });

  const communities = profile?.communities ?? [];

  const tabs = [
    {
      id: 'communities',
      title: 'Comunidades',
      component: <TabCommunities communities={communities} />,
    },
    {
      id: 'bookmarks',
      title: 'Salvos',
      component: <TabBookmarks />,
    },
    {
      id: 'security',
      title: 'Segurança',
      component: (
        <TabSecurity
          email={
            <Text fontWeight={400} variant="subtitle2">
              {user?.email}
            </Text>
          }
        />
      ),
    },
  ];

  React.useEffect(() => {
    formMethods.reset({
      profile_name: user?.main_profile.profile_name,
      short_bio: user?.main_profile?.short_bio ?? '',
    });
  }, [user]);

  if (isLoadingProfile) {
    return null;
  }

  return (
    <Box display="flex" width="100%" marginTop={{ xs: 2, sm: 0 }} paddingBottom={2} flexDirection="column" gap="16px">
      <EditProfileDialog
        avatarUrl={profile?.avatar_url ?? ''}
        bannerUrl={profile?.banner_url ?? ''}
        refId={profile?._id ?? ''}
        open={open}
        handleClose={handleClose}
      />
      <FormProvider {...formMethods}>
        <MainProfileHeader
          profileId={user?.main_profile._id ?? ''}
          hero={
            <Box width="100%" height="100%" display="flex">
              <img
                width="100%"
                alt="banner_profile"
                style={{ position: 'relative', objectFit: 'cover' }}
                src={profile?.banner_url}
              />
            </Box>
          }
          avatar={
            <>
              <Box
                position="absolute"
                sx={{
                  left: '90px',
                  bottom: '10px',
                  zIndex: 999,
                }}
              >
                <IconButton
                  size="small"
                  color="primary"
                  onClick={handleOpen}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.info.main,
                    '&:hover': { backgroundColor: theme.palette.primary.light },
                  })}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Box>
              <Avatar
                profileName={user?.main_profile.profile_name ?? ''}
                sx={{ width: '120px', height: '120px' }}
                src={profile?.avatar_url}
              />
            </>
          }
          fullname={
            <Text fontWeight={700} variant="h5">
              {user?.main_profile.profile_name}
            </Text>
          }
          about={
            <Text fontWeight={400} variant="subtitle2" sx={{ color: 'info.dark' }}>
              {user?.main_profile?.short_bio}
            </Text>
          }
        />
      </FormProvider>
      <TabList initialIndex={ininitalIndex} tabs={tabs} />
    </Box>
  );
};

export default User;
