import DescriptionIcon from '@mui/icons-material/Description';

import { Box, Image, Text } from 'common/components';
import CompleteAvatar from 'common/components/CompleteAvatar';
import { getTimePassed } from 'common/utils/functions';
import { TTopic } from 'modules/communities/screens/posts/topic.types';

type TTopicCardForSearch = {
  topic: TTopic;
};

const TopicCardForSearch = ({ topic }: TTopicCardForSearch) => {
  const images = topic.content?.content?.filter((it) => it.type === 'image') ?? [];
  const datePosted = getTimePassed(topic.created_at);

  return (
    <Box gap={2} display="flex" padding="8px 4px" flex={1} width="100%">
      <Box
        sx={{ cursor: 'pointer', backgroundColor: 'info.dark' }}
        display="flex"
        width="80px"
        height="80px"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        {images.length > 0 ? (
          <Image src={images?.[0].attrs?.src} />
        ) : (
          <DescriptionIcon sx={{ color: 'white', width: '44px', height: '44px' }} />
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="column" gap={0.5}>
        <Box display="flex" gap={1} alignItems="center">
          <CompleteAvatar
            profileId={topic.created_by._id}
            src={topic.created_by.avatar_url}
            fullName={topic.created_by.profile_name}
          />
          <Text variant="body2">hà {datePosted}</Text>
        </Box>
        <Text variant="h5" sx={{ color: 'black' }}>
          {topic.title}
        </Text>
        <Text variant="caption2">40 Comentarios</Text>
      </Box>
    </Box>
  );
};

export default TopicCardForSearch;
