import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { useParams } from 'app-router';
import { Button, LoadingButton } from 'common/components';

import { useCreateHighlight } from '../community.api';
import { emptyContent } from 'modules/communities/common/editor/initialContent';

type DeleteHighlightProps = {
  open: boolean;
  handleClose: () => void;
};

const DeleteHighlight = ({ open, handleClose }: DeleteHighlightProps) => {
  const { id } = useParams();
  const { mutate: deleteHighlight, isLoading: isDeleting } = useCreateHighlight();

  const onDelete = () => {
    const formData = { description: emptyContent };
    deleteHighlight(
      { formData, id: id ?? '' },
      {
        onSuccess: () => {
          handleClose();
        },
      },
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deletar Destaque</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Após deletar não será possível recuperar essas informações. Tem certeza que deseja fazer isso?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isDeleting} variant="outlined" size="small" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton loading={isDeleting} size="small" variant="contained" color="error" onClick={onDelete}>
          Deletar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteHighlight;
