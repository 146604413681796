import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormControlLabel, Checkbox } from '@mui/material';
import { FormProvider, SubmitHandler, useForm, useWatch } from 'react-hook-form';

import { CheckBoxOutlineIcon } from 'assets/icons/CheckBoxOutlineIcon';
import { Box, Button, LoadingButton, Text } from 'common/components';
import { DescriptionInputCommunity, NameInputCommunity } from 'modules/communities/common';
import { TCommunity, TCreateCommunityRequest } from 'modules/communities/common/types/community.types';
import useNotification from 'providers/NotificationProvider';

import CategoryInput from './CategoryInput';
import TagGroupForm from './TagGroupForm';
import { useUpdateCommunityByIdAPI } from '../community.api';
import { EditCommunitySchema, TEditCommunityForm } from '../community.schemas';

type PanelSettingsCommunityProps = {
  community: TCommunity;
};

export const MAX_CHARACTERS = 90;

const normalizeCommunityData = (values: TEditCommunityForm) => {
  const community: Omit<TCreateCommunityRequest, 'founder'> = {
    name: values.name,
    description: values.description,
    category: values.category,
    tags: !!values.tags && values.tags?.length < 1 ? undefined : values.tags,
    settings: {
      allow_minor_members: !values.not_allow_minor_members,
    },
  };
  return community;
};

const PanelSettingsCommunity = ({ community }: PanelSettingsCommunityProps) => {
  const { toast } = useNotification();
  const { mutate: updateCommunity, isLoading: updateCommunityLoading } = useUpdateCommunityByIdAPI(community._id);
  const isLoading = updateCommunityLoading;
  const formMethods = useForm<TEditCommunityForm>({
    resolver: zodResolver(EditCommunitySchema),
    defaultValues: {
      name: community.name,
      description: community.description,
      category: community.category,
      tags: community.tags,
      not_allow_minor_members: true,
    },
  });

  const checkKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      return e.preventDefault();
    }
  };

  const onSubmit: SubmitHandler<TEditCommunityForm> = (formData) => {
    const update = normalizeCommunityData(formData);
    updateCommunity(
      { update },
      {
        onError: () => {
          toast({ title: 'Falha ao atualizar comunidade', message: 'Tente novamente', type: 'error' });
        },
      },
    );
  };
  const { handleSubmit, register, reset, control } = formMethods;
  const handleCancel = () => {
    reset();
  };

  const notAllowMinorMembers = useWatch({ control, name: 'not_allow_minor_members' });

  React.useEffect(() => {
    formMethods.reset({
      name: community.name,
      description: community.description,
      category: community.category,
      tags: community.tags,
      not_allow_minor_members: false,
    });
  }, [community]);

  return (
    <FormProvider {...formMethods}>
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        component="form"
        alignItems="center"
        onKeyDown={(e) => checkKeyDown(e)}
        gap={4}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            display: 'flex',
            maxWidth: '1080px',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
          }}
        >
          <NameInputCommunity />
          <DescriptionInputCommunity maxCharacteres={MAX_CHARACTERS} />
          <CategoryInput />
          <TagGroupForm />
          <Box>
            <Text fontWeight={500}>Conteudo adulto</Text>
            <FormControlLabel
              control={
                <Checkbox
                  {...register('not_allow_minor_members')}
                  checked={notAllowMinorMembers}
                  sx={{
                    color: 'primary.light',
                    '&:hover': { backgroundColor: 'transparent' },
                    width: '38px',
                    height: '38px',
                  }}
                  icon={<CheckBoxOutlineIcon />}
                />
              }
              label="Comunidade para maiores de 18 anos"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignSelf: 'center',
            alignItems: 'center',
          }}
        >
          <Button variant="outlined" onClick={handleCancel}>
            Cancelar
          </Button>
          <LoadingButton loading={isLoading} disabled={isLoading} variant="contained" type="submit">
            Salvar
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default PanelSettingsCommunity;
