export const emptyContent = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
    },
  ],
};
export const content = {
  type: 'doc',
  content: [
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Compartilhe exercícios e desafios relacionados à conjugação de verbos em italiano.',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Pratique a construção correta de frases em italiano por meio de exercícios interativos.',
        },
      ],
    },
  ],
};
