import React from 'react';
import { Box, Button, Card } from 'common/components';
import { getRoutePathname } from 'routes/getRoutePathname';
import { useNavigate } from 'react-router-dom';

interface ChannelCardProps {
  avatar: React.ReactNode;
  name: React.ReactNode;
  description: React.ReactNode;
  communityId: string;
  channelId: string;
}

const ChannelCard = ({ avatar, name, description, communityId, channelId }: ChannelCardProps) => {
  const channelRoute = getRoutePathname.CommunitiesDetailsChannel(communityId, channelId);
  const navigate = useNavigate();
  const handleEnterChannel = () => (!!communityId ? navigate(channelRoute) : null);
  return (
    <Card
      sx={(theme) => ({
        '&:hover': { boxShadow: theme.yethosCustoms.shadows.cardHover },
        width: '280px',
        padding: '0 0 16px 0',
        overflow: 'hidden',
      })}
    >
      <Box sx={() => ({ borderRadius: 0 })} width="100%" height="157.5px">
        {avatar}
      </Box>
      <Box display="flex" flexDirection="column" textAlign="center" alignItems="center" padding="0 16px" gap={0.5}>
        <Box display="flex" gap={0.5}>
          {name}
        </Box>
        {description}
      </Box>
      <Box display="flex" alignItems="flex-end" padding="0 16px" flex={1}>
        <Box width="100%">
          <Button onClick={handleEnterChannel} fullWidth variant="contained">
            Entrar
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default ChannelCard;
