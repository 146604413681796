import { Divider, Tooltip } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';

//TODO remove apis from screens folder

import { useNavigate } from 'app-router';
import CommunityAvatarIcon from 'assets/icons/CommunityAvatarIcon';
import { Box } from 'common/components';
import { useGetProfileByIdAPI } from 'modules/accounts/common/apis/profiles.api';
import { MemberRoleEnum, MemberStatusEnum } from 'modules/communities/common/enums/members.enums';
import useAuthContext from 'providers/AuthProvider';
import { getRoutePathname } from 'routes/getRoutePathname';

import NavCommunityButton from './components/NavCommunityButton';
import NavDefaultLinks from './components/NavDefaultLinks';
import ListCommunitiesNavbar from './components/ListCommunitiesNavbar';

const Navbar = () => {
  const { user } = useAuthContext();
  const { data: profile } = useGetProfileByIdAPI(user?.main_profile._id ?? '');

  const profileCommunities = profile?.communities ?? null;
  const navigate = useNavigate();

  const handleNavigateCommunity = (communityId: string) => {
    navigate(getRoutePathname.CommunitiesDetails(communityId));
  };

  const communitiesModerator = profileCommunities
    ? profileCommunities.filter(({ member }) => member.role === MemberRoleEnum.MODERATOR)
    : null;

  const communitiesMember = profileCommunities
    ? profileCommunities.filter(
        (community) =>
          community.member.status === MemberStatusEnum.ACTIVE && community.member.role === MemberRoleEnum.MEMBER,
      ) ?? null
    : null;

  const itemsModerator = communitiesModerator?.length
    ? communitiesModerator.map(({ community }) => (
        <Tooltip key={`id_${community._id}_admin`} title={community.name} placement="right">
          <NavCommunityButton
            onClick={() => handleNavigateCommunity(community._id)}
            sx={{ width: 'fit-content', padding: '6px 6px', marginTop: '2px' }}
            size="medium"
            colorIcon="secondary"
          >
            {community.avatar_url ? <CommunityAvatarIcon avatarUrl={community.avatar_url} /> : <GroupsIcon />}
          </NavCommunityButton>
        </Tooltip>
      ))
    : null;

  const itemsMember = communitiesMember?.length
    ? communitiesMember.map(({ community }, idx) => {
        if (idx > 3) {
          return null;
        } else
          return (
            <Tooltip key={`id_${community._id}_member`} title={community.name} placement="right">
              <NavCommunityButton
                onClick={() => handleNavigateCommunity(community._id)}
                sx={{ width: 'fit-content', padding: '6px 6px', marginTop: '2px' }}
                colorIcon="secondary"
              >
                {community.avatar_url ? <CommunityAvatarIcon avatarUrl={community.avatar_url} /> : <GroupsIcon />}
              </NavCommunityButton>
            </Tooltip>
          );
      })
    : null;

  return (
    <Box
      sx={() => ({
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        overflow: 'auto',
        padding: 1,
      })}
    >
      <NavDefaultLinks />
      <Divider sx={{ borderColor: 'transparent' }} />
      {itemsModerator && <ListCommunitiesNavbar items={itemsModerator} title="Moderador" />}
      {itemsMember && (
        <>
          <ListCommunitiesNavbar items={itemsMember} title="Membro" />
        </>
      )}
    </Box>
  );
};

export default Navbar;
