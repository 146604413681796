import { styled } from '@mui/material/styles';
import { Menu as MenuMUI, MenuItem as MenuItemMUI, MenuProps, MenuItemProps } from '@mui/material';

const Menu = styled(MenuMUI)<MenuProps>(({ theme }) => ({
  '& .MuiMenu-paper': {
    overflow: 'visible',
    boxShadow: theme.yethosCustoms.shadows.cardHover,
    border: `1px solid ${theme.palette.primary.light}`,
    marginTop: theme.spacing(1.5),
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      marginLeft: -0.5,
      marginRight: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      backgroundColor: theme.palette.background.paper,
      borderLeft: `1px solid ${theme.palette.primary.light}`,
      borderTop: `1px solid ${theme.palette.primary.light}`,
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}));

const MenuItem = styled(MenuItemMUI)<MenuItemProps>(({ theme }) => ({
  '& .MuiListItemText-root': {
    '> span': {
      fontWeight: 600,
      fontSize: '.75rem',
    },
  },
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
    color: theme.palette.secondary.main,
  },
}));

export { Menu, MenuItem };
