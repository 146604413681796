import { z } from 'zod';
import { JSONContent } from '@tiptap/react';

const FileSchema = z
  .custom<string | File | FileList>((val) => {
    if (typeof val === 'string' || val instanceof File || val instanceof FileList) {
      return true;
    } else return false;
  }, 'Arquivo inválido')
  .optional();

const topic = z.custom<JSONContent>();
const name = z.string().min(1, { message: 'Título é obrigatório.' });

//CREATE TOPIC COMMUNITY SCHEMA
export const CreateTopicSchema = z.object({
  name: name,
  description: topic,
});

export type TCreateTopicSchema = z.infer<typeof CreateTopicSchema>;

//CREATE REPLY SCHEMA
export const CreateReplySchema = z
  .object({
    file: FileSchema.optional(),
    reply: z.string(),
  })
  .refine(({ reply, file }) => reply.length > 0 || typeof file !== undefined, 'Precisa comentar');

export type TCreateReplyForm = z.infer<typeof CreateReplySchema>;

//CREATE COMMENT SCHEMA
export const CreateCommentSchema = z
  .object({
    file: FileSchema.optional(),
    comment: z.string(),
  })
  .refine(({ comment, file }) => comment.length > 0 || typeof file !== undefined, 'Precisa comentar');

export type TCreateCommentForm = z.infer<typeof CreateCommentSchema>;
