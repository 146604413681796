import { useFormContext, useWatch } from 'react-hook-form';

import imgAvatar from 'assets/avatarCommunity.svg';
import { Avatar, Box, Card, Image, Text } from 'common/components';
import { categoriesArr } from 'common/utils/filters.community.constants';
import { InputValueEnum } from 'common/utils/validation.schemas';
import useAuthContext from 'providers/AuthProvider';

type CommunityCardFormProps = {};

const CommunityCardForm = ({}: CommunityCardFormProps) => {
  const { user } = useAuthContext();

  const { control } = useFormContext();
  const name = useWatch({ control, name: InputValueEnum.NAME });
  const category = useWatch({ control, name: 'category' });
  const description = useWatch({ control, name: InputValueEnum.DESCRIPTION });
  const categoryLabel = categoriesArr.find((item) => item.value === category)?.label;

  return (
    <Card
      sx={(theme) => ({
        alignItems: 'flex-start',
        overflow: 'hidden',
        backgroundColor: 'transparent',
        gap: 1,
        padding: 0,
        boxShadow: theme.yethosCustoms.shadows.cardHover,

        minHeight: '286px',
        width: '280px',
        maxWidth: '280px',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          position: 'relative',
          width: '100%',
          gap: 1,
          flex: 1,
        }}
      >
        <Box sx={{ width: '100%', height: 'calc(280px / 6)', position: 'absolute' }}>
          <Box
            sx={{
              background: 'linear-gradient(180deg, rgba(221, 107, 32, 0.75) 0%, rgba(156, 66, 33, 0.75) 100%)',
              borderRadius: 0,
            }}
            width="100%"
            minHeight="calc(280px / 6)"
            display="flex"
            justifyContent="center"
            alignItems="center"
          />
        </Box>

        <Box
          sx={{
            padding: '16px 16px 0px',
            zIndex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              borderRadius: theme.spacing(1),
              width: '160px',
              height: '90px',
              border: `1px solid ${theme.palette.primary.light}`,
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              backgroundColor: 'white',
              display: 'flex',
              zIndex: 10,
            })}
          >
            <Box width="56px" height="56px">
              <Image src={imgAvatar} />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            gap: 0.5,
            padding: '0px 16px',
          }}
        >
          <Text fontWeight={700}>{!!name ? name : 'Nome da comunidade'}</Text>
          <Text variant="body1">
            {!!categoryLabel ? categoryLabel : 'Categoria'} <span style={{ fontWeight: 700, fontSize: '1rem' }}>·</span>{' '}
            1 Membro
          </Text>
          <Text
            variant="body1"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {!!description ? description : 'Descrição da comunidade'}
          </Text>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0px 16px 16px',
            flex: 1,
            gap: 1,
          }}
        >
          <Text variant="caption">Criado por</Text>
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar
              sx={{ width: '24px', height: '24px', fontSize: '.75rem' }}
              src={user?.main_profile.avatar_url}
              profileName={user?.main_profile.profile_name ?? ''}
            />
            <Text fontWeight={700} variant="subtitle2">
              {user?.full_name}
            </Text>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CommunityCardForm;
