import { QueryClient } from 'react-query';

import { LoaderFunctionArgs, useParams } from 'app-router';
import { Box, Image, TabList, Text } from 'common/components';
import useAuthContext from 'providers/AuthProvider';
import { MemberRoleEnum } from 'modules/communities/common/enums/members.enums';
import { TMember } from 'modules/communities/common/types/community.types';
import { emptyContent } from 'modules/communities/common/editor/initialContent';
import ResponsiveCommunityShell from 'modules/communities/common/components/ResponsiveCommunityShell';

import { fetchCommunityById, useGetCommunityByIdAPI } from './community.api';
import { AboutCommunity, ChannelCard, ChannelList, PanelCommunity } from './components';
import { useGetMembersByCommunityIdAPI } from './communityMembers.api';
import WallCommunity from './components/WallCommunity';
import { useGetChannelsByCommunityIdAPI } from '../channel/channel.api';
import MembersCommunity from './components/MembersCommunity';
import { COMMUNITIES_KEY } from 'common/utils/queryKeys';

export const loaderCommunityDetails =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const communityId = params.id ?? '';
    const query = COMMUNITIES_KEY.details(communityId);
    queryClient.prefetchQuery({ queryKey: query, queryFn: () => fetchCommunityById(communityId) });
    return null;
  };

const CommunityDetails = () => {
  const { user } = useAuthContext();
  const { id = '' } = useParams();
  const { data: community, isLoading } = useGetCommunityByIdAPI(id);
  const { data: channelsData } = useGetChannelsByCommunityIdAPI(id);
  const { data: returnMembers } = useGetMembersByCommunityIdAPI(id, {
    page: 0,
    size: 15,
  });

  const members = returnMembers?.items ?? [];

  //TODO: should get member with profileId and communityId using backend.
  const meMember = members.find((member) => member.profile._id === user?.main_profile._id) ?? null;
  const isAdmin = meMember?.role === MemberRoleEnum.ADMIN;
  const isFounder = user?.main_profile._id === community?.founder._id;

  const hasPermission = ({ user, permissions }: { user: TMember | null; permissions?: string[] }) => {
    return permissions ? permissions.includes(user?.role || user?.status || 'not-render') : true;
  };

  const channels = channelsData?.items ?? [];

  const tabs = [
    {
      id: 'board',
      title: 'Mural',
      component: <WallCommunity />,
    },

    {
      id: 'channels',
      title: 'Canais',
      component: (
        <ChannelList
          isAdmin={isAdmin}
          channels={
            channels.length > 0 ? (
              !isLoading &&
              channels.map((channel) => (
                <ChannelCard
                  key={`key_channel_${channel._id}`}
                  communityId={channel.community}
                  channelId={channel._id}
                  avatar={<Image src={channel.photo_url} />}
                  name={
                    <Text fontWeight={700} lineHeight={1.2} fontSize="1rem">
                      {channel.name}
                    </Text>
                  }
                  description={
                    <Text fontWeight={400} fontSize=".75rem">
                      {channel.description}
                    </Text>
                  }
                />
              ))
            ) : (
              <Text>Nenhum canal</Text>
            )
          }
        />
      ),
    },
    {
      id: 'about',
      title: 'Sobre',
      component: <AboutCommunity isAdmin={isAdmin} highlight={emptyContent} />,
    },
    {
      id: 'members',
      title: 'Membros',
      component: <MembersCommunity />,
    },
    {
      id: 'panel',
      title: 'Painel',
      permissions: [MemberRoleEnum.ADMIN, MemberRoleEnum.MODERATOR],
      component: community ? (
        <PanelCommunity meMember={meMember} isFounder={isFounder} community={community} />
      ) : (
        <p>No community</p>
      ),
    },
  ];

  const allowedTabs = tabs.filter((it) => hasPermission({ user: meMember, permissions: it.permissions }));

  return (
    <ResponsiveCommunityShell>
      <Box display="flex" flexDirection="column" marginTop={0.5} gap={1}>
        <TabList initialIndex={0} tabs={allowedTabs} />
      </Box>
    </ResponsiveCommunityShell>
  );
};

export default CommunityDetails;
