import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { TextField } from '@mui/material';

const EmailInput = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  return (
    <TextField
      id="email"
      error={!!`${get(errors, 'email.message', '')}`}
      size="small"
      label="Email"
      placeholder="Email"
      helperText={`${get(errors, 'email.message', '')}`}
      {...register('email')}
    />
  );
};

export default EmailInput;
