import { AvatarProps as AvatarPropsMUI, Avatar as AvatarMUI } from '@mui/material';

interface AvatarProps extends AvatarPropsMUI {
  profileName: string;
}

const Avatar = ({ src, profileName, sx }: AvatarProps) => {
  const firstWord = profileName.slice(0, 1).toUpperCase();
  return (
    <>
      {src ? (
        <AvatarMUI src={src} sx={[...(Array.isArray(sx) ? sx : [sx])]} />
      ) : (
        <AvatarMUI sx={[{ fontSize: '1rem', backgroundColor: 'primary.main' }, ...(Array.isArray(sx) ? sx : [sx])]}>
          {firstWord}
        </AvatarMUI>
      )}
    </>
  );
};

export default Avatar;
