import React from 'react';
import { DebouncedFunc } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { InputBase, Box, Dialog, IconButton } from '@mui/material';

import { createSearchParams, useNavigate } from 'app-router';
import { getRoutePathname } from 'routes/getRoutePathname';
import { Button, Text, WrapperSearchInput } from 'common/components';
import { TCommunity } from 'modules/communities/common/types/community.types';
import { TPaginatedResource } from 'common/types/api.types';
import { CommunityCardAutocomplete } from 'modules/communities/screens/community/components';

interface SearchInputAutocompleteProps {
  placeholder?: string;
  query: string;
  communities: TPaginatedResource<TCommunity>[];
  debounceFn: DebouncedFunc<(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void>;
  open: boolean;
  handleCloseDialog: () => void;
  cleanQuery: () => void;
}

const SearchMobile: React.FC<SearchInputAutocompleteProps> = ({
  placeholder,
  communities,
  debounceFn,
  query,
  open,
  cleanQuery,
  handleCloseDialog,
}) => {
  const [value, setValue] = React.useState(query);
  const navigate = useNavigate();
  let options: TCommunity[] = communities[0]?.items ?? [];

  const handleCleanQuery = () => {
    cleanQuery();
    setValue('');
  };

  const handleClose = () => {
    handleCloseDialog();
    cleanQuery();
    setValue('');
  };

  const onClickAdvancedSearch = () => {
    navigate(getRoutePathname.Communities());
    handleClose();
  };

  const onClickMoreResults = () => {
    navigate({
      pathname: getRoutePathname.Communities(),
      search: `?${createSearchParams({ q: query })}`,
    });
    handleClose();
  };

  const onClickCommunity = (id: string) => {
    navigate(getRoutePathname.CommunitiesDetails(id));
    handleClose();
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose}>
      <Box display="flex" gap={2} padding={3}>
        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
          <ArrowBackIcon />
        </IconButton>
        <Box width="100%">
          <WrapperSearchInput>
            <Box
              sx={{
                padding: '0px 5px',
                height: '100%',
                position: 'absolute',
                pointerEvents: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SearchIcon sx={(theme) => ({ color: theme.palette.info.dark })} />
            </Box>
            <InputBase
              size="small"
              autoFocus
              sx={{
                color: 'inherit',
                width: '100%',
                '& .MuiInputBase-input': {
                  padding: 1,
                  paddingLeft: 5,
                  width: '100%',
                },
              }}
              placeholder={placeholder}
              onChange={(e) => {
                debounceFn(e);
                setValue(e.target.value);
              }}
              value={value}
            />

            <Box
              sx={{
                padding: '0px 5px',
                height: '100%',
                display: !!value ? 'flex' : 'none',
                top: 0,
                right: 4,
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 999,
              }}
              onClick={handleCleanQuery}
            >
              <CloseIcon fontSize="small" />
            </Box>
          </WrapperSearchInput>
        </Box>
      </Box>
      {!!query && (
        <Box display="flex" flexDirection="column" gap={2} padding="0px 8px">
          {options.length > 0 ? (
            <>
              {options.slice(0, 5).map((community, idx) => (
                <Box onClick={() => onClickCommunity(community._id)} key={`key_${idx + community._id}`}>
                  <CommunityCardAutocomplete community={community} />
                </Box>
              ))}
              {options.length > 4 && (
                <Box
                  display="flex"
                  sx={{
                    borderRadius: '4px',
                    width: '100%',
                    backgroundColor: 'white',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}
                  zIndex={1}
                >
                  <Button onClick={onClickMoreResults} fullWidth variant="text">
                    Ver mais resultados
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <>
              <Box display="flex" justifyContent="center">
                <Text>Não encontramos o que procura.</Text>
              </Box>
              <Box
                display="flex"
                sx={{
                  borderRadius: '4px',
                  width: '100%',
                  backgroundColor: 'white',
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                }}
                zIndex={1}
              >
                <Button onClick={onClickAdvancedSearch} fullWidth variant="text">
                  Pesquisa avançada
                </Button>
              </Box>
            </>
          )}
        </Box>
      )}
    </Dialog>
  );
};

export default SearchMobile;
