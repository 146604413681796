import React from 'react';
import { get, omit } from 'lodash';
import { Box, IconButton, TextField, useMediaQuery, useTheme } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useFormContext } from 'react-hook-form';

import { TProfile } from 'modules/accounts/common/types';
import { useUpdateProfileByIdAPI } from 'modules/accounts/common/apis/profiles.api';
import { Button, LoadingButton } from 'common/components';
import useAuthContext from 'providers/AuthProvider';
import { TUpdateProfileForm } from '../profile.schemas';

interface MainProfileHeaderProps {
  avatar: React.ReactNode;
  hero: React.ReactNode;
  fullname: React.ReactNode;
  about: React.ReactNode;
  profileId: TProfile['_id'];
}

const MainProfileHeader: React.FC<MainProfileHeaderProps> = ({ avatar, hero, fullname, profileId, about }) => {
  const { updateUser, user } = useAuthContext();
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useFormContext<TUpdateProfileForm>();
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { mutate: updateInfoProfile, isLoading: updateInfoProfileIsLoading } = useUpdateProfileByIdAPI();
  const isLoading = updateInfoProfileIsLoading;

  const [editMode, setEditMode] = React.useState(false);

  const handleOpenEdit = () => {
    setEditMode(true);
  };

  const onSubmit = async (data: TUpdateProfileForm) => {
    const { profile_name, short_bio } = data;
    const hasNewProfileName = profile_name !== user?.main_profile.profile_name;
    const hasNewShortBio = short_bio !== user?.main_profile.short_bio;
    if (hasNewProfileName || hasNewShortBio) {
      return updateInfoProfile(
        { profileId, profile_name, short_bio: short_bio ?? '' },
        {
          onSuccess: (res) => {
            const newProfile = omit(res, ['user', 'avatar_url', 'banner_url', 'communities']);
            updateUser({ main_profile: { ...user?.main_profile, ...newProfile } });
            setEditMode(false);
          },
        },
      );
    } else return setEditMode(false);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    reset();
  };

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form" display="flex" flexDirection="column" gap={2}>
      <Box
        height={{ xs: '70px', sm: '140px' }}
        sx={(theme) => ({
          borderRadius: theme.spacing(1),
          position: 'relative',
          boxShadow: theme.yethosCustoms.shadows.card,
          backgroundColor: theme.palette.info.contrastText,
          overflow: 'hidden',
        })}
      >
        {hero}
      </Box>
      <Box position="relative" display="flex" alignItems="center" gap={3}>
        {avatar}
        <Box display="flex" width="100%" flexDirection="column" gap={2}>
          <Box display="flex" alignItems="center" gap={2}>
            {!editMode ? (
              <>
                {fullname}
                <IconButton
                  size="small"
                  color="primary"
                  sx={(theme) => ({
                    backgroundColor: theme.palette.info.main,
                    '&:hover': { backgroundColor: theme.palette.primary.light },
                  })}
                  component="label"
                  onClick={handleOpenEdit}
                >
                  <EditOutlinedIcon />
                </IconButton>
              </>
            ) : (
              <>
                <TextField
                  error={!!`${get(errors, 'profile_name.message', '')}`}
                  helperText={`${get(errors, 'profile_name.message', '')}`}
                  size="small"
                  label="Nome"
                  disabled={isLoading}
                  inputProps={{ maxLength: 30 }}
                  placeholder="Nome"
                  {...register('profile_name')}
                />
                {!matchMobile && (
                  <Box display="flex" gap={1}>
                    <LoadingButton
                      loading={isLoading}
                      disabled={isLoading}
                      variant="contained"
                      size="small"
                      type="submit"
                    >
                      Salvar
                    </LoadingButton>
                    <Button disabled={isLoading} size="small" variant="outlined" onClick={handleCancelEdit}>
                      Cancelar
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Box>
          {!editMode ? (
            <>{about}</>
          ) : (
            <Box maxWidth="840px" display="flex" flexDirection="column" gap={1}>
              <TextField
                multiline
                disabled={isLoading}
                rows={1}
                id="short_bio"
                label="Sobre"
                placeholder="Escreva sobre voce."
                error={!!`${get(errors, 'short_bio.message', '')}`}
                helperText={`${get(errors, 'short_bio.message', '')}`}
                inputProps={{ maxLength: 90 }}
                size="small"
                {...register('short_bio')}
              />
            </Box>
          )}
          {matchMobile && editMode && (
            <Box display="flex" gap={1}>
              <LoadingButton loading={isLoading} disabled={isLoading} variant="contained" size="small" type="submit">
                Salvar
              </LoadingButton>
              <Button disabled={isLoading} size="small" variant="outlined" onClick={handleCancelEdit}>
                Cancelar
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MainProfileHeader;
