import React from 'react';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import DeleteIcon from '@mui/icons-material/Delete';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import ChatIcon from '@mui/icons-material/Chat';
import ReplyIcon from '@mui/icons-material/Reply';
import { Divider, Theme, ListItemIcon, ListItemText } from '@mui/material';

import { createSearchParams, useNavigate } from 'app-router';
import EditIcon from 'assets/icons/EditIcon';
import { Box, Button, MoreIconMenu } from 'common/components';
import { TTopic } from 'modules/communities/screens/posts/topic.types';
import DeleteTopic from 'modules/communities/screens/posts/components/DeleteTopic';
import useNotification from 'providers/NotificationProvider';
import useAuthContext from 'providers/AuthProvider';
import { getRoutePathname } from 'routes/getRoutePathname';

const styleMobileButton = (theme: Theme) => ({
  height: '24px',
  minWidth: '24px',
  '&:hover': { backgroundColor: 'transparent' },
  fontSize: '12px',
  [theme.breakpoints.up('md')]: { display: 'none' },
});

const styleWebButton = (theme: Theme) => ({
  height: '24px',
  '&:hover': { backgroundColor: 'transparent' },
  fontSize: '12px',
  [theme.breakpoints.down('md')]: { display: 'none' },
});

const appRoute = 'https://app.yethos.io';

export const styleStartIcon = { width: '14px', height: '14px' };

type TTopicButtons = {
  expanded?: boolean;
  handleShowMore?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleEdit: () => void;
  topic: TTopic;
};

const TopicButtons = ({ expanded, handleShowMore, handleEdit, topic }: TTopicButtons) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [favorite, setFavorite] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);

  const topicRoute = getRoutePathname.CommunitiesDetailsPostsDetails(topic.community, topic.channel, topic._id);

  const allowed = !!topic ? user?.main_profile._id === topic?.created_by._id : false;

  const { toast } = useNotification();

  const handleFavorite = () => {
    setFavorite((pre) => !pre);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleSeeComment = () => {
    navigate({
      pathname: getRoutePathname.CommunitiesDetailsPostsDetails(topic.community, topic.channel, topic._id),
      search: `?${createSearchParams({ comments: 'true' })}`,
    });
  };

  const handleStopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const copyClipBoard = () => {
    const linkToCommunity = `${appRoute + topicRoute}`;
    navigator.clipboard.writeText(linkToCommunity).then(() => {
      toast({ title: 'Link copiado!' });
    });
  };

  const optionsMoreMenu = [
    {
      label: (
        <>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </>
      ),
      onClick: handleEdit,
      shouldRender: allowed,
    },
    {
      label: (
        <>
          <ListItemIcon>
            <DeleteIcon sx={{ width: '12px', height: '12px' }} />
          </ListItemIcon>
          <ListItemText>Deletar</ListItemText>
        </>
      ),
      onClick: handleOpenDelete,
      shouldRender: allowed,
    },
    {
      label: (
        <>
          <ListItemIcon>
            <ReportGmailerrorredIcon sx={{ width: '12px', height: '12px' }} />
          </ListItemIcon>
          <ListItemText>Denunciar</ListItemText>
        </>
      ),
      onClick: () => alert('Denunciar'),
      shouldRender: true,
    },
  ];

  return (
    <Box onClick={handleStopPropagation} sx={{ cursor: 'default' }} display="flex" gap={1} alignItems="flex-end">
      <DeleteTopic id={topic._id} open={openDelete} handleClose={handleCloseDelete} />
      {/*Web Button */}
      {!!handleShowMore && (
        <>
          <Button
            onClick={handleShowMore}
            startIcon={
              expanded ? <UnfoldLessIcon sx={{ ...styleStartIcon }} /> : <UnfoldMoreIcon sx={{ ...styleStartIcon }} />
            }
            variant="text"
            sx={styleWebButton}
          >
            {expanded ? 'Mostrar menos' : 'Mostrar conteúdo'}
          </Button>
          {/* Mobile Button */}
          <Button onClick={handleShowMore} variant="text" sx={styleMobileButton}>
            {expanded ? <UnfoldLessIcon sx={{ ...styleStartIcon }} /> : <UnfoldMoreIcon sx={{ ...styleStartIcon }} />}
          </Button>
          <Divider orientation="vertical" flexItem />
          {/* Web Button */}
          <Button
            onClick={handleSeeComment}
            startIcon={<ChatIcon sx={{ ...styleStartIcon }} />}
            variant="text"
            sx={styleWebButton}
          >
            Comentários
          </Button>
          {/* Mobile Button */}
          <Button variant="text" sx={styleMobileButton}>
            <ChatIcon sx={{ ...styleStartIcon }} />
          </Button>
        </>
      )}
      <Button
        onClick={copyClipBoard}
        variant="text"
        sx={{ height: '24px', minWidth: '24px', '&:hover': { backgroundColor: 'transparent' }, fontSize: '12px' }}
      >
        <ReplyIcon sx={{ ...styleStartIcon }} />
      </Button>
      <Button
        onClick={handleFavorite}
        variant="text"
        sx={{ height: '24px', minWidth: '24px', '&:hover': { backgroundColor: 'transparent' }, fontSize: '12px' }}
      >
        {favorite ? (
          <BookmarkOutlinedIcon sx={{ ...styleStartIcon }} />
        ) : (
          <BookmarkBorderOutlinedIcon sx={{ ...styleStartIcon }} />
        )}
      </Button>
      <MoreIconMenu options={optionsMoreMenu} side="left" />
    </Box>
  );
};

export default TopicButtons;
