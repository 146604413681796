import React from 'react';
import { DebouncedFunc } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';

import { Search } from 'common/components/SearchWithTransition';
import { Box } from 'common/components';

type TFilterTopics = {
  debounceFn: DebouncedFunc<(query: string) => void>;
};

const FilterTopics = ({ debounceFn }: TFilterTopics) => {
  const [query, setQuery] = React.useState('');
  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuery(event.target.value);
    debounceFn(event.target.value);
  };

  return (
    <Box width="fit-content" display="flex" gap={2} marginTop={1} marginBottom={1}>
      <Search.Container sx={{ bgcolor: 'white' }}>
        <Search.IconWrapper>
          <SearchIcon sx={(theme) => ({ color: theme.palette.info.dark, width: '20px', height: '20px' })} />
        </Search.IconWrapper>
        <Search.InputBase value={query} onChange={handleChangeQuery} placeholder="Procurar..." />
      </Search.Container>
    </Box>
  );
};

export default FilterTopics;
