import { z } from 'zod';

export enum HelperMessagesEnum {
  MAX_CHARACTERS = 'Você atingiu o número máximo de caracteres',
  MAX_TAGS = 'Você atingiu o número máximo de tags.',
}

export enum InputValueEnum {
  DESCRIPTION = 'description',
  NAME = 'name',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
}

export enum InputErrorMessageEnum {
  DESCRIPTION = 'description.message',
  NAME = 'name.message',
  FIRST_NAME = 'first_name.message',
  LAST_NAME = 'last_name.message',
}

export const validEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const validationZod = {
  email: z.string({ required_error: 'E-mail é obrigatório.' }).email({ message: 'E-mail inválido.' }),
  required: z.string().min(1, { message: 'Senha é obrigatório.' }),
  password: z.string({ required_error: 'Senha é obrigatório.' }).refine(
    (value) => {
      const has12Characters = /^.{12,}/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSymbole = /[!@#$%^&*(),.?":{}|<>_-]/.test(value);
      let validConditions = 0;
      const numberOfMustBeValidConditions = 5;
      const conditions = [has12Characters, hasLowerCase, hasUpperCase, hasNumber, hasSymbole];
      conditions.forEach((condition) => (condition ? validConditions++ : null));
      if (validConditions >= numberOfMustBeValidConditions) {
        return true;
      }
      return false;
    },
    {
      message:
        ' Senha deve ter pelo menos 12 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial (@!?*#)',
    },
  ),
};

//SCHEMAS
