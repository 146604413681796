import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, IconButton, Divider } from '@mui/material';
import { useForm, useWatch, FormProvider } from 'react-hook-form';

import { Text, Box, Button, Image, LoadingButton, Avatar } from 'common/components';
import CropImage from 'common/components/CropImage';
import { getImageUrl, getImgFile, getImgSrc } from 'common/utils/imageResource';
import {
  useUpdateProfileAvatarByIdAPI,
  useUpdateProfileBannerByIdAPI,
} from 'modules/accounts/common/apis/profiles.api';
import { TUpdateProfileImgsForm, UpdateProfileImgsSchema } from '../profile.schemas';

type EditProfileDialogProps = {
  open: boolean;
  handleClose: () => void;
  avatarUrl: string;
  bannerUrl: string;
  refId: string;
};

const EditProfileDialog = ({ open, handleClose, avatarUrl, bannerUrl, refId }: EditProfileDialogProps) => {
  const [openCrop, setOpenCrop] = React.useState('none');
  const [originalImage, setOriginalImage] = React.useState(avatarUrl);

  const { mutate: updateAvatar, isLoading: updatingAvatarLoading } = useUpdateProfileAvatarByIdAPI(refId);
  const { mutate: updateBanner, isLoading: updatingBannerLoading } = useUpdateProfileBannerByIdAPI(refId);

  const isLoading = updatingAvatarLoading || updatingBannerLoading;

  const formMethods = useForm<TUpdateProfileImgsForm>({
    resolver: zodResolver(UpdateProfileImgsSchema),
    defaultValues: {
      avatarProfile: avatarUrl,
      bannerUrl: bannerUrl,
    },
  });

  const { register, control, reset } = formMethods;
  const banner = useWatch({ control, name: 'bannerUrl' });
  const urlBanner = getImgSrc(banner);
  const avatar = useWatch({ control, name: 'avatarProfile' });
  const urlAvatar = getImgSrc(avatar);

  const handleCloseCrop = () => {
    setOpenCrop('none');
  };

  const handleCancel = (input: 'avatar' | 'banner') => {
    if (input === 'avatar') {
      reset({ bannerUrl: banner, avatarProfile: avatarUrl });
    }
    if (input === 'banner') {
      reset({ avatarProfile: avatar, bannerUrl });
    }
  };

  const handleSaveImg = (input: 'avatar' | 'banner') => {
    const avatarFile = getImgFile(avatar);
    const bannerFile = getImgFile(banner);
    if (input === 'avatar') {
      updateAvatar(avatarFile, {
        onSuccess: () => {
          reset({ bannerUrl: banner, avatarProfile: urlAvatar });
          setOriginalImage(avatarUrl);
        },
      });
    }
    if (input === 'banner') {
      updateBanner(bannerFile, {
        onSuccess: () => {
          reset({ avatarProfile: avatar, bannerUrl: urlBanner });
        },
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            gap: 2,
            padding: 2,
            minWidth: '300px',
            maxWidth: '920px',
            width: '100%',
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Text variant="h3">Alterar foto</Text>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon sx={{ width: '24px', height: '24px' }} />
          </IconButton>
        </Box>
        <Divider sx={{ width: '100%', color: 'info.dark' }} />
        <Box display="flex" flexDirection="column" gap={1}>
          <Text variant="h4">Foto do avatar</Text>
          <Avatar profileName={''} sx={{ width: '120px', height: '120px' }} src={urlAvatar} />

          <Box display="flex" gap={2}>
            {typeof avatar !== 'string' ? (
              <>
                <Button disabled={isLoading} variant="outlined" size="small" onClick={() => handleCancel('avatar')}>
                  Cancelar
                </Button>
                <Button
                  disabled={isLoading}
                  variant="outlined"
                  size="small"
                  onClick={() => setOpenCrop('avatarProfile')}
                >
                  Editar
                </Button>
                <LoadingButton
                  loading={isLoading}
                  size="small"
                  variant="contained"
                  onClick={() => handleSaveImg('avatar')}
                >
                  Salvar
                </LoadingButton>
              </>
            ) : (
              <Button variant="outlined" size="small" component="label">
                Alterar imagem
                <input
                  hidden
                  accept="image/png, image/jpg, image/jpeg"
                  type="file"
                  {...register('avatarProfile')}
                  onChange={(e) => {
                    register('avatarProfile').onChange(e);
                    if (!!e.target?.files?.[0]) {
                      setOriginalImage(getImageUrl(e.target.files[0]));
                    }
                    setOpenCrop('avatarProfile');
                  }}
                />
              </Button>
            )}
            <CropImage handleClose={handleCloseCrop} type="avatarProfile" open={openCrop} image={originalImage} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Text variant="h4">Foto da capa</Text>
          <Box
            width="calc(100% * .788)"
            height={{ xs: '60px', sm: '90px' }}
            bgcolor="white"
            sx={(theme) => ({ border: `1px solid ${theme.palette.primary.light}` })}
            overflow="hidden"
          >
            <Image src={urlBanner ?? ''} />
          </Box>
          <Box display="flex" gap={2}>
            {typeof banner !== 'string' ? (
              <>
                <Button disabled={isLoading} variant="outlined" size="small" onClick={() => handleCancel('banner')}>
                  Cancelar
                </Button>
                <Button disabled={isLoading} variant="outlined" size="small" onClick={() => setOpenCrop('bannerUrl')}>
                  Editar
                </Button>
                <LoadingButton
                  loading={isLoading}
                  size="small"
                  variant="contained"
                  onClick={() => handleSaveImg('banner')}
                >
                  Salvar
                </LoadingButton>
              </>
            ) : (
              <Button variant="outlined" size="small" component="label">
                Alterar imagem
                <input
                  hidden
                  accept="image/png, image/jpg, image/jpeg"
                  type="file"
                  onChange={(e) => {
                    register('bannerUrl').onChange(e);
                    if (!!e.target?.files?.[0]) {
                      setOriginalImage(getImageUrl(e.target.files[0]));
                    }
                    setOpenCrop('bannerUrl');
                  }}
                />
              </Button>
            )}
            <CropImage handleClose={handleCloseCrop} type="bannerUrl" open={openCrop} image={originalImage} />
          </Box>
        </Box>
        <Box />
      </Dialog>
    </FormProvider>
  );
};

export default EditProfileDialog;
