import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import TabPanel from './TabPanel';

interface TabProps {
  id: string;
  title: string;
  component: React.ReactNode;
}

interface TabListProps {
  tabs: TabProps[];
  valueParent?: number;
  setValueParent?: (value: any) => void;
  initialIndex?: number;
  fullWidth?: boolean;
  justifyContent?: 'center' | 'flex-start' | 'flex-end';
  withoutBorder?: boolean;
  sticky?: boolean;
}

const TabList = ({
  tabs,
  valueParent,
  setValueParent,
  initialIndex = 0,
  fullWidth,
  justifyContent,
  withoutBorder,
  sticky,
}: TabListProps) => {
  const [value, setValue] = React.useState(initialIndex);
  const onChangeTab = (_event: React.SyntheticEvent, value: number) => {
    if (setValueParent) {
      setValueParent(value);
      return;
    }
    setValue(value);
  };

  return (
    <Box display="flex" width="100%" sx={{ alignItems: { xs: 'center', sm: 'flex-start' } }} flexDirection="column">
      <Box
        display="flex"
        zIndex={sticky ? 1000 : 0}
        width="100%"
        position={sticky ? 'sticky' : undefined}
        top={sticky ? { xs: '47px', sm: `${56 + 47}px` } : undefined}
        justifyContent={justifyContent ? justifyContent : undefined}
        sx={(theme) => ({
          width: '100%',
          bgcolor: theme.palette.info.light,
          borderBottom: withoutBorder ? undefined : `1px solid ${theme.palette.primary.light}`,
        })}
      >
        <Tabs
          value={valueParent ? valueParent : value}
          onChange={onChangeTab}
          textColor="primary"
          indicatorColor="primary"
          aria-label="tab"
          sx={{
            '& .MuiTabs-flexContainer': {
              width: fullWidth ? '100%' : undefined,
              justifyContent: fullWidth ? 'space-between' : undefined,
            },
            '&.MuiTabs-root': {
              width: fullWidth ? '100%' : undefined,
              minHeight: 0,
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              sx={(theme) => ({
                textTransform: 'none',
                '&.MuiButtonBase-root': {
                  minWidth: '30px',
                  padding: '8px 16px',
                  height: 'auto',
                  fontSize: '1rem',
                  lineHeight: '1.5rem',
                  minHeight: 0,
                },
                [theme.breakpoints.down('lg')]: {
                  '&.MuiButtonBase-root': {
                    minWidth: '50px',
                    padding: '8px 16px',
                    [theme.breakpoints.down('sm')]: {
                      minWidth: '30px',
                      padding: '8px 10px',
                      height: 'auto',
                      fontSize: '14px',
                      lineHeight: '1.2rem',
                    },
                  },
                },
              })}
              key={tab.id}
              label={tab.title}
            />
          ))}
        </Tabs>
      </Box>

      <Box display="flex" width="100%" paddingLeft={0.5} paddingRight={0.5}>
        {tabs.map((tab, idx) => (
          <TabPanel key={`_idPanel_${tab.id}`} index={idx} value={valueParent ? valueParent : value}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default TabList;
