const LogoCircle = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3715_30908)">
        <path
          d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20ZM23.9251 21.9799L23.819 22.0879V22.2406V28.9766C23.819 31.2163 22.0341 33.032 19.8323 33.032C17.6305 33.032 15.8456 31.2163 15.8456 28.9766V21.9103V21.7575L15.7395 21.6495L9.62392 15.4286C11.9636 17.163 15.7992 18.7226 19.9731 14.4857L19.9947 14.5077L20.2509 14.247L26.2388 8.1558C27.7957 6.57205 30.32 6.57205 31.8768 8.1558C33.4338 9.73955 33.4338 12.3073 31.8768 13.8911L23.9251 21.9799ZM14.2737 7.64527L19.9947 13.4649L25.7263 7.63442C27.5663 5.76271 30.5494 5.76271 32.3894 7.63442C34.2293 9.50612 34.2293 12.5407 32.3894 14.4124L24.5438 22.3934V28.9766C24.5438 31.6235 22.4344 33.7694 19.8323 33.7694C17.2302 33.7694 15.1207 31.6235 15.1207 28.9766V22.0629L7.61061 14.4233C5.77064 12.5516 5.77064 9.51697 7.61061 7.64527C9.45058 5.77356 12.4338 5.77356 14.2737 7.64527Z"
          fill="url(#paint0_linear_3715_30908)"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_3715_30908" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DD6B20" />
          <stop offset="0.588542" stopColor="#DD6B20" />
          <stop offset="1" stopColor="#F6AD55" />
        </linearGradient>
        <clipPath id="clip0_3715_30908">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoCircle;
