import { Tooltip } from '@mui/material';

import { categoriesArr } from 'common/utils/filters.community.constants';
import { TCommunity } from 'modules/communities/common/types/community.types';

import Box from './Box';
import Card from './Card';
import Text from './Text';
import Image from './Image';
import CompleteAvatar from './CompleteAvatar';

type CommunityCardProps = {
  community: TCommunity;
  onClickCommunity: () => void;
};

const CommunityCard = ({ community, onClickCommunity }: CommunityCardProps) => {
  const categoryLabel = categoriesArr.find((item) => item.value === community.category)?.label ?? 'Categoria';
  const communityLength = community.members_count ?? 590;
  const communityMembers =
    communityLength <= 1
      ? 1
      : communityLength > 0 && communityLength < 1000
      ? communityLength
      : (communityLength / 1000).toFixed(1) + 'K';

  return (
    <Card
      onClick={onClickCommunity}
      sx={(theme) => ({
        alignItems: 'flex-start',
        overflow: 'hidden',
        gap: 1,
        padding: 0,
        cursor: 'pointer',
        '&:hover': {
          boxShadow: theme.yethosCustoms.shadows.cardHover,
        },
        '&:active': {
          cursor: 'default',
          border: `1px solid ${theme.palette.info.dark}`,
        },
        minHeight: '294px',
        width: '280px',
        maxWidth: '280px',
      })}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          position: 'relative',
          width: '100%',
          gap: 1,
          flex: 1,
        }}
      >
        <Box sx={{ width: '100%', height: 'calc(280px / 6)', position: 'absolute' }}>
          <Image src={community.banner_url} />
        </Box>

        <Box
          sx={{
            padding: '16px 16px 0px',
            zIndex: 1,
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'auto',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={(theme) => ({
              borderRadius: theme.spacing(1),
              minWidth: '160px',
              maxWidth: '160px',
              minHeight: '90px',
              maxHeight: '90px',
              border: `1px solid ${theme.palette.primary.light}`,
              overflow: 'hidden',
              backgroundColor: 'white',
              zIndex: 10,
            })}
          >
            <Image src={community.avatar_url} />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            gap: 0.5,
            padding: '0px 16px',
          }}
        >
          <Text variant="h4">{community.name}</Text>
          <Text variant="body1">
            {categoryLabel} <span style={{ fontWeight: 700, fontSize: '1rem' }}>·</span> {communityMembers}{' '}
            {communityLength <= 1 ? 'Membro' : 'Membros'}
          </Text>
          <Text
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical',
            }}
          >
            {community.description}
          </Text>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0px 16px 16px',
            flex: 1,
            gap: 1,
          }}
        >
          <Text variant="caption">Criado por</Text>
          <Tooltip arrow title="Ver perfil">
            <Box display="flex" alignItems="center" gap={1}>
              <CompleteAvatar
                fullName={community.founder.profile_name}
                profileId={community.founder._id}
                src={community.founder.avatar_url}
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Card>
  );
};

export default CommunityCard;
