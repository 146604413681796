import React from 'react';
import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => {
  return (
    <Box sx={{ role: 'tabpanel' }} hidden={value !== index} display={value === index ? 'flex' : 'none'} width={'100%'}>
      {children}
    </Box>
  );
};

export default TabPanel;
