import React from 'react';
import { debounce } from 'lodash';
import { useTheme, useMediaQuery } from '@mui/material';

import { useNavigate, useParams } from 'app-router';
import { Box, Card, Text } from 'common/components';
import { orderTopicsByRecently } from 'common/utils/functions';
import { useGetTopicsByChannelIdAPI } from 'modules/communities/screens/posts/post.api';
import TopicCard from 'modules/communities/common/components/TopicCard';
import SelectFilter from 'modules/communities/common/components/SelectFilter';
import { getRoutePathname } from 'routes/getRoutePathname';

import CreateTopicButton from './CreateTopicButton';
import FilterTopics from './FilterTopics';

const filters = [
  { label: 'Todos', value: 'all' },
  {
    label: 'Últimos 7 dias',
    value: '7',
  },
  {
    label: 'Últimos 14 dias',
    value: '14',
  },
  {
    label: 'Últimos 30 dias',
    value: '30',
  },
];

const TopicsTabChannel = () => {
  const [search, setSearch] = React.useState('');
  const [filter, setFilter] = React.useState(filters[0]);
  const { id = '', channelId = '' } = useParams();
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { data, isLoading } = useGetTopicsByChannelIdAPI(channelId);

  const posts = data?.items ?? [];
  const orderedPostsByRecently = orderTopicsByRecently(posts);

  const handleCreateTopic = () => {
    navigate(getRoutePathname.CommunitiesDetailsPostsAdd(id, channelId));
  };

  const handleOnChangeSearch = (query: string) => {
    setSearch(query.toLowerCase());
  };

  const handleChangeFilter = (value: { label: string; value: string }) => {
    setFilter(value);
  };

  const debounceFn = debounce(handleOnChangeSearch, 400);

  const filteredTopicsByDays =
    orderedPostsByRecently.filter((topic) => {
      const nowDate = new Date();
      const topicDate = new Date(topic.created_at);
      const differenceDate = nowDate.getTime() - topicDate.getTime();
      const minutesPassed = Math.floor(differenceDate / (1000 * 60));
      return filter.value === 'all' ? true : minutesPassed <= Number(filter.value) * 24 * 60;
    }) ?? [];

  const filteredTopics = filteredTopicsByDays.filter((topic) => topic.title.toLowerCase().includes(search)) ?? [];

  return (
    <Box display="flex" flexDirection="column" gap={1} width="100%" marginTop={1}>
      <Box
        sx={(theme) => ({
          padding: 0,
          width: '100%',
          [theme.breakpoints.down('sm')]: { gap: 1, display: 'flex', flexDirection: 'column' },
        })}
      >
        <CreateTopicButton handleCreateTopic={handleCreateTopic} />
        <Box width="fit-content" alignItems="center" display="flex" gap={2} marginTop={1} marginBottom={1}>
          <SelectFilter filtered={filter} filters={filters} handleChangeFilter={handleChangeFilter} />

          <FilterTopics debounceFn={debounceFn} />
        </Box>
        {filteredTopics.length > 0 ? (
          !matchMobile && !isLoading ? (
            <Card sx={{ padding: 0, gap: 0 }}>
              {filteredTopics?.map((post, idx) => (
                <Box
                  key={`post_mural_${post._id}`}
                  sx={(theme) => ({
                    borderBottom:
                      idx < filteredTopics.length - 1 ? `1px solid ${theme.palette.primary.light}` : undefined,
                    borderRadius: 0,
                  })}
                >
                  <TopicCard channelId={channelId} post={post} />
                </Box>
              ))}
            </Card>
          ) : (
            !isLoading &&
            orderedPostsByRecently.map((post, index) => (
              <Card
                key={`post_mural_${post._id}_${index}`}
                sx={() => ({
                  padding: 0,
                  gap: 0,
                })}
              >
                <TopicCard channelId={channelId} post={post} />
              </Card>
            ))
          )
        ) : (
          <Text>
            {!!search || filter.value !== 'all' ? 'Não foi encontrado nenhum tópico.' : 'Não há tópicos neste canal.'}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default TopicsTabChannel;
