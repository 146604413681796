import { QueryClient } from 'react-query';

import { RouteObject } from 'app-router';
import { ERoutesCommunities } from 'routes/ERoutesCommunities';

import Communities, { loaderInfiniteCommunities } from './Communities.screen';
import CommunityDetails, { loaderCommunityDetails } from './CommunityDetails.screen';
import CreateCommunity from './CreateCommunity.screen';
import { channelRoutes } from '../channel';
import { postRoutes } from '../posts';

const communityRoutes: (queryClient: QueryClient) => RouteObject = (queryClient) => ({
  path: ERoutesCommunities.Communities,
  children: [
    {
      index: true,
      element: <Communities />,
      loader: loaderInfiniteCommunities(queryClient),
    },
    {
      path: ERoutesCommunities.Communities_Add,
      element: <CreateCommunity />,
    },
    {
      path: ERoutesCommunities.Communities_Details,
      children: [
        {
          index: true,
          element: <CommunityDetails />,
          loader: loaderCommunityDetails(queryClient),
        },
        ...channelRoutes(queryClient),
        ...postRoutes(queryClient),
      ],
    },
  ],
});
export default communityRoutes;
