import React from 'react';
import { Slider, Dialog, DialogContent } from '@mui/material';
import { Point, Area } from 'react-easy-crop/types';
import Cropper from 'react-easy-crop';
import { useFormContext } from 'react-hook-form';

import getCroppedImg from 'common/utils/cropImage';

import Text from './Text';
import Button from './Button';
import Box from './Box';

type TCropImage = {
  image: string;
  open: string;
  handleClose: () => void;
  type: 'avatarUrl' | 'bannerUrl' | 'avatarProfile';
};

const CropImage = ({ image, handleClose, open, type }: TCropImage) => {
  const { setValue } = useFormContext();
  const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area | null>(null);

  const aspect = type === 'avatarUrl' ? 16 / 9 : type === 'bannerUrl' ? 9 / 1 : 1 / 1;
  const cropShape = type === 'avatarProfile' ? 'round' : 'rect';

  const onCropComplete = React.useCallback((_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = React.useCallback(async () => {
    if (!croppedAreaPixels) return image;
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      const fileImage = croppedImage ? new File([croppedImage], 'image.png', { type: 'image/png' }) : null;
      setValue(type, fileImage);
      handleClose();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  return (
    <Dialog open={open === type} onClose={handleClose}>
      <DialogContent>
        <Box position="relative" width={{ xs: '250px', sm: '400px' }} height="250px">
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            cropShape={cropShape}
            aspect={aspect}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </Box>
        <Box>
          <Box>
            <Text variant="overline">Zoom</Text>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(_e, zoom) => setZoom(Number(zoom))}
            />
          </Box>
          <Box display="flex" gap={2}>
            <Button onClick={handleClose} variant="outlined">
              Cancelar
            </Button>
            <Button onClick={showCroppedImage} variant="contained">
              Confirmar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CropImage;
