import React from 'react';
import { TextField } from '@mui/material';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { Avatar, Box, Button } from 'common/components';
import useAuthContext from 'providers/AuthProvider';

import CommentButtons from './CommentButtons';
import { CreateCommentSchema, TCreateCommentForm } from '../post.schemas';

type TTopicCommentButton = {
  idScroll: string;
  open?: boolean;
};

const TopicCommentButton = ({ idScroll, open = false }: TTopicCommentButton) => {
  const { user } = useAuthContext();
  const formMethods = useForm<TCreateCommentForm>({
    resolver: zodResolver(CreateCommentSchema),
    defaultValues: {
      file: '',
      comment: '',
    },
  });
  const { register, handleSubmit } = formMethods;

  const onSubmit: SubmitHandler<TCreateCommentForm> = (formData) => {
    console.log(formData);
  };

  const [openComment, setOpenComment] = React.useState(open);

  const handleOpen = () => {
    setOpenComment(true);
  };

  const handleClose = () => {
    setOpenComment(false);
  };

  return openComment ? (
    <FormProvider {...formMethods}>
      <Box
        id={idScroll}
        onSubmit={handleSubmit(onSubmit)}
        component="form"
        width="100%"
        display="flex"
        flexDirection="column"
      >
        <TextField
          multiline
          autoFocus
          placeholder="Responder"
          sx={() => ({
            width: '100%',
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '8px 8px 8px 8px',
              border: `none`,
            },
          })}
          size="small"
          {...register('comment')}
        />
        <CommentButtons type="comment" handleClose={handleClose} />
      </Box>
    </FormProvider>
  ) : (
    <Box
      id={idScroll}
      sx={{
        display: 'flex',
        gap: 2,
        padding: '10px 0px',
        alignItems: 'center',
      }}
    >
      <Avatar
        sx={{ width: '36.5px', height: '36.5px' }}
        src={user?.main_profile?.avatar_url ?? ''}
        profileName={user?.first_name ?? ''}
      />
      <Button
        onClick={handleOpen}
        variant="outlined"
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-start',
          color: 'rgba(0, 0, 0, 0.36)',
          bgcolor: 'white',
          '&:hover': { backgroundColor: 'white' },
        }}
      >
        Comentar
      </Button>
    </Box>
  );
};

export default TopicCommentButton;
