import React from 'react';
import { List } from '@mui/material';

import { Box, Text } from 'common/components';

interface ListCommunitiesNavbarProps {
  title: string;
  items: React.ReactNode;
}

const ListCommunitiesNavbar = ({ title, items }: ListCommunitiesNavbarProps) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" gap={1}>
      <Text fontWeight={700} fontSize=".75rem" sx={{ color: 'info.dark' }}>
        {title}
      </Text>
      <List component="div" disablePadding>
        {items}
      </List>
    </Box>
  );
};

export default ListCommunitiesNavbar;
