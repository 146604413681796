import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

const CommunityAppShellMobile = React.lazy(() => import('./CommunityAppShellMobile'));
const CommunityAppShell = React.lazy(() => import('./CommunityAppShell'));

type TResponsiveCommunityShell = {
  children: React.ReactNode;
};

const ResponsiveCommunityShell = ({ children }: TResponsiveCommunityShell) => {
  const theme = useTheme();
  const matchMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return matchMobile ? (
    <CommunityAppShellMobile>{children}</CommunityAppShellMobile>
  ) : (
    <CommunityAppShell>{children}</CommunityAppShell>
  );
};

export default ResponsiveCommunityShell;
