import { faker } from '@faker-js/faker';
import { categoriesArr, typesArr } from 'common/utils/filters.community.constants';

import { createRandomCommunityLanguage } from './languages/mock';
import { JSONContent } from '@tiptap/react';

export const enum network_types {
  facebook = 'facebook',
  twitter = 'twitter',
  youtube = 'youtube',
}

export interface UserProps {
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
  gender: string;
  date_of_birth: Date;
  created_at: Date;
  _id: string;
  accepted_terms_at: Date;
  is_deleted: boolean;
  last_login_at: Date;
  password: string;
  status: 'active' | 'waiting-activation';
  updated_at: Date;
  user_activation: { code: string; activated_at: Date };
}

export interface ReplyProps {
  user: UserProps;
  content: string;
  date: Date;
  id: string;
  replies?: string[];
}

export interface CommentProps {
  user: UserProps;
  content: string;
  img?: string;
  date: Date;
  reply: ReplyProps[];
  id: string;
  upVotes: number;
  downVotes: number;
}

export interface PostProps {
  user: UserProps;
  title: string;
  description: JSONContent;
  upVotes: number;
  downVotes: number;
  comments: CommentProps[];
  share: number;
  id: string;
  date: Date;
  files: string[];
}

export interface CommunityPropsMOCK {
  name: string;
  description: string;
  highlight: string | null;
  avatar_url: string | null;
  banner_url: string | null;
  tags?: string[];
  founder: UserProps;
  posts: PostProps[];
  admins: UserProps[];
  moderators: UserProps[];
  usersMembers: UserProps[];
  most_active_members: UserProps[];
  related_communities: CommunityPropsMOCK[];
  resources: File[];
  category: (typeof categoriesArr)[0];
  type: (typeof typesArr)[0];
  channels: ChannelProps[];
  settings: {
    allow_minor_members: boolean;
    allow_unverifies_users: boolean;
    is_channel_format: boolean;
    topics: {
      displayLastPost: boolean;
    };
    members: {
      canCreateTopics: boolean;
      canCreatePolls: boolean;
    };
    billing: {
      join_fee: number;
      monthly_Fee: number;
    };
  };
  social_network: { type: network_types; url: string }[];
  date: Date;
  favoritePosts: string[];
  _id: string;
  invite: boolean;
  firstTime: boolean;
}

export interface ChannelProps {
  name: string;
  description: string;
  avatar: string | null;
  posts: PostProps[];
  owner: UserProps;
  settings: {
    allow_minor_members: boolean;
    allow_unverifies_users: boolean;
    is_channel_format: boolean;
    topics: {
      displayLastPost: boolean;
    };
    members: {
      canCreateTopics: boolean;
      canCreatePolls: boolean;
    };
    billing: {
      join_fee: number;
      monthly_Fee: number;
    };
  };
  date: Date;
  favoritePosts: string[];
  id: string;
}

export function createRandomComment(): CommentProps {
  return {
    user: createRandomUser(),
    content: faker.random.words(15),
    date: faker.date.recent(),
    reply: Array.from({ length: Math.floor(Math.random() * 14) }, () => ({
      content: faker.random.words(14),
      user: createRandomUser(),
      id: faker.datatype.uuid(),
      date: faker.date.recent(),
    })),
    upVotes: Math.floor(Math.random() * 40),
    downVotes: Math.floor(Math.random() * 40),
    id: faker.datatype.uuid(),
  };
}

export function createRandomHighlight() {
  return faker.random.words(20);
}

// export function createRandomPost(): PostProps {
//   return {
//     user: createRandomUser(),
//     title: faker.random.words(5),
//     description: faker.random.words(10),
//     upVotes: Math.floor(Math.random() * 40),
//     downVotes: Math.floor(Math.random() * 40),
//     comments: Array.from({ length: Math.floor(Math.random() * 8) }, () => createRandomComment()),
//     share: Math.floor(Math.random() * 10),
//     id: faker.datatype.uuid(),
//     date: faker.date.recent(),
//     files: [],
//   };
// }

export function createRandomUser(): UserProps {
  const first_name = faker.name.firstName();
  const last_name = faker.name.lastName();
  return {
    email: faker.internet.email(),
    first_name,
    last_name,
    full_name: first_name + '' + last_name,
    status: 'active',
    accepted_terms_at: faker.date.past(),
    password: first_name,
    is_deleted: false,
    updated_at: faker.date.past(),
    user_activation: { code: first_name, activated_at: faker.date.past() },
    last_login_at: faker.date.past(),
    gender: faker.name.sexType(),
    date_of_birth: faker.date.past(),
    _id: faker.datatype.uuid(),
    created_at: faker.date.past(),
  };
}

export function createRandomNewUser(): UserProps {
  const first_name = faker.name.firstName();
  const last_name = faker.name.lastName();
  return {
    email: faker.internet.email(),
    first_name,
    last_name,
    full_name: first_name + '' + last_name,
    status: 'active',
    accepted_terms_at: faker.date.past(),
    password: first_name,
    is_deleted: false,
    updated_at: faker.date.past(),
    user_activation: { code: first_name, activated_at: faker.date.past() },
    last_login_at: faker.date.past(),
    gender: faker.name.sexType(),
    date_of_birth: faker.date.past(),
    _id: faker.datatype.uuid(),
    created_at: faker.date.past(),
  };
}

export const communitiesMockedLanguage = Array.from({ length: 4 }, () => createRandomCommunityLanguage());
export const communitiesMockedData = [...communitiesMockedLanguage];
export const user = createRandomNewUser();
