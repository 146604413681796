const LogoMobile = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM19.1401 17.5839L19.0552 17.6703V17.7925V23.1813C19.0552 24.973 17.6273 26.4256 15.8658 26.4256C14.1044 26.4256 12.6765 24.973 12.6765 23.1813V17.5282V17.406L12.5916 17.3196L7.69912 12.3429C9.57088 13.7304 12.6394 14.9781 15.9785 11.5886L15.9958 11.6062L16.2007 11.3976L20.991 6.52464C22.2366 5.25764 24.256 5.25764 25.5014 6.52464C26.747 7.79164 26.747 9.84584 25.5014 11.1129L19.1401 17.5839ZM11.419 6.1162L15.9958 10.7719L20.581 6.10752C22.053 4.61016 24.4395 4.61016 25.9115 6.10752C27.3834 7.60488 27.3834 10.0326 25.9115 11.5299L19.635 17.9147V23.1813C19.635 25.2988 17.9475 27.0155 15.8658 27.0155C13.7842 27.0155 12.0966 25.2988 12.0966 23.1813V17.6503L6.08848 11.5386C4.61652 10.0413 4.61652 7.61356 6.08848 6.1162C7.56048 4.61884 9.94704 4.61884 11.419 6.1162Z"
        fill="url(#paint0_linear_4288_19083)"
      />
      <defs>
        <linearGradient id="paint0_linear_4288_19083" x1="16" y1="0" x2="16" y2="32" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DD6B20" />
          <stop offset="0.588542" stopColor="#DD6B20" />
          <stop offset="1" stopColor="#F6AD55" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoMobile;
