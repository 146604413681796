import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';

import { useParams } from 'app-router';
import { Button, LoadingButton } from 'common/components';

import { useDeleteChannelByIdAPI } from '../channel.api';

type DeleteChannelProps = {
  open: boolean;
  handleClose: () => void;
};

const DeleteChannel = ({ open, handleClose }: DeleteChannelProps) => {
  const { channelId = '' } = useParams();
  const { mutate: deleteChannel, isLoading: isDeleting } = useDeleteChannelByIdAPI();

  const onDelete = () => {
    deleteChannel(
      { channelId },
      {
        onSuccess: () => {
          handleClose();
        },
      },
    );
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deletar Canal</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Após deletar não será possível recuperar essas informações. Tem certeza que deseja fazer isso?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isDeleting} variant="outlined" size="small" onClick={handleClose}>
          Cancelar
        </Button>
        <LoadingButton loading={isDeleting} size="small" variant="contained" color="error" onClick={onDelete}>
          Deletar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteChannel;
