import React from 'react';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';

import { useGetCommunitiesByQueryAPI } from 'modules/communities/screens/community/community.api';
import { Button } from 'common/components';
import SearchMobile from './SearchMobile';

const AutoCompleteHeader = () => {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState('');
  const { data: returnCommunitiesParams } = useGetCommunitiesByQueryAPI(
    {
      q: query,
      page: 0,
      size: 10,
    },
    { keepPreviousData: true, enabled: open },
  );

  const handleOnChangeQuery = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuery(event.target.value);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const cleanQuery = () => {
    setQuery('');
  };

  const communitiesSearchPages = returnCommunitiesParams?.pages ?? [];

  const debounceFn = debounce(handleOnChangeQuery, 400);

  return (
    <>
      <Button
        onClick={handleOpenDialog}
        variant="outlined"
        startIcon={<SearchIcon />}
        sx={{
          minWidth: '50vw',
          fontWeight: 400,
          color: 'info.dark',
          backgroundColor: 'white',
          justifyContent: 'flex-start',
          height: '32px',
        }}
      >
        Pesquisar...
      </Button>

      <SearchMobile
        query={query}
        open={open}
        handleCloseDialog={handleCloseDialog}
        communities={communitiesSearchPages}
        placeholder="Procurar Comunidades"
        debounceFn={debounceFn}
        cleanQuery={cleanQuery}
      />
    </>
  );
};

export default AutoCompleteHeader;
