import { communitiesRoutePathnames } from './ERoutesCommunities';
import { authenticationRoutePathnames } from './ERoutesAuthentication';
import { profileRoutePathnames } from './ERoutesProfile';

export const getRoutePathname = {
  // Authentication
  ...authenticationRoutePathnames,
  // Communities
  ...communitiesRoutePathnames,
  // Profile
  ...profileRoutePathnames,
};
