import { styled } from '@mui/material/styles';

import { Button } from 'common/components';
import { ButtonProps } from 'common/components/Button';

export interface NavCommunityButtonProps extends ButtonProps {
  colorIcon?: 'info' | 'secondary';
  defaultHeight?: boolean;
}

const NavCommunityButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'colorIcon' && prop !== 'defaultHeight',
})<NavCommunityButtonProps>(({ theme, colorIcon, defaultHeight }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  fontSize: '.875rem',
  minWidth: 0,
  lineHeight: 1.25,
  padding: !defaultHeight ? `${theme.spacing(1.25)} ${theme.spacing(2)}` : undefined,
  '& .MuiButtonBase-root': {
    textAlign: 'start',
  },
  '& .MuiSvgIcon-root': {
    backgroundColor: !!colorIcon ? undefined : '#FFF',
    color: colorIcon === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main,
    borderRadius: '4px',
    '&:nth-of-type(1)': {
      fontSize: '20px',
    },

    border: !!colorIcon ? undefined : `1px solid ${theme.palette.info.main}`,
  },
}));

export default NavCommunityButton;
