export enum ERoutesCommunities {
  Communities = 'communities',
  Communities_Add = 'add',
  Communities_Details = ':id',
  Communities_Details_Settings = 'settings',
  Communities_Details_ChannelAdd = 'channel/add',
  Communities_Details_HighlightAdd = 'highlight/add',
  Communities_Details_Channel = 'channel/:channelId',
  Communities_Details_PostsAdd = 'channel/:channelId/posts/add',
  Communities_Details_PostsDetails = 'channel/:channelId/posts/:postId',
  Communities_Details_PostsEdit = 'edit',
}

// TODO find a better name
// this will be used to use on the "navigateTo" function.
export const communitiesRoutePathnames = {
  Communities: () => '/communities',
  CommunitiesAdd: () => '/communities/add',
  CommunitiesDetails: (communityId: string) => `/communities/${communityId}`,
  CommunitiesDetailsSettings: (communityId: string) => `/communities/${communityId}/settings`,
  CommunitiesDetailsChannelAdd: (communityId: string) => `/communities/${communityId}/channel/add`,
  CommunitiesDetailsHighlightAdd: (communityId: string) => `/communities/${communityId}/highlight/add`,
  CommunitiesDetailsChannel: (communityId: string, channelId: string) =>
    `/communities/${communityId}/channel/${channelId}`,
  CommunitiesDetailsPostsAdd: (communityId: string, channelId: string) =>
    `/communities/${communityId}/channel/${channelId}/posts/add`,
  CommunitiesDetailsPostsDetails: (communityId: string, channelId: string, postId: string) =>
    `/communities/${communityId}/channel/${channelId}/posts/${postId}`,
  CommunitiesDetailsPostsEdit: (communityId: string, channelId: string, postId: string) =>
    `/communities/${communityId}/channel/${channelId}/posts/${postId}/edit`,
};
