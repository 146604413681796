import { LoadingButton as LoadingButtonMUI, LoadingButtonProps as MUILoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material/styles';

interface LoadingButtonProps extends MUILoadingButtonProps {
  gradient?: boolean;
}

const LoadingButton = styled(LoadingButtonMUI, {
  shouldForwardProp: (props) => props !== 'gradient',
})<LoadingButtonProps>(({ gradient, theme }) => ({
  position: 'relative',
  textTransform: 'none',
  borderRadius: '6px',
  fontWeight: '700',

  '&.MuiLoadingButton-outlinedSecondary': {
    color: theme.palette.secondary.light,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  '&.MuiLoadingButton-text': {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '&.MuiLoadingButton-textSecondary': {
    color: theme.palette.info.main,
    '&:hover': {
      backgroundColor: '#4A5568',
    },
  },
  ...(gradient && {
    background: `linear-gradient(90deg,${theme.palette.primary.main} 0%, #9C4221 100%)`,
    borderRadius: '4px',
    justifyContent: 'space-between',
    '&.MuiLoadingButtonBase-root': {
      color: theme.palette.primary.contrastText,
    },
  }),
}));

export default LoadingButton;
