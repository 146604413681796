import React from 'react';
import { Box, Divider } from '@mui/material';

import { Text } from 'common/components';

import ChangePasswordDialog from './ChangePasswordDialog';

interface TabSecurityProps {
  email: React.ReactNode;
}

const TabSecurity: React.FC<TabSecurityProps> = ({ email }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <>
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          gridTemplateRows: 'auto auto',
          gridTemplateColumns: '1fr 1fr',
          paddingTop: '16px',
          gap: '16px',
        }}
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'flex-start',
            gridArea: '1/1/1/3',
            paddingBottom: 2,
            borderBottom: `1px solid ${theme.palette.primary.light}`,
          })}
        >
          <Text sx={{ flex: 1 }} fontWeight={700}>
            Email
          </Text>
          <Box sx={{ flex: 2 }}>{email}</Box>
          <Divider />
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'flex-start',
            gridArea: '2/1/2/3',
            paddingBottom: 2,

            borderBottom: `1px solid ${theme.palette.primary.light}`,
          })}
        >
          <Text sx={{ flex: 1 }} fontWeight={700}>
            Senha
          </Text>
          <Box sx={{ flex: 2 }}>
            <Text
              sx={{ '&:hover': { cursor: 'pointer' }, color: '#2C5282' }}
              onClick={handleOpenDialog}
              fontWeight={400}
              variant="subtitle2"
            >
              Mudar senha
            </Text>
          </Box>
        </Box>
      </Box>
      <ChangePasswordDialog open={open} closeDialog={handleCloseDialog} />
    </>
  );
};

export default TabSecurity;
