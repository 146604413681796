import { PathParams, RestRequest, rest } from 'msw';
import { getApiBasePath } from 'common/services/axiosService';
import { communitiesMockedData } from '../mockData';

const BASE_URL = getApiBasePath();
const communitiesDB = communitiesMockedData;

const HandlersCommunities = (delay: number) => {
  return [
    rest.get(BASE_URL + '/communities', async (_, res, ctx) => {
      return res(ctx.status(200), ctx.json(communitiesDB), ctx.delay(delay));
    }),

    rest.get(BASE_URL + '/communities/:id', async (req, res, ctx) => {
      const { id } = req.params;
      const currentCommunity = communitiesDB.find((community) => community._id === id);
      return res(ctx.status(200), ctx.json(currentCommunity), ctx.delay(delay));
    }),

    rest.post(BASE_URL + '/communities/:id/highlight', async (req: RestRequest<any, PathParams<string>>, res, ctx) => {
      const { description } = req.body;
      const { id: communityId } = req.params;

      const community = communitiesDB.find((community) => community._id === communityId) ?? null;
      const newCommunity = { ...community, highlight: description };

      return res(ctx.status(200), ctx.json({ newCommunity }), ctx.delay(delay));
    }),

    rest.put(BASE_URL + '/communities/:id', async (req, res, ctx) => {
      const newData: any = req.body;
      const { id } = req.params;
      const index = communitiesDB.findIndex((community) => community._id === id);
      communitiesDB[index] = { ...communitiesDB[index], ...newData };

      return res(ctx.status(200), ctx.json(communitiesDB[index]), ctx.delay(delay));
    }),
  ];
};

export default HandlersCommunities;
